import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { AxiosError } from 'axios';
import { Box, Stack, TextField, Typography } from '@mui/material';
import { useFormik } from 'formik';
import { LoadingButton } from '@mui/lab';

import { SignInRequest, useSignInMutation } from '@src/services';
import { getAccessToken } from '@src/utils/auth';
import { validateManager } from '@src/utils/validators';
import { ForgotPassword } from '@src/components/ForgotPassword';

const SignIn: React.FC = () => {
  const navigate = useNavigate();
  const [showForgotPasswordModal, setShowForgotPasswordModal] = useState<boolean>(false);

  const { mutateAsync, isLoading } = useSignInMutation();

  const formik = useFormik<SignInRequest>({
    initialValues: {
      username: '',
      password: '',
    },
    validationSchema: validateManager.pick(['username', 'password']),
    onSubmit: async values => {
      try {
        await mutateAsync(values);
        navigate('/');
      } catch (e) {
        console.log(e);

        if (e instanceof AxiosError && e?.response?.data?.msg && e?.response?.status) {
          const field = e.response.status === 404 ? 'username' : 'password';
          formik.setErrors({ [field]: e.response.data.msg });
          return;
        }

        formik.setErrors({
          username: 'Unknown error. Please try again later or contact main administrator.',
        });
      }
    },
  });

  useEffect(() => {
    if (getAccessToken() !== null) {
      navigate('/');
    }
  }, []);

  return (
    <Box
      component="main"
      height="100vh"
      width="100vw"
      display="flex"
      alignItems="center"
      justifyContent="center"
    >
      <form onSubmit={formik.handleSubmit}>
        <Stack spacing={3} width={300}>
          <Typography variant="h5" component="h1" textAlign="center">
            Admin login
          </Typography>
          <TextField
            id="username"
            label="Username"
            name="username"
            value={formik.values.username}
            onChange={formik.handleChange}
            error={formik.touched.username && Boolean(formik.errors.username)}
            helperText={formik.touched.username && formik.errors.username}
            required
            fullWidth
          />
          <TextField
            id="password"
            type="password"
            label="Password"
            name="password"
            value={formik.values.password}
            onChange={formik.handleChange}
            error={formik.touched.password && Boolean(formik.errors.password)}
            helperText={formik.touched.password && formik.errors.password}
            required
          />
          {/* <Link href="#" onClick={() => setShowForgotPasswordModal(true)}>
            forgot password?
          </Link> */}
          <LoadingButton loading={isLoading} type="submit" variant="contained">
            LOG IN
          </LoadingButton>
        </Stack>
      </form>
      <ForgotPassword
        showForgotPasswordModal={showForgotPasswordModal}
        setShowForgotPasswordModal={setShowForgotPasswordModal}
      />
    </Box>
  );
};

export default SignIn;
