import React, { useEffect, useState } from 'react';
import { useFormik } from 'formik';
import {
  Card,
  CardHeader,
  IconButton,
  CardMedia,
  CardContent,
  Box,
  Dialog,
  useMediaQuery,
  Autocomplete,
  Checkbox,
  TextField,
  Button,
  Typography,
} from '@mui/material';
import { TextareaAutosize } from '@mui/base';
import { useTheme } from '@mui/material/styles';
import { CheckBox, CheckBoxOutlineBlank, HighlightOffOutlined } from '@mui/icons-material';

import { toBase64 } from '@src/utils/toBase64';
import { validatePost } from '@src/utils/validators';
import { IEmoji, IKeyword, IPost, IUser, ModalImage } from '@src/types';
import { createPost, getEmoji, getKeywords } from '@src/services/posts/api';
import { getUsers } from '@src/services/users/api';
import { toastPromise } from '../Toast';

interface Props {
  showModal: boolean;
  setShowModal: React.Dispatch<React.SetStateAction<boolean>>;
  setRows: React.Dispatch<React.SetStateAction<any[]>>;
  rows: IUser[] | IPost[];
  count: number;
}

export const Post: React.FC<Props> = ({ showModal, setShowModal, setRows, rows, count }) => {
  const [keywordsOptions, setKeywordsOptions] = useState<IKeyword[]>([]);
  const [emojii, setEmojii] = useState<IEmoji[]>([]);
  const [inputValue, setInputValue] = useState('');
  const [errors, setErrors] = useState<any>({
    keywords: false,
    text: false,
    user: false,
  });
  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down('md'));

  const formik = useFormik({
    initialValues: {
      text: '',
      keywords: [] as IKeyword[],
      users: [] as IUser[],
      user: {} as IUser,
      image: {} as ModalImage,
      keyword: '',
    },
    validationSchema: validatePost,
    onSubmit: (): void => {
      ('');
    },
  });

  useEffect(() => {
    const fetchKeywords = async () => {
      const {
        data: {
          success,
          body: { keywords },
        },
      } = await getKeywords();
      setKeywordsOptions(keywords);

      setErrors({ ...errors, keywords: !success });
    };

    const fetchEmojii = async () => {
      const {
        data: {
          success,
          body: { emojis },
        },
      } = await getEmoji();

      setEmojii(emojis);

      setErrors({ ...errors, emojii: !success });
    };

    const fetchUsers = async () => {
      const page = 0;
      const {
        data: { body },
      } = await getUsers(page, 0);
      formik.setFieldValue('users', body.rows);
    };

    fetchEmojii();
    fetchUsers();
    fetchKeywords();
  }, []);

  const handleCreatePost = async () => {
    if (!formik.values.user.id) {
      setErrors({ ...errors, user: true });
      return;
    }

    const keywords_ids = formik.values.keywords.map(i => i.id);

    if (formik.values.text.length > 1500 || formik.values.text.length === 0) {
      setErrors({ ...errors, text: true });
      return;
    }

    const postResponse = await toastPromise<Awaited<ReturnType<typeof createPost>>>(
      createPost(
        formik.values.text,
        formik.values.image?.base64 as string,
        keywords_ids,
        formik.values.user.id,
      ),
      {
        loading: 'Creating post...',
        success: 'Post created successfully',
        error: 'Something went wrong, please try again',
      },
    );

    if (!postResponse?.data?.body?.post) {
      setShowModal(false);
      return;
    }

    const {
      data: {
        body: { post },
      },
    } = postResponse;

    setRows([post, ...rows]);

    setShowModal(false);
  };

  const handleDeleteImage = () => {
    formik.setFieldValue('image', {
      src: '',
      base64: '',
      type_of_img: '',
    });
  };

  const onChangeImage = async (event: React.ChangeEvent<HTMLInputElement>) => {
    if (event.target.files !== null) {
      const { base64 } = await toBase64(event.target.files[0]);

      formik.setFieldValue('image', {
        src: URL.createObjectURL(event.target.files[0]),
        base64,
        type_of_img: event.target.files[0].type.replace('image/', ''),
      });
    }
  };

  return (
    <Box>
      <Dialog
        fullScreen={fullScreen}
        open={showModal}
        onClose={() => setShowModal(!showModal)}
        aria-labelledby="responsive-dialog-title"
      >
        <Card sx={{ width: 500, minHeight: 200, overflow: 'auto' }}>
          <CardHeader sx={{ textAlign: 'center' }} title="Creating Post" />
          <Box
            sx={{
              display: 'grid',
              justifyContent: 'center',
              minHeight: 200,
              alignContent: 'space-around',
            }}
          >
            <Autocomplete
              multiple
              disableCloseOnSelect
              onChange={(_, value) => {
                formik.setFieldValue('keywords', value);

                if (value.length > 0) {
                  setErrors({ ...errors, keywords: false });
                }
              }}
              value={formik.values.keywords}
              inputValue={inputValue}
              onInputChange={(event, newInputValue) => {
                setInputValue(newInputValue);
              }}
              id="keywords-autocomplete"
              options={keywordsOptions}
              getOptionLabel={(option: IKeyword) => option.value}
              sx={{ width: 300, margin: 'auto' }}
              renderOption={(props, option, { selected }) => {
                return (
                  <li {...props}>
                    <Checkbox
                      icon={<CheckBoxOutlineBlank />}
                      checkedIcon={<CheckBox />}
                      style={{ marginRight: 8 }}
                      checked={selected}
                    />
                    {option.value}
                  </li>
                );
              }}
              renderInput={params => {
                if (errors.keywords) {
                  return (
                    <TextField
                      error={errors.keywords}
                      helperText="Please select keywords"
                      {...params}
                      label="Select keywords"
                      variant="outlined"
                    />
                  );
                }

                return (
                  <TextField
                    {...params}
                    label="Choose keywords"
                    placeholder="Please select keywords"
                  />
                );
              }}
            />
            <Autocomplete
              disablePortal
              id="free-solo-demo"
              freeSolo
              onChange={(_, value) => {
                const user = formik.values.users.find(
                  i => `${i.firstName} ${i.lastName}` === value,
                );

                if (user) {
                  setErrors({ ...errors, user: false });
                  formik.setFieldValue('user', user);
                  return;
                }

                setErrors({ ...errors, user: true });
              }}
              options={formik.values.users
                .filter(u => !u.isBanned)
                .map(option => `${option.firstName} ${option.lastName}`)}
              sx={{ width: 300 }}
              renderInput={params => {
                if (errors.user) {
                  return (
                    <TextField
                      error={errors.user}
                      helperText="Please select user"
                      {...params}
                      label="Select user"
                      variant="outlined"
                    />
                  );
                }

                return <TextField {...params} label="Select User" />;
              }}
            />
          </Box>
          {!formik.values.image.src && (
            <Box sx={{ display: 'flex', justifyContent: 'center', minHeight: 100 }}>
              <Button variant="outlined" sx={{ width: 100, alignSelf: 'center' }} component="label">
                Upload
                <input
                  hidden
                  accept="image/*"
                  multiple
                  name="image"
                  type="file"
                  onChange={onChangeImage}
                />
              </Button>
            </Box>
          )}
          {formik.values.image.src && (
            <Box
              sx={{
                display: 'flex',
                justifyContent: 'center',
                margin: 2,
              }}
            >
              <CardMedia
                component="img"
                height="150"
                width="150"
                image={formik.values.image?.src}
                alt="Paella dish"
              />
              <IconButton
                aria-label="delete"
                style={{
                  backgroundColor: 'transparent',
                  alignItems: 'flex-start',
                  height: '50px',
                }}
                onClick={handleDeleteImage}
              >
                <HighlightOffOutlined fontSize="inherit" cursor="pointer" color="error" />
              </IconButton>
            </Box>
          )}
          <CardContent sx={{ display: 'grid', justifyContent: 'center' }}>
            <Typography sx={{ textAlign: 'center' }}>Type text for post</Typography>
            <TextareaAutosize
              aria-label="maximum height"
              onChange={event => {
                if (event.target.value.length > 1500 || event.target.value.length === 0) {
                  setErrors({ ...errors, text: true });
                  formik.setFieldValue('text', event.target.value);
                  return;
                }

                setErrors({ ...errors, text: false });
                formik.setFieldValue('text', event.target.value);
              }}
              style={{
                width: 400,
                borderRadius: 3,
                minHeight: 50,
                margin: '10px 0 0 0',
                border: errors.text ? '1px solid red' : '',
              }}
              value={formik.values.text}
            />
            {errors.text && (
              <p
                style={{
                  color: '#d32f2f',
                  fontSize: '12px',
                  margin: '0 0 0 10px',
                }}
              >
                {formik.values.text.length === 0 ? 'Please type text' : 'Text is too long'}
              </p>
            )}
          </CardContent>
          <CardContent sx={{ display: 'grid', justifyContent: 'center' }}>
            <Typography sx={{ textAlign: 'center' }}>Select Emojii</Typography>
            <Autocomplete
              id="free-solo-demo"
              freeSolo
              options={emojii.map(option => option.char)}
              renderInput={params => <TextField {...params} label="Emojii" />}
              onKeyPress={event => {
                const emoji = emojii.find(
                  i => i.char === (event.target as HTMLInputElement).defaultValue,
                );

                if (emoji) {
                  formik.setFieldValue(
                    'text',
                    `${formik.values.text}${String.fromCodePoint(+`0x${emoji.codes}`)}`,
                  );
                }
              }}
              onChange={event => {
                const emoji = emojii.find(i => i.char === event.currentTarget.innerHTML);

                if (emoji) {
                  formik.setFieldValue(
                    'text',
                    `${formik.values.text}${String.fromCodePoint(+`0x${emoji.codes}`)}`,
                  );
                }
              }}
            />
          </CardContent>
          <Box
            sx={{
              display: 'flex',
              justifyContent: 'space-around',
              marginBottom: 5,
            }}
          >
            <Button
              variant="outlined"
              sx={{ width: 100, alignSelf: 'center' }}
              component="label"
              color="error"
              onClick={() => setShowModal(!showModal)}
            >
              Cancel
            </Button>
            <Button
              variant="outlined"
              sx={{ width: 100, alignSelf: 'center' }}
              type="submit"
              onClick={handleCreatePost}
            >
              Submit
            </Button>
          </Box>
        </Card>
      </Dialog>
    </Box>
  );
};
