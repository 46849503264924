import React, { useEffect } from 'react';
import { Box, Stack, Typography } from '@mui/material';
import { communityRulesContent } from '@src/utils/communityRulesContent';

interface IContent {
  article?: string;
  ulList?: Array<string>;
  numberList?: Array<string>;
  listGap?: boolean;
}

const CommunityRules: React.FC = () => {
  useEffect(() => {
    document.title = 'Help';
  }, []);

  return (
    <Box display={'flex'} justifyContent={'center'} pt={5} pb={5} pl={3} pr={3} overflow={'hidden'}>
      <Box
        display={'flex'}
        flexDirection={'column'}
        alignItems={'center'}
        width={'100%'}
        fontFamily={'Red Hat Display, sans-serif!important'}
        maxWidth={'720px'}
      >
        <Box
          fontWeight={700}
          fontSize={{ xs: '16px', md: '32px' }}
          color={'black'}
          lineHeight={{ xs: '21px', md: '42px' }}
        >
          HIGEDI COMMUNITY GUIDELINES
        </Box>

        <Box width={'100%'} mt={{ xs: '20px', md: '48px' }} mb={{ xs: '16px', md: '24px' }}>
          <Typography
            fontFamily={'Red Hat Display, sans-serif!important'}
            fontSize={{ xs: '14px', md: '20px' }}
            lineHeight={{ xs: '19px', md: '140%' }}
            fontWeight={400}
          >
            We know that dealing with health issues can be hard. The goal of HIgedi community is to
            create a safe space for current or expecting moms to support each other during and after
            their gestational diabetes journey. Share your gestational diabetes experiences
            honestly, but keep in mind positivity has been shown to improve health outcomes.
          </Typography>
          <br></br>
          <Typography
            fontFamily={'Red Hat Display, sans-serif!important'}
            fontSize={{ xs: '14px', md: '20px' }}
            lineHeight={{ xs: '19px', md: '140%' }}
            fontWeight={400}
          >
            HIgedi’s admins review user-generated content (posts and comments). We remain the right
            to remove content or users from the app at our discretion, because it’s our job to make
            sure that HIgedi community is a welcoming place for all.
          </Typography>
          <br></br>
          <Typography
            fontFamily={'Red Hat Display, sans-serif!important'}
            fontSize={{ xs: '14px', md: '20px' }}
            lineHeight={{ xs: '19px', md: '140%' }}
            fontWeight={400}
          >
            By participating in the HIgedi community, you agree to respect these guidelines.
          </Typography>
          <br></br>
          <Typography
            fontFamily={'Red Hat Display, sans-serif!important'}
            fontSize={{ xs: '14px', md: '20px' }}
            lineHeight={{ xs: '19px', md: '140%' }}
            fontWeight={400}
          >
            Please know that if your post or comment was reported in breach of the following
            guidelines, this may lead to the permanent deactivation of your account. We’re all in
            this together, and it’s up to all of us to make HIgedi a safe place for all.
          </Typography>
          <br></br>
          <Typography
            fontFamily={'Red Hat Display, sans-serif!important'}
            fontSize={{ xs: '14px', md: '20px' }}
            lineHeight={{ xs: '19px', md: '140%' }}
            fontWeight={400}
          >
            If you see any off-limits content, please report it in-app or by writing to us on{' '}
            <a href="mailto:hi@higedi.com">hi@higedi.com</a>.
          </Typography>
        </Box>
        <Box width={'100%'}>
          <Stack direction={'column'} spacing={{ xs: '16px', md: '24px' }}>
            {communityRulesContent.map(
              (contentData: { title: string | undefined; content: Array<IContent> }, index) => {
                return (
                  <Box key={index}>
                    {contentData.title && (
                      <Typography
                        fontFamily={'Red Hat Display, sans-serif!important'}
                        fontSize={{ xs: '16px', md: '24px' }}
                        lineHeight={{ xs: '21px', md: '140%' }}
                        fontWeight={700}
                      >
                        {contentData.title}
                      </Typography>
                    )}
                    {contentData.content.map((data, index) => {
                      return (
                        <Box key={index}>
                          {data.article && (
                            <Typography
                              mt={contentData.title ? { xs: '16px', md: '24px' } : 0}
                              fontFamily={'Red Hat Display, sans-serif!important'}
                              fontSize={{ xs: '14px', md: '20px' }}
                              lineHeight={{ xs: '22px', md: '140%' }}
                              fontWeight={400}
                              dangerouslySetInnerHTML={{
                                __html: `${data.article}`,
                              }}
                            />
                          )}
                          {data.ulList && (
                            <Box mt={data.listGap ? { xs: '20px', md: '28px' } : 0}>
                              <ul
                                style={{
                                  display: 'flex',
                                  flexDirection: 'column',
                                  paddingInlineStart: '30px',
                                  marginBlockEnd: 0,
                                  marginBlockStart: 0,
                                }}
                              >
                                {data.ulList.map(text => {
                                  return (
                                    <li key={text}>
                                      <Typography
                                        fontFamily={'Red Hat Display, sans-serif!important'}
                                        fontSize={{ xs: '14px', md: '20px' }}
                                        lineHeight={{ xs: '22px', md: '140%' }}
                                        fontWeight={400}
                                        dangerouslySetInnerHTML={{
                                          __html: `${text}`,
                                        }}
                                      />
                                    </li>
                                  );
                                })}
                              </ul>
                            </Box>
                          )}
                          {data.numberList && (
                            <Box
                              mt={data.listGap ? { xs: '20px', md: '28px' } : 0}
                              fontSize={{ xs: '14px', md: '20px' }}
                            >
                              <ol
                                style={{
                                  display: 'flex',
                                  flexDirection: 'column',
                                  paddingInlineStart: '30px',
                                  marginBlockEnd: 0,
                                  marginBlockStart: 0,
                                }}
                              >
                                {data.numberList.map(text => {
                                  return (
                                    <li key={text}>
                                      <Typography
                                        fontFamily={'Red Hat Display, sans-serif!important'}
                                        fontSize={{ xs: '14px', md: '20px' }}
                                        lineHeight={{ xs: '22px', md: '140%' }}
                                        fontWeight={400}
                                        dangerouslySetInnerHTML={{
                                          __html: `${text}`,
                                        }}
                                      />
                                    </li>
                                  );
                                })}
                              </ol>
                            </Box>
                          )}
                        </Box>
                      );
                    })}
                  </Box>
                );
              },
            )}
          </Stack>
        </Box>
      </Box>
    </Box>
  );
};

export default CommunityRules;
