import React from 'react';
import { BrowserRouter } from 'react-router-dom';
import { CssBaseline } from '@mui/material';
import { QueryClient, QueryClientProvider } from 'react-query';

import { DashboardRouter } from '@src/routes';
import { StateContextProvider } from '@src/context';
import AuthMiddleware from '@src/middleware/AuthMiddleware';
import Toast from './components/Toast';

const queryClient = new QueryClient();

const App: React.FC = () => (
  <StateContextProvider>
    <QueryClientProvider client={queryClient}>
      <BrowserRouter>
        <AuthMiddleware>
          <DashboardRouter />
        </AuthMiddleware>
      </BrowserRouter>
    </QueryClientProvider>

    <CssBaseline />
    <Toast />
  </StateContextProvider>
);

export default App;
