import React, { useCallback, useEffect, useState } from 'react';
import {
  alpha,
  Box,
  Button,
  CircularProgress,
  Grid,
  IconButton,
  Paper,
  Stack,
  styled,
  Table as MuiTable,
  TableBody,
  TableCell,
  tableCellClasses,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from '@mui/material';
import { Link, useSearchParams } from 'react-router-dom';
import { ContentCopy, DeleteForever } from '@mui/icons-material';

import { Filters } from '@src/pages/Marketplace/Filters';
import {
  useCopyProductsMutation,
  useDeleteProductsMutation,
  useGetProductsQuery,
  useMarketplaceFiltersCache,
} from '@src/services/marketplace/hooks';

import { Row } from './Row';

const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: alpha(theme.palette.common.black, 0.05),
    fontWeight: 'bold',
  },
}));

export const MarketplaceTable: React.FC = () => {
  const [searchParams] = useSearchParams();
  const [loading, setLoading] = useState(false);
  const [filtersCache] = useMarketplaceFiltersCache();
  const { data, refetch, error, isLoading } = useGetProductsQuery({
    name: filtersCache.name || searchParams.get('name') || '',
    categories: filtersCache.categories.map(c => c.category),
  });

  const [selected, setSelected] = useState<number[]>([]);
  const handleSelect = useCallback((id: number) => {
    setSelected(prevState =>
      prevState.includes(id)
        ? prevState.filter(selectedId => selectedId !== id)
        : [...prevState, id],
    );
  }, []);

  const deleteMutation = useDeleteProductsMutation();
  const handleDelete = useCallback(async () => {
    await deleteMutation.mutateAsync(selected);
    setSelected([]);
  }, [selected]);

  const copyMutation = useCopyProductsMutation();
  const handleCopy = useCallback(async () => {
    setLoading(true);
    await copyMutation.mutateAsync(selected);
    setSelected([]);
    await refetch();
    setLoading(false);
  }, [selected]);

  useEffect(() => {
    void refetch();
  }, [searchParams.toString(), filtersCache]);

  return (
    <>
      <Stack direction="row" justifyContent="flex-end" spacing={1}>
        <Button component={Link} to="create" variant="outlined">
          Create
        </Button>
        {selected.length > 0 && (
          <>
            <IconButton size="small" onClick={handleCopy}>
              <ContentCopy />
            </IconButton>
            <IconButton size="small" onClick={handleDelete}>
              <DeleteForever />
            </IconButton>
          </>
        )}
      </Stack>

      <Box sx={{ flexGrow: 1 }}>
        <Grid item container spacing={2}>
          <Grid pl={0} item xs={3}>
            <Filters />
          </Grid>
          <Grid item xs={9}>
            <Paper variant="outlined">
              <>
                {(isLoading || loading) && (
                  <Box my={3} width="100%" display="flex" justifyContent="center">
                    <CircularProgress />
                  </Box>
                )}
                <TableContainer sx={{ width: '100%' }}>
                  <MuiTable sx={{ minWidth: 700 }} size="small" aria-label="Managers table">
                    <TableHead>
                      <TableRow>
                        <StyledTableCell align="center" style={{ width: '5%' }} />
                        <StyledTableCell align="center" style={{ width: '30%' }}>
                          Image
                        </StyledTableCell>
                        <StyledTableCell style={{ width: '30%' }} align="center">
                          Product name
                        </StyledTableCell>
                        <StyledTableCell align="center" style={{ width: '30%' }}>
                          Category
                        </StyledTableCell>
                        <StyledTableCell style={{ width: '5%' }} align="center">
                          Actions
                        </StyledTableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {data?.data?.body?.products?.map(product => (
                        <Row
                          key={product.id}
                          {...product}
                          checked={selected.includes(product.id)}
                          actions={{ select: handleSelect }}
                        />
                      ))}
                    </TableBody>
                  </MuiTable>
                </TableContainer>

                {error && (
                  <Typography my={3} textAlign="center">
                    Something went wrong, please try again.
                  </Typography>
                )}
                {data?.data?.body?.products?.length === 0 && (
                  <Typography my={3} textAlign="center">
                    No results.
                  </Typography>
                )}
              </>
            </Paper>
          </Grid>
        </Grid>
      </Box>
    </>
  );
};
