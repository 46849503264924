import React from 'react';
import {
  Box,
  Divider,
  Drawer as MuiDrawer,
  List,
  ListItem,
  ListItemButton,
  Toolbar,
} from '@mui/material';
import { NavLink, useLocation } from 'react-router-dom';

import { ReactComponent as HigediLogo } from '@src/assets/higedi-logo-with-text.svg';
import { drawerRoutes } from '@src/routes';

const DRAWER_WIDTH = '200px';

export const Drawer: React.FC = () => {
  const location = useLocation();

  const isActive = (route: string): boolean => {
    if (route === '/') {
      return location.pathname === route;
    }

    return location.pathname.includes(route);
  };

  return (
    <MuiDrawer
      variant="permanent"
      anchor="left"
      sx={{ width: DRAWER_WIDTH }}
      PaperProps={{ sx: { width: DRAWER_WIDTH } }}
    >
      <Box
        sx={{
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
        }}
      >
        <Toolbar>
          <HigediLogo />
        </Toolbar>
      </Box>

      <Divider />

      <List component="nav">
        {drawerRoutes.map(route => (
          <React.Fragment key={route.id}>
            <ListItem disablePadding>
              <ListItemButton component={NavLink} to={route.path} selected={isActive(route.path)}>
                {route.name}
              </ListItemButton>
            </ListItem>
          </React.Fragment>
        ))}
      </List>
    </MuiDrawer>
  );
};
