import { useMutation } from 'react-query';

import { getAccessToken, login, logout } from '@src/utils/auth';

import { signIn, signOut } from './api';

export const useSignInMutation = () =>
  useMutation('sign-in', signIn, {
    onSuccess: ({ data }) => {
      login(data.body.token.access_token);
    },
  });

export const useSignOutMutation = (navigate: (to: string) => void) =>
  useMutation('sign-out', async () => {
    const token = getAccessToken();
    logout();
    navigate('/signin');
    await signOut(token ?? '');
  });
