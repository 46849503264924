interface ExtendedFile extends File {
  base64: string;
}

export const toBase64 = (file: File): Promise<ExtendedFile> =>
  new Promise((resolve, reject) => {
    const reader = new FileReader();

    if (!file) {
      reject(new DOMException('No result.'));

      return;
    }

    reader.onload = evt => {
      if (!evt.target?.result || !reader.result || reader.result instanceof ArrayBuffer) {
        reject(new DOMException('No result.'));

        return;
      }

      const [, base64] = reader.result.split(',');

      const extendedFile = {
        ...file,
        base64,
      };

      resolve(extendedFile);
    };

    reader.readAsDataURL(file);
  });
