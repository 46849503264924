import { useMutation, useQuery, useQueryClient } from 'react-query';

import {
  copyProducts,
  createProduct,
  deleteProducts,
  editProduct,
  getCategories,
  getOneProduct,
  getProducts,
} from '@src/services/marketplace/api';
import { GetProductsParams } from '@src/services/marketplace/types';
import { showToast, toastPromise } from '@src/components/Toast';
import { useStateContext } from '@src/context';
import { MarketplaceFilters } from '@src/types';

export const useMarketplaceFiltersCache = (): [
  MarketplaceFilters,
  typeof setMarketplaceFiltersCache,
] => {
  const stateContext = useStateContext();

  const setMarketplaceFiltersCache = (filters: MarketplaceFilters) => {
    stateContext.dispatch({ type: 'SET_MARKETPLACE_FILTERS', payload: filters });
  };

  return [stateContext.state.marketplaceFilters, setMarketplaceFiltersCache];
};

export const useGetProductsQuery = (params: GetProductsParams) =>
  useQuery('products', async () => await getProducts(params));

export const useGetOneProductQuery = (id: number) =>
  useQuery(`product-${id}`, async () => await getOneProduct(id), {
    refetchOnMount: false,
    refetchOnWindowFocus: false,
    refetchIntervalInBackground: false,
    refetchInterval: false,
    refetchOnReconnect: false,
  });

export const useCreateProductMutation = () => {
  const queryClient = useQueryClient();

  const createProductToasted = async (data: any) =>
    toastPromise<Awaited<ReturnType<typeof createProduct>>>(createProduct(data), {
      loading: 'Creating product...',
      success: 'Product created successfully',
      error: 'Something went wrong, please try again',
    });

  return useMutation('createProduct', createProductToasted, {
    onSuccess: () => {
      return queryClient.invalidateQueries('products');
    },
  });
};

export const useDeleteProductsMutation = () => {
  const queryClient = useQueryClient();

  const deleteProductsToasted = async (data: any) =>
    toastPromise<Awaited<ReturnType<typeof deleteProducts>>>(deleteProducts(data), {
      loading: 'Deleting product...',
      success: 'Product deleted successfully',
      error: 'Something went wrong, please try again',
    });

  return useMutation('deleteProduct', deleteProductsToasted, {
    onSuccess: () => {
      return queryClient.invalidateQueries('products');
    },
  });
};

export const useCopyProductsMutation = () => {
  const queryClient = useQueryClient();

  return useMutation('copyProducts', copyProducts, {
    onSuccess: () => {
      showToast('success', 'Products copied successfully');
      return queryClient.invalidateQueries('products');
    },
    onError: () => {
      showToast('error', 'Something went wrong, please try again');
    },
  });
};

export const useEditProductMutation = () => {
  const editProductToasted = async (data: any) =>
    toastPromise<Awaited<ReturnType<typeof editProduct>>>(editProduct(data), {
      loading: 'Creating product...',
      success: 'Product updated successfully',
      error: 'Something went wrong, please try again',
    });

  return useMutation('editProduct', editProductToasted);
};

export const useGetCategoriesQuery = () => useQuery('categories', getCategories, {});
