import React, { useCallback } from 'react';
import { DialogActions, IconButton, TableCell, TableRow } from '@mui/material';
import { bindTrigger, usePopupState } from 'material-ui-popup-state/hooks';
import { DeleteForever, Edit } from '@mui/icons-material';
import { LoadingButton } from '@mui/lab';

import { Dialog } from '@src/components/Dialog';
import { ManagerDialog } from '@src/pages/Managers/ManagerDialog';
import { IManager } from '@src/types';
import { useDeleteManagerMutation } from '@src/services/managers';

interface Props extends IManager {
  currentManagerID: number | undefined;
}

export const Row: React.FC<Props> = ({ id, username, password, email, currentManagerID }) => {
  const editDialogState = usePopupState({
    variant: 'dialog',
    popupId: 'edit-dialog',
  });
  const deleteDialogState = usePopupState({
    variant: 'dialog',
    popupId: 'delete-dialog',
  });

  const { mutateAsync, isLoading } = useDeleteManagerMutation();

  const deleteManager = useCallback(async () => {
    await mutateAsync(id);
    deleteDialogState.close();
  }, []);

  return (
    <TableRow>
      <TableCell component="th" scope="row" align="center">
        {username}
      </TableCell>
      <TableCell align="center">{email}</TableCell>
      {currentManagerID === 1 && (
        <>
          <TableCell align="center">
            <IconButton aria-label="edit" disabled={id === 1} {...bindTrigger(editDialogState)}>
              <Edit />
            </IconButton>

            <ManagerDialog
              variant="edit"
              dialogTitle="Edit manager"
              dialogState={editDialogState}
              managerData={{ id, username, password, email }}
            />
          </TableCell>
          <TableCell align="center">
            <IconButton aria-label="delete" disabled={id === 1} {...bindTrigger(deleteDialogState)}>
              <DeleteForever />
            </IconButton>
            <Dialog popupState={deleteDialogState} title="Delete manager">
              <DialogActions sx={{ display: 'flex', justifyContent: 'space-between' }}>
                <LoadingButton loading={isLoading} onClick={deleteDialogState.close}>
                  Cancel
                </LoadingButton>
                <LoadingButton loading={isLoading} onClick={deleteManager}>
                  Delete
                </LoadingButton>
              </DialogActions>
            </Dialog>
          </TableCell>
        </>
      )}
    </TableRow>
  );
};
