import { useMutation, useQuery, useQueryClient } from 'react-query';

import { useStateContext } from '@src/context';

import { createManager, deleteManager, editManager, getCurrentManager, getManagers } from './api';
import { showToast, toastPromise } from '@src/components/Toast';

export const useGetManagersQuery = (username = '') =>
  useQuery('managers', async () => await getManagers(username));

export const useGetCurrentManagerQuery = (enabled: boolean) => {
  const stateContext = useStateContext();

  return useQuery('currentManager', getCurrentManager, {
    select: data => data.data.body.user,
    enabled,
    retry: false,
    onSuccess(data) {
      stateContext.dispatch({ type: 'SET_USER', payload: data });
    },
  });
};

export const useCreateManager = () => {
  const queryClient = useQueryClient();

  const createManagerToasted = async (data: any) =>
    toastPromise<Awaited<ReturnType<typeof createManager>>>(createManager(data), {
      loading: 'Creating manager...',
      success: 'Manager created successfully',
      error: 'Something went wrong, please try again',
    });

  return useMutation('postManager', createManagerToasted, {
    onSuccess: () => {
      return queryClient.invalidateQueries('managers');
    },
  });
};

export const useEditManagerMutation = () => {
  const queryClient = useQueryClient();

  const editManagerToasted = async (data: any) =>
    toastPromise<Awaited<ReturnType<typeof editManager>>>(editManager(data), {
      loading: 'Updating manager...',
      success: 'Manager updated successfully',
      error: 'Something went wrong, please try again',
    });

  return useMutation('patchManager', editManagerToasted, {
    onSuccess: () => {
      return queryClient.invalidateQueries('managers');
    },
  });
};

export const useDeleteManagerMutation = () => {
  const queryClient = useQueryClient();

  return useMutation('deleteManager', deleteManager, {
    onSuccess: () => {
      showToast('success', 'Manager deleted successfully');
      return queryClient.invalidateQueries('managers');
    },
  });
};
