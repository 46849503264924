import React, { useState, useEffect } from 'react';

import { TableComponent } from '@src/components/Table/Table';
import { getBannedUsers } from '@src/services/users/api';
import { IUser } from '@src/types';
import { Columns } from '@src/components/Table/Columns';

export const BannedUsersTable: React.FC = () => {
  const [page, setPage] = useState(0);
  const [limit, setLimit] = useState(10);
  const [users, setUsers] = useState<IUser[]>([]);
  const [countUsers, setCountUsers] = useState<number>(1);
  const [error, setError] = useState<boolean>(false);
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [showRemoveModal, setShowRemoveModal] = useState<boolean>(false);

  const column1 = 'id';
  const column2 = 'firstName';
  const column3 = 'lastName';
  const column4 = 'avatarPath';
  const column5 = 'email';
  const label1 = 'ID';
  const label2 = 'First Name';
  const label3 = 'Last Name';
  const label4 = 'Avatar';
  const label5 = 'Email';
  const columns = Columns(
    column1,
    column2,
    column3,
    column4,
    column5,
    label1,
    label2,
    label3,
    label4,
    label5,
  );

  useEffect(() => {
    const fetchData = async () => {
      const {
        data: { success, body },
      } = await getBannedUsers(page, limit);

      setCountUsers(body.count);
      setUsers(body.users);
      setIsLoading(false);
      setError(!success);
    };

    fetchData();
  }, [limit, page]);

  return (
    <TableComponent
      type="banned_user"
      showModal={showRemoveModal}
      setShowModal={setShowRemoveModal}
      page={page}
      setPage={setPage}
      limit={limit}
      setLimit={setLimit}
      count={countUsers || 0}
      columns={columns}
      rows={users || []}
      setRows={setUsers}
      error={error}
      isLoading={isLoading}
    />
  );
};
