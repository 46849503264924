import { alpha, styled, TableCell, tableCellClasses, TableHead, TableRow } from '@mui/material';
import React from 'react';

interface Props {
  column_1: string;
  column_2: string;
  column_3: string;
  column_4: string;
  column_5: string;
  column_6: string;
}

const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: alpha(theme.palette.common.black, 0.05),
    fontWeight: 'bold',
  },
}));

export const Table: React.FC<Props> = ({
  column_1,
  column_2,
  column_3,
  column_4,
  column_5,
  column_6,
}) => (
  <TableHead>
    <TableRow>
      <StyledTableCell align="center" style={{ width: '10%' }}>
        {column_1}
      </StyledTableCell>
      <StyledTableCell align="center" style={{ width: '20%' }}>
        {column_2}
      </StyledTableCell>
      <StyledTableCell style={{ width: '40%' }} align="center">
        {column_3}
      </StyledTableCell>
      <StyledTableCell style={{ width: '20%' }} align="center">
        {column_4}
      </StyledTableCell>
      <StyledTableCell style={{ width: '10%' }} align="center">
        {column_5}
      </StyledTableCell>
      <StyledTableCell style={{ width: '10%' }} align="center">
        {column_6}
      </StyledTableCell>
    </TableRow>
  </TableHead>
);
