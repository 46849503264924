export const communityRulesContent = [
  {
    title:
      'Please share your experiences and listen to the experiences of others, but remember that it’s not medical advice.',
    content: [
      {
        article:
          'Users of the HIgedi app are not qualified to give medical advice, even if they happen to be health professionals. No content on the HIgedi app and community should be considered medical advice. You should always consult a health practitioner who is familiar with your specific symptoms, diagnosis, and situation. ',
      },
    ],
  },
  {
    title: 'Please be nice and respect other user’s opinions and experience.',
    content: [
      {
        article:
          'Always approach users with no judgment especially when disagreeing with one another. We can all have different opinions and experiences! Because we recognize that everyone can learn from different perspectives, healthy, lively debates are encouraged. However, messages posted with the intent to instigate personal attacks are not allowed.',
      },
    ],
  },
  {
    title: 'Please share your knowledge. ',
    content: [
      {
        article: 'If you think you may be able to help another user then we’d love you to post it.',
      },
    ],
  },
  {
    title: 'Please respect all HIgedi users. Please no hate speech or bullying',
    content: [
      {
        article:
          "The HIgedi community is diverse and welcoming of all identities. That means we absolutely don’t allow or tolerate hate speech toward any individual or group of individuals. Please make sure everyone feels safe. Bullying of any kind isn't allowed, and degrading comments about things like race, religion, culture, sexual orientation, nationality, neurotypicality, political affiliation, physical appearance, home life or history, disability are not acceptable. There is absolutely no discrimination of any kind allowed in this community.",
      },
    ],
  },
  {
    title: 'Please don’t be rude, use bad language or offensive behaviour.',
    content: [
      {
        article:
          'If you’re ever concerned or worried about something someone has said, we urge that you report them immediately and our team will get right on it.',
      },
    ],
  },
  {
    title: 'Please don’t promote, advertise and post repetitive messages or links.',
    content: [
      {
        article:
          'Please do not post ads or promotions for your own or any company, product, organization, etc. You are welcome to share your genuine personal experiences by naming products or goods that you use and support. However, we remain the right to remove any posts deemed to be promotional.',
      },
    ],
  },
  {
    title: 'Please don’t spam',
    content: [
      {
        article: 'We don’t allow spam of any kind and actively monitor comments to prevent spam. ',
      },
    ],
  },
  {
    title: 'Please don’t self-promote.',
    content: [
      {
        article:
          'HIgedi is a place to share advice, find support, and support others — not gain status, attention, or “followers”. We don’t allow the promotion of your personal social media accounts or the accounts of others.',
      },
    ],
  },
  {
    title: 'Please have fun, feel comfortable and know we support you.',
    content: [
      {
        article:
          'Please feel free to ask questions that may not be gestational diabetes related. After all we are here to help. Feel free to post pictures of your beautiful babies once they are born.',
      },
    ],
  },
  {
    title: 'Updating guidelines.',
    content: [
      {
        article:
          'HIgedi reserves the right to modify and amend these guidelines at any time without notice. We will strive to notify the community of the changes, but it’s every user’s responsibility to remain informed of current community guidelines.',
      },
    ],
  },
];
