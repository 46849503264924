import React, { useEffect } from 'react';
import { Box, DialogActions, DialogContent, FormHelperText, Stack, TextField } from '@mui/material';
import { PopupState } from 'material-ui-popup-state/hooks';
import { useFormik } from 'formik';
import { AxiosError } from 'axios';
import { LoadingButton } from '@mui/lab';

import { Dialog } from '@src/components/Dialog';
import { IManager } from '@src/types';
import { useCreateManager, useEditManagerMutation } from '@src/services/managers';
import { validateManager } from '@src/utils/validators';

interface Props {
  dialogTitle: string;
  dialogState: PopupState;
  managerData: IManager;
  variant: 'add' | 'edit';
}

const useQueryStrategy = {
  add: useCreateManager,
  edit: useEditManagerMutation,
};

export const ManagerDialog: React.FC<Props> = ({
  dialogTitle,
  dialogState,
  variant,
  managerData,
}) => {
  const { mutateAsync, isLoading, error, reset } = useQueryStrategy[variant]();

  const formik = useFormik<IManager>({
    initialValues: {
      id: managerData.id,
      username: managerData.username,
      password: managerData.password,
      email: managerData.email,
    },
    validationSchema: validateManager.pick(['username', 'password', 'email']),
    onSubmit: async values => {
      if (isLoading) {
        return;
      }
      try {
        const { data } = await mutateAsync(values);
        if (!data.success) {
          formik.setErrors({ username: data.msg });
          return;
        }
        dialogState.close();
      } catch (e) {
        console.log(e);
      }
    },
  });

  useEffect(() => {
    return () => {
      reset();
      formik.resetForm();
      formik.setErrors({});
    };
  }, [dialogState]);

  return (
    <Dialog popupState={dialogState} title={dialogTitle}>
      <Box width={350}>
        <form onSubmit={formik.handleSubmit}>
          <DialogContent>
            <Stack spacing={2} pt={1}>
              <TextField
                id="username"
                label="username"
                name="username"
                value={formik.values.username}
                onChange={formik.handleChange}
                error={formik.touched.username && Boolean(formik.errors.username)}
                helperText={formik.touched.username && formik.errors.username}
              />
              <TextField
                id="email"
                type="email"
                label="email"
                name="email"
                value={formik.values.email}
                onChange={formik.handleChange}
                helperText={formik.touched.email ? formik.errors.email : ''}
                error={formik.touched.email && Boolean(formik.errors.email)}
              />
              <TextField
                id="password"
                type="password"
                label="password"
                name="password"
                value={formik.values.password}
                onChange={formik.handleChange}
                helperText={formik.touched.password ? formik.errors.password : ''}
                error={formik.touched.password && Boolean(formik.errors.password)}
              />
              <FormHelperText error>
                {error instanceof AxiosError && error?.response?.data?.msg}
              </FormHelperText>
            </Stack>
          </DialogContent>
          <DialogActions>
            <LoadingButton loading={isLoading} type="submit">
              Submit
            </LoadingButton>
          </DialogActions>
        </form>
      </Box>
    </Dialog>
  );
};
