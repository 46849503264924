import React from 'react';
import { Checkbox, IconButton, TableCell, TableRow } from '@mui/material';
import { Edit } from '@mui/icons-material';
import { useNavigate } from 'react-router-dom';

import { Product } from '@src/types';
import ImageWithLoading from '@src/components/ImageWithLoading';

interface Actions {
  select: (id: number) => void;
}

interface Props extends Product {
  checked: boolean;
  actions: Actions;
}

export const Row: React.FC<Props> = ({ id, checked, images, icon, categories, name, actions }) => {
  const navigate = useNavigate();
  const category = categories.map(i => i.category);

  return (
    <TableRow>
      <TableCell component="th" scope="row" align="center">
        <Checkbox onClick={() => actions.select(id)} checked={checked} />
      </TableCell>
      <TableCell align="center">
        {icon?.imgPath && (
          <ImageWithLoading
            src={icon?.imgPath}
            style={{ width: '75px', height: '75px', objectFit: 'cover' }}
            alt={'image'}
          />
        )}
      </TableCell>
      <TableCell align="center">{name}</TableCell>
      <TableCell align="center">{category.join(',')}</TableCell>
      <TableCell align="center">
        <IconButton onClick={() => navigate(`edit-${id}`)}>
          <Edit />
        </IconButton>
      </TableCell>
    </TableRow>
  );
};
