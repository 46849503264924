import React from 'react';
import { Navigate, RouteObject, useRoutes } from 'react-router-dom';

import Dashboard from '@src/pages/Dashboard';
import Managers from '@src/pages/Managers';
import Marketplace, { MarketplaceEditor, MarketplaceTable } from '@src/pages/Marketplace';
import SignIn from '@src/pages/SignIn';
import { DashboardLayout } from '@src/components/DashboardLayout';
import { PrivateRoute } from '@src/components/PrivateRoute';
import { PostReports } from '@src/pages/Reports/Post';
import { UserReports } from '@src/pages/Reports/User';

import { UsersTable } from './pages/Users/UsersTable';
import { PostsTable } from './pages/Posts/PostsTable';
import PrivacyPolicy from './pages/PrivacyPolicy';
import TermOfUse from './pages/TermOfUse';
import Help from './pages/Help';
import CommunityRules from './pages/CommunityRules';
import { BannedUsersTable } from './pages/BannedUsers/BannedUsersTable';
import Texts from './pages/Texts';

type DashboardRouteObject = RouteObject & {
  id: string;
  name: string;
  path: string;
};

export const drawerRoutes: Array<DashboardRouteObject> = [
  {
    id: 'dashboard',
    path: '/',
    name: 'Dashboard',
    element: (
      <PrivateRoute
        component={
          <DashboardLayout>
            <Dashboard />
          </DashboardLayout>
        }
      />
    ),
  },
  {
    id: 'marketplace',
    path: '/marketplace',
    name: 'Marketplace',
    element: (
      <PrivateRoute
        component={
          <DashboardLayout>
            <Marketplace />
          </DashboardLayout>
        }
      />
    ),
    children: [
      { path: '', element: <MarketplaceTable /> },
      {
        path: 'create',
        element: <MarketplaceEditor variant="create" />,
      },
      {
        path: 'edit-:id',
        element: <MarketplaceEditor variant="edit" />,
      },
    ],
  },
  {
    id: 'managers',
    path: '/managers',
    name: 'Managers',
    element: (
      <PrivateRoute
        component={
          <DashboardLayout>
            <Managers />
          </DashboardLayout>
        }
      />
    ),
  },
  {
    id: 'post_reports',
    path: '/reports/post',
    name: 'Post reports',
    element: (
      <PrivateRoute
        component={
          <DashboardLayout>
            <PostReports />
          </DashboardLayout>
        }
      />
    ),
    children: [{ path: '', element: <PostReports /> }],
  },
  {
    id: 'user_reports',
    path: '/reports/user',
    name: 'User reports',
    element: (
      <PrivateRoute
        component={
          <DashboardLayout>
            <UserReports />
          </DashboardLayout>
        }
      />
    ),
    children: [{ path: '', element: <UserReports /> }],
  },
  {
    id: 'posts',
    path: '/posts',
    name: 'Posts',
    element: (
      <PrivateRoute
        component={
          <DashboardLayout>
            <PostsTable />
          </DashboardLayout>
        }
      />
    ),
    children: [{ path: '', element: <PostsTable /> }],
  },
  {
    id: 'fake_users',
    path: '/users',
    name: 'Users',
    element: (
      <PrivateRoute
        component={
          <DashboardLayout>
            <UsersTable />
          </DashboardLayout>
        }
      />
    ),
    children: [{ path: '', element: <UsersTable /> }],
  },
  {
    id: 'blocked',
    path: '/blocked-users',
    name: 'Blocked users',
    element: (
      <PrivateRoute
        component={
          <DashboardLayout>
            <BannedUsersTable />
          </DashboardLayout>
        }
      />
    ),
    children: [{ path: '', element: <BannedUsersTable /> }],
  },
  {
    id: 'texts',
    path: '/texts',
    name: 'Texts',
    element: (
      <PrivateRoute
        component={
          <DashboardLayout>
            <Texts />
          </DashboardLayout>
        }
      />
    ),
  },
];

export const routes: Array<DashboardRouteObject | RouteObject> = [
  ...drawerRoutes,
  {
    path: '/signin',
    element: <SignIn />,
  },
  {
    path: '/privacy-policy',
    element: <PrivacyPolicy />,
  },
  {
    path: '/term-of-use',
    element: <TermOfUse />,
  },
  {
    path: '/help',
    element: <Help />,
  },
  {
    path: '/community-rules',
    element: <CommunityRules />,
  },
  {
    path: '*',
    element: <Navigate to="/" />,
  },
];

export const DashboardRouter: React.FC = () => useRoutes(routes);
