import axios from 'axios';

import { getAccessToken } from '@src/utils/auth';

import { createAxiosResponseInterceptor } from './auth/api';

export interface HigediResponse<Body = void> {
  status: number;
  success: boolean;
  msg: string;
  body: Body;
}

const API_URL = process.env.REACT_APP_API_URL ?? 'http://localhost:5000/api/adminpanel';

export const axiosInstance = axios.create({
  baseURL: API_URL,
});

axiosInstance.interceptors.request.use(config => {
  return {
    ...config,
    headers: {
      Authorization: `Bearer ${getAccessToken() ?? ''}`,
    },
  };
});

createAxiosResponseInterceptor();

export * from 'axios';
