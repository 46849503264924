import toast, { Toaster } from 'react-hot-toast';

const Toast = () => {
  return <Toaster position="top-center" />;
};

export const showToast = (
  status: 'error' | 'success' | 'warning' | 'loading',
  message: string,
  toastIdToHide?: string,
) => {
  if (toastIdToHide) {
    hideToast(toastIdToHide);
  }

  if (status === 'error') {
    return toast.error(message);
  }
  if (status === 'success') {
    return toast.success(message);
  }
  if (status === 'warning') {
    return toast(message);
  }
  if (status === 'loading') {
    return toast.loading(message);
  }
};

export const hideToast = (toastId?: string) => toast.dismiss(toastId);

// make typescript generic which will take type from given promise
export function toastPromise<T>(
  promise: Promise<T>,
  { success, error, loading }: { success: string; error?: string; loading?: string },
) {
  return toast.promise(promise, {
    ...(loading ? { loading } : { loading: success }),
    success,
    ...(error ? { error } : { error: success }),
  });
}

export default Toast;
