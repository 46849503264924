import axios from 'axios';

import { IReports } from '@src/types';
import { getAccessToken } from '@src/utils/auth';

import { axiosInstance, HigediResponse } from '../axios';

const url = process.env.REACT_APP_API;

export const getPostReports = async () =>
  await axios.get<HigediResponse<{ reports: IReports[] }>>(`${url}/reports/post`, {
    headers: {
      Authorization: `Bearer ${getAccessToken() ?? ''}`,
    },
  });

export const deletePostReport = async (id: number) =>
  await axiosInstance.delete<HigediResponse>(`${url}/reports/post/${id}`);

export const updatePostReport = async (id: number, status: string) =>
  await axios.patch<HigediResponse>(
    `${url}/reports/post/${id}`,
    { status },
    {
      headers: {
        Authorization: `Bearer ${getAccessToken() ?? ''}`,
      },
    },
  );

export const getUserReports = async () =>
  await axios.get<HigediResponse<{ reports: IReports[] }>>(`${url}/reports/user`, {
    headers: {
      Authorization: `Bearer ${getAccessToken() ?? ''}`,
    },
  });

export const deleteUserReport = async (id: number) =>
  await axiosInstance.delete<HigediResponse>(`${url}/reports/user/${id}`);

export const updateUserReport = async (id: number, status: string, email: string) =>
  await axios.patch<HigediResponse>(
    `${url}/reports/user/${id}`,
    { status, email },
    {
      headers: {
        Authorization: `Bearer ${getAccessToken() ?? ''}`,
      },
    },
  );
