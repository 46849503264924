import React, { useState } from 'react';
import { IconButton, Typography, TableCell, TableRow } from '@mui/material';
import RemoveCircleOutlineIcon from '@mui/icons-material/RemoveCircleOutline';

import { IReport, IUserExperience } from '@src/types';
import { UserInfo } from '@src/components/UserInfo';
import { AxiosResponse, HigediResponse } from '@src/services';

import { RemoveModal } from '../Modals/Remove';
import { toastPromise } from '../Toast';

interface ReportProps {
  key: number;
  report: IReport;
  setReports: React.Dispatch<React.SetStateAction<IReport[]>>;
  reports: IReport[];
  deleteReport: (id: number) => Promise<AxiosResponse<HigediResponse<void>>>;
}

export const Report: React.FC<ReportProps> = ({ report, setReports, reports, deleteReport }) => {
  const id = report.report_id;
  const [showUser, setShowUser] = useState<boolean>(false);
  const [showRemoveModal, setShowRemoveModal] = useState(false);

  const handleDelete = async () => {
    if (id) {
      await toastPromise<Awaited<ReturnType<typeof deleteReport>>>(deleteReport(id), {
        loading: 'Removing report...',
        success: 'Report removed successfully',
        error: 'Something went wrong, please try again',
      });
      const new_reports = reports.filter(i => i.report_id !== id);
      setReports(new_reports);
    }

    setShowRemoveModal(!showRemoveModal);
  };

  return (
    <>
      <TableRow>
        <TableCell component="th" scope="row" align="center">
          <Typography variant="h6" component="h6">
            {new Date(report.createdAt).toLocaleDateString()}
          </Typography>
        </TableCell>
        <TableCell align="center">{report.reason}</TableCell>
        <TableCell align="center">{report.description}</TableCell>
        <TableCell align="center">{report.status}</TableCell>
        <TableCell
          align="center"
          style={{ cursor: 'pointer' }}
          onClick={() => setShowUser(!showUser)}
        >
          {report.reporter?.email || 'DELETED USER'}
        </TableCell>
        <UserInfo
          showUser={showUser}
          setShowUser={setShowUser}
          user={
            report.reporter || {
              id: 0,
              experience: IUserExperience.new_comer,
              isBanned: false,
              email: '',
              firstName: 'DELETED',
              lastName: 'USER',
              avatarPath: '',
            }
          }
        />
        <TableCell align="center">
          <IconButton onClick={() => setShowRemoveModal(!showRemoveModal)} color={'error'}>
            <RemoveCircleOutlineIcon />
          </IconButton>
        </TableCell>
        <RemoveModal
          showRemoveModal={showRemoveModal}
          setShowRemoveModal={setShowRemoveModal}
          handleDelete={handleDelete}
          content={'Are you sure you wanna remove this report ?'}
          title={'Removing Report'}
        />
      </TableRow>
    </>
  );
};
