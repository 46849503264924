import * as React from 'react';
import {
  Button,
  Box,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  useMediaQuery,
  Backdrop,
  CircularProgress,
} from '@mui/material';
import { useTheme } from '@mui/material/styles';

interface Props {
  showRemoveModal: boolean;
  setShowRemoveModal: React.Dispatch<React.SetStateAction<boolean>>;
  handleDelete?: () => Promise<void> | void;
  content: string;
  title: string;
  approveBtnText?: string;
  isLoading?: boolean;
}

export const RemoveModal: React.FC<Props> = ({
  showRemoveModal,
  setShowRemoveModal,
  handleDelete,
  content,
  title,
  isLoading,
  approveBtnText,
}) => {
  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down('md'));

  return (
    <Box>
      <Dialog
        fullScreen={fullScreen}
        open={showRemoveModal}
        onClose={() => setShowRemoveModal(!showRemoveModal)}
        aria-labelledby="responsive-dialog-title"
      >
        <DialogTitle id="responsive-dialog-title" style={{ color: 'red' }}>
          {title}
        </DialogTitle>
        <DialogContent>
          <DialogContentText>{content}</DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button autoFocus color="success" onClick={() => setShowRemoveModal(!showRemoveModal)}>
            Cancel
          </Button>
          {isLoading && (
            <Backdrop
              sx={{ color: '#fff', zIndex: () => theme.zIndex.drawer + 1 }}
              open={isLoading as boolean}
            >
              <CircularProgress color="inherit" />
            </Backdrop>
          )}
          <Button onClick={handleDelete} autoFocus color="error" disabled={isLoading}>
            {approveBtnText ? approveBtnText : 'Agree'}
          </Button>
        </DialogActions>
      </Dialog>
    </Box>
  );
};
