import React, { useEffect, useState } from 'react';
import {
  Autocomplete,
  Box,
  Button,
  Checkbox,
  Paper,
  Stack,
  TextField,
  Typography,
} from '@mui/material';
import { CheckBox, CheckBoxOutlineBlank, ListAlt } from '@mui/icons-material';
import { useFormik } from 'formik';
import { useSearchParams } from 'react-router-dom';

import { SearchBar } from '@src/components/SearchBar';
import { CategoriesLayout } from '@src/components/CategoryLayout/Categories';
import { Category, MarketplaceFilters } from '@src/types';
import { useGetCategoriesQuery, useMarketplaceFiltersCache } from '@src/services/marketplace/hooks';

export const Filters: React.FC = () => {
  const [searchParams, setSearchParams] = useSearchParams();
  const { data } = useGetCategoriesQuery();
  const [showCategories, setShowCategories] = useState(false);

  const [filtersCache, setFiltersCache] = useMarketplaceFiltersCache();

  const categories = data ? data.data.body.categories : [];

  const formik = useFormik<MarketplaceFilters>({
    initialValues: filtersCache,

    onSubmit(values) {
      if (values.name) {
        searchParams.set('name', values.name);
      } else {
        searchParams.delete('name');
      }

      searchParams.delete('categories');
      values.categories.forEach(categories => {
        searchParams.append('categories', categories.category);
      });

      setSearchParams(searchParams);
      setFiltersCache(values);
    },

    onReset() {
      searchParams.delete('name');
      searchParams.delete('categories');
      setSearchParams(searchParams);
    },
  });

  useEffect(() => {
    if (searchParams.get('name')) {
      formik.setFieldValue('name', searchParams.get('name'));
    }
    if (searchParams.getAll('categories')?.length) {
      const categoriesFilter: Category[] = [];
      searchParams.getAll('categories').forEach(category => {
        const matchedCategory = categories.find(cat => cat.category === category);
        if (matchedCategory) {
          categoriesFilter.push(matchedCategory);
        }
      });
      formik.setFieldValue('categories', categoriesFilter);
    }
  }, [searchParams, categories]);

  useEffect(() => {
    if (filtersCache.name) {
      formik.setFieldValue('name', filtersCache.name);
      searchParams.set('name', filtersCache.name);
    }
    if (filtersCache.categories?.length) {
      formik.setFieldValue('categories', filtersCache.categories);
      searchParams.delete('categories');
      filtersCache.categories.forEach(category => {
        searchParams.append('categories', category.category);
      });
    }
    if (filtersCache.name || filtersCache.categories?.length) {
      setSearchParams(searchParams);
    }
  }, [filtersCache]);

  return (
    <Paper variant="outlined">
      <Box p={2}>
        <form onSubmit={formik.handleSubmit}>
          <Stack spacing={2}>
            <Typography>Product name</Typography>
            <SearchBar
              placeholder="Product name"
              inputProps={{
                'aria-label': 'search',
                name: 'name',
                value: formik.values.name,
                onChange: formik.handleChange,
              }}
            />

            <Box
              style={{
                display: 'flex',
                justifyContent: 'space-between',
                alignItems: 'center',
              }}
            >
              <Typography>Categories</Typography>
              <Button variant="outlined" onClick={() => setShowCategories(!showCategories)}>
                <ListAlt />
              </Button>
            </Box>
            <CategoriesLayout
              setShowCategories={setShowCategories}
              showCategories={showCategories}
            />
            <Autocomplete
              multiple
              id="categories-autocomplete"
              options={categories}
              value={formik.values.categories}
              getOptionLabel={option => option.category}
              onChange={(_, value) => {
                formik.setFieldValue('categories', value);
              }}
              renderOption={(props, option, { selected }) => (
                <li {...props}>
                  <Checkbox
                    icon={<CheckBoxOutlineBlank />}
                    checkedIcon={<CheckBox />}
                    style={{ marginRight: 8 }}
                    checked={selected}
                  />
                  {option.category}
                </li>
              )}
              renderInput={params => (
                <TextField {...params} label="Category" placeholder="Type to search" />
              )}
            />

            <Stack direction="row" justifyContent="space-between">
              <Button variant="outlined" onClick={() => formik.resetForm()}>
                Reset
              </Button>
              <Button variant="outlined" type="submit">
                Apply
              </Button>
            </Stack>
          </Stack>
        </form>
      </Box>
    </Paper>
  );
};
