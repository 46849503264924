import { axiosInstance, HigediResponse } from '@src/services/axios';
import { IManager } from '@src/types';

import { GetAllManagers, PatchManager, PostManager } from './types';

const MANAGERS_ENDPOINT = 'managers';

export const getCurrentManager = async () =>
  await axiosInstance.get<HigediResponse<{ user: IManager }>>(
    `/${MANAGERS_ENDPOINT}/currentManager`,
  );

export const getManagers = async (username = '') =>
  await axiosInstance.get<GetAllManagers>(
    `/${MANAGERS_ENDPOINT}${username ? `?username=${username}` : ''}`,
  );

export const createManager = async (manager: Omit<IManager, 'id'>) =>
  await axiosInstance.post<PostManager>(`/signup`, manager);

export const editManager = async (manager: IManager) =>
  await axiosInstance.patch<PatchManager>(`/${MANAGERS_ENDPOINT}/${manager.id}`, manager);

export const deleteManager = async (id: number) =>
  await axiosInstance.delete<HigediResponse>(`/${MANAGERS_ENDPOINT}/${id}`);
