import axios, { AxiosRequestConfig } from 'axios';

import { logout, setAccessToken } from '@src/utils/auth';

import { axiosInstance, HigediResponse } from '../axios';
import { SignInRequest, SignInResponse } from './types';

export const signIn = async (formData: SignInRequest) =>
  await axiosInstance.post<SignInResponse>(`/signin`, formData);

export const signOut = async (accessToken: string) =>
  await axiosInstance.post<HigediResponse>(`/signout`, {
    headers: {
      Authorization: `Bearer ${accessToken}`,
    },
  });

export const createAxiosResponseInterceptor = () => {
  const interceptor = axiosInstance.interceptors.response.use(
    response => response,
    error => {
      if (!error.response) {
        return Promise.reject(error);
      }

      if (error.response.status === 401) {
        logout();
        return;
      }

      if (error.response.status !== 401) {
        return Promise.reject(error);
      }

      const axiosRequestConfig = error.config as AxiosRequestConfig;

      axiosInstance.interceptors.response.eject(interceptor);

      try {
        const newToken = error.response.data.body.new_access_token;

        if (typeof newToken !== 'string') {
          throw new Error();
        }

        setAccessToken(newToken);

        return axiosInstance.request({
          ...axiosRequestConfig,
          headers: {
            ...axiosRequestConfig.headers,
            Authorization: `Bearer ${newToken}`,
          },
        });
      } catch (e) {
        logout();
        location.replace(`${location.host}/signin`);
        alert(`redirect to sign in ${location.host}`);
      } finally {
        createAxiosResponseInterceptor();
      }

      return axiosRequestConfig;
    },
  );
};

export const restorePassword = async (email: string) => {
  const url = process.env.REACT_APP_API;

  try {
    const { data } = await axios.patch<HigediResponse>(`${url}/auth/restore`, {
      email,
    });

    return data;
  } catch (error: any) {
    return error?.response?.data;
  }
};
