import React, { useEffect } from 'react';
import { Box, Stack, Typography } from '@mui/material';
import { privacyPolicyContent } from '@src/utils/privacyPolicyContent';

interface IContent {
  article?: string;
  ulList?: Array<string>;
  listGap?: boolean;
}

const PrivacyPolicy: React.FC = () => {
  useEffect(() => {
    document.title = 'Privacy Policy';
  }, []);

  return (
    <Box display={'flex'} justifyContent={'center'} pt={5} pb={5} pl={3} pr={3} overflow={'hidden'}>
      <Box
        display={'flex'}
        flexDirection={'column'}
        alignItems={'center'}
        width={'100%'}
        fontFamily={'Red Hat Display, sans-serif!important'}
        maxWidth={'720px'}
      >
        <Box
          fontWeight={700}
          fontSize={{ xs: '16px', md: '32px' }}
          color={'black'}
          lineHeight={{ xs: '21px', md: '42px' }}
        >
          HIGEDI PRIVACY POLICY
        </Box>
        <Box
          lineHeight={{ xs: '16px', md: '21px' }}
          fontWeight={400}
          fontSize={{ xs: '14px', md: '16px' }}
          color={'black'}
          mt={'8px'}
        >
          Last updated: <span style={{ fontWeight: 500 }}>February 10, 2023</span>
        </Box>
        <Box width={'100%'} mt={{ xs: '20px', md: '48px' }} mb={{ xs: '16px', md: '24px' }}>
          <Typography
            fontFamily={'Red Hat Display, sans-serif!important'}
            fontSize={{ xs: '14px', md: '20px' }}
            lineHeight={{ xs: '19px', md: '140%' }}
            fontWeight={400}
          >
            Hi, welcome to HIgedi's Privacy Policy.
          </Typography>
        </Box>
        <Box width={'100%'}>
          <Stack direction={'column'} spacing={{ xs: '16px', md: '24px' }}>
            {privacyPolicyContent.map(
              (contentData: { title: string | undefined; content: Array<IContent> }, index) => {
                return (
                  <Box key={index}>
                    {contentData.title && (
                      <Typography
                        fontFamily={'Red Hat Display, sans-serif!important'}
                        fontSize={{ xs: '16px', md: '24px' }}
                        lineHeight={{ xs: '21px', md: '140%' }}
                        fontWeight={700}
                      >
                        {contentData.title}
                      </Typography>
                    )}
                    {contentData.content.map((data, index) => {
                      return (
                        <Box key={index}>
                          {data.article && (
                            <Typography
                              mt={contentData.title ? { xs: '16px', md: '24px' } : 0}
                              fontFamily={'Red Hat Display, sans-serif!important'}
                              fontSize={{ xs: '14px', md: '20px' }}
                              lineHeight={{ xs: '22px', md: '140%' }}
                              fontWeight={400}
                              dangerouslySetInnerHTML={{
                                __html: `${data.article}`,
                              }}
                            />
                          )}
                          {data.ulList && (
                            <Box mt={data.listGap ? { xs: '20px', md: '40px' } : 0}>
                              <ul
                                style={{
                                  display: 'flex',
                                  flexDirection: 'column',
                                  paddingInlineStart: '30px',
                                  marginBlockEnd: 0,
                                  marginBlockStart: 0,
                                }}
                              >
                                {data.ulList.map(text => {
                                  return (
                                    <li key={text}>
                                      <Typography
                                        fontFamily={'Red Hat Display, sans-serif!important'}
                                        fontSize={{ xs: '14px', md: '20px' }}
                                        lineHeight={{ xs: '22px', md: '140%' }}
                                        fontWeight={400}
                                        dangerouslySetInnerHTML={{
                                          __html: `${text}`,
                                        }}
                                      />
                                    </li>
                                  );
                                })}
                              </ul>
                            </Box>
                          )}
                        </Box>
                      );
                    })}
                  </Box>
                );
              },
            )}
          </Stack>
        </Box>
      </Box>
    </Box>
  );
};

export default PrivacyPolicy;
