import React, { useEffect, useState } from 'react';
import {
  Autocomplete,
  Avatar,
  Box,
  Button,
  Checkbox,
  Grid,
  IconButton,
  InputAdornment,
  Stack,
  TextField,
  TextareaAutosize,
} from '@mui/material';
import { useFormik } from 'formik';
import {
  ImageNotSupported,
  HighlightOffOutlined,
  CheckBoxOutlineBlank,
  CheckBox,
} from '@mui/icons-material';
import { useLocation, useNavigate } from 'react-router-dom';
import { LoadingButton } from '@mui/lab';

import {
  useCreateProductMutation,
  useEditProductMutation,
  useGetOneProductQuery,
} from '@src/services/marketplace/hooks';
import { useGetCategoriesQuery } from '@src/services/marketplace/hooks';
import { Product } from '@src/types';
import { validateProduct } from '@src/utils/validators';
import { toBase64 } from '@src/utils/toBase64';
import { deleteImage } from '@src/services/marketplace/api';

interface Props {
  variant: 'create' | 'edit';
}

const useEditorStrategy = {
  create: useCreateProductMutation,
  edit: useEditProductMutation,
};

export const MarketplaceEditor: React.FC<Props> = ({ variant }) => {
  const navigate = useNavigate();
  const { mutateAsync, isLoading } = useEditorStrategy[variant]();
  const { pathname } = useLocation();
  const getAllCategories = useGetCategoriesQuery();
  const [inputValue, setInputValue] = useState('');
  const [images, setImages] = useState<any[]>([]);
  const [imagesKey, setImagesKey] = useState<any[]>([]);

  const [, id] = pathname.split('-');

  const { refetch } = useGetOneProductQuery(parseInt(id, 10));

  const formik = useFormik<Omit<Product, 'id'>>({
    initialValues: {
      name: '',
      link: '',
      price: 0,
      category: '',
      categories: [],
      images: [],
      description: '',
      ingredients: '',
    },
    validationSchema: validateProduct,

    async onSubmit(values) {
      if (values.categories.length === 0) {
        formik.setErrors({ categories: 'Please select category' });
        return;
      }

      const update = {
        id: parseInt(id, 10),
        name: values.name,
        link: values.link,
        price: values.price,
        description: values.description,
        ingredients: values.ingredients,
        categories: values.categories,
        images,
      };

      await Promise.all(
        imagesKey.map(async key => {
          await deleteImage(key);
        }),
      );

      const result = await mutateAsync(update);

      if (result.data.success) {
        navigate('/marketplace');
      }
    },
  });

  useEffect(() => {
    const fillData = async () => {
      if (variant !== 'edit') {
        return;
      }

      const { data } = await refetch();
      const product = data?.data.body?.product;
      const product_images_length = product?.images?.length ?? [].length;

      if (product_images_length > 0) {
        setImages(product?.images || []);
        // product?.images?.forEach((i, idx) => {
        //   if (i.imgKey && i.imgKey?.startsWith('products/icon')) {
        //     product?.images?.splice(idx, 1);
        //   }
        // });
      }

      if (!product) {
        navigate('/marketplace');
        return;
      }

      await formik.setValues({
        name: product.name,
        link: product.link,
        price: product.price,
        categories: product.categories,
        images: product.images || [],
        description: product.description,
        ingredients: product.ingredients,
      });
    };

    void fillData();
  }, []);

  const onChangeImage = async (event: React.ChangeEvent<HTMLInputElement>) => {
    if (event.target.files && event.target.files?.length > 1) {
      const promises: any[] = [];
      const files = Object.values(event.target.files);
      files.forEach(file => promises.push(toBase64(file)));
      const result = await Promise.all(promises);
      const new_images = result.map((img, idx) => ({
        id: images.length + result.length + idx,
        src: URL.createObjectURL(files[idx]),
        base64: img.base64,
        type_of_img: files[idx].type.replace('image/', ''),
      }));
      setImages([...images, ...new_images]);
      return;
    }

    if (event.target.files !== null) {
      const { base64 } = await toBase64(event.target.files[0]);

      setImages([
        ...images,
        {
          id: images.length + 1,
          src: URL.createObjectURL(event.target.files[0]),
          base64,
          type_of_img: event.target.files[0].type.replace('image/', ''),
        },
      ]);
    }
  };

  const handleDeleteImage = (idImg: number, key: string | undefined) => {
    const newImages = images.filter(img => img.id !== idImg);
    setImages(newImages);
    setImagesKey([...imagesKey, key]);
  };

  const isDescriptionErrored =
    (formik.values.description?.length && formik.values.description?.length > 1000) ||
    (formik.touched.description && Boolean(formik.errors.description));

  const isIngredientsErrored =
    (formik.values.ingredients?.length && formik.values.ingredients?.length > 1000) ||
    (formik.touched.ingredients && Boolean(formik.errors.ingredients));

  return (
    <Box style={{ flexGrow: 1 }}>
      <form onSubmit={formik.handleSubmit}>
        <Stack spacing={2}>
          <Grid spacing={2} container justifyContent="space-between">
            <Grid item sm={4}>
              <Stack spacing={2}>
                <TextField
                  label="Product name"
                  name="name"
                  value={formik.values.name}
                  onChange={formik.handleChange}
                  helperText={formik.touched.name ? formik.errors.name : ''}
                  error={formik.touched.name && Boolean(formik.errors.name)}
                />

                <TextField
                  label="Link"
                  name="link"
                  value={formik.values.link}
                  onChange={formik.handleChange}
                  helperText={formik.touched.link ? formik.errors.link : ''}
                  error={formik.touched.link && Boolean(formik.errors.link)}
                />

                <TextField
                  label="Price"
                  name="price"
                  value={formik.values.price}
                  onChange={formik.handleChange}
                  type="text"
                  placeholder="22.99"
                  InputProps={{
                    inputMode: 'numeric',
                    startAdornment: <InputAdornment position="start">$</InputAdornment>,
                  }}
                  helperText={formik.touched.price ? formik.errors.price : ''}
                  error={formik.touched.price && Boolean(formik.errors.price)}
                />

                <Autocomplete
                  multiple
                  onChange={(_, value) => {
                    formik.setFieldValue('categories', value);
                  }}
                  value={formik.values.categories}
                  inputValue={formik.values.category || inputValue}
                  onInputChange={(event, newInputValue) => {
                    setInputValue(newInputValue);
                  }}
                  loading={getAllCategories.isLoading}
                  id="categories-autocomplete"
                  options={
                    getAllCategories?.data?.data.success
                      ? getAllCategories?.data.data.body.categories
                      : formik.values.categories
                  }
                  getOptionLabel={option => option.category}
                  sx={{ width: 300 }}
                  renderOption={(props, option, { selected }) => {
                    const select =
                      formik.values.categories?.some(i => i.category === option.category) ||
                      selected;
                    return (
                      <li {...props}>
                        <Checkbox
                          icon={<CheckBoxOutlineBlank />}
                          checkedIcon={<CheckBox />}
                          style={{ marginRight: 8 }}
                          checked={select}
                        />
                        {option.category}
                      </li>
                    );
                  }}
                  renderInput={params => {
                    if (formik.touched.categories && Boolean(formik.errors.categories)) {
                      return (
                        <TextField
                          error={true}
                          helperText="Please select category"
                          {...params}
                          label="Select Category"
                          variant="outlined"
                        />
                      );
                    }

                    return (
                      <TextField {...params} label="Choose category" placeholder="Type to Search" />
                    );
                  }}
                />
              </Stack>
            </Grid>

            <Button variant="outlined" sx={{ width: 100, alignSelf: 'center' }} component="label">
              Upload
              <input
                hidden
                accept="image/jpeg, image/png, image/gif, image/webp"
                multiple
                name="image"
                type="file"
                onChange={onChangeImage}
              />
            </Button>
            <Grid item sm={5} maxHeight={500} justifyContent="center">
              <Stack
                spacing={2}
                style={{
                  display: 'grid',
                  gridTemplateColumns: `repeat(${
                    formik?.values?.images?.length || images.length
                  }, 1fr)`,
                  gridAutoRows: '100px',
                  gridGap: '15px',
                }}
              >
                <Box
                  style={{
                    display: 'grid',
                    gridAutoFlow: 'column dense',
                    gridTemplateRows: '150px 150px',
                    gridAutoColumns: '200px',
                  }}
                >
                  {images?.map(image => (
                    <Box key={image.id} style={{ display: 'flex', justifyContent: 'left' }}>
                      <Avatar
                        sx={{
                          width: '150px',
                          height: '150px',
                          objectFit: 'contain',
                        }}
                        key={image.src}
                        variant="square"
                        imgProps={{
                          sx: { objectFit: 'contain' },
                        }}
                        src={image.src || image.imgPath}
                        alt={`${image.id}`}
                      >
                        <ImageNotSupported sx={{ color: 'text.primary' }} fontSize="large" />
                      </Avatar>
                      <IconButton
                        aria-label="delete"
                        data-key={image.imgKey || ''}
                        style={{
                          backgroundColor: 'transparent',
                          alignItems: 'flex-start',
                          height: '50px',
                        }}
                        tabIndex={image.id}
                        onClick={event =>
                          handleDeleteImage(
                            event.currentTarget.tabIndex,
                            event.currentTarget.dataset.key,
                          )
                        }
                      >
                        <HighlightOffOutlined fontSize="inherit" cursor="pointer" color="error" />
                      </IconButton>
                    </Box>
                  ))}
                </Box>
              </Stack>
            </Grid>
          </Grid>

          <Stack spacing={2}>
            <TextareaAutosize
              aria-label="maximum height"
              onChange={event => {
                formik.setFieldValue('description', event.target.value);
              }}
              minRows={2}
              style={{
                width: '100%',
                borderRadius: 3,
                padding: '15px',
                minHeight: 50,
                margin: '10px 0 0 0',
                fontFamily: '"Roboto","Helvetica","Arial",sans-serif',
                fontSize: '16px',
                border: isDescriptionErrored ? '1px solid #d32f2f' : '',
              }}
              placeholder="Description"
              value={formik.values.description || ''}
            />
            <div
              style={{
                fontSize: '0.75rem',
                marginTop: '5px',
                display: 'flex',
                justifyContent: 'space-between',
                color: isDescriptionErrored ? '#d32f2f' : '',
              }}
            >
              {formik.touched.description && Boolean(formik.errors.description) ? (
                <div>{formik.errors.description}</div>
              ) : (
                <div />
              )}
              {formik.values.description?.length} / 1000
            </div>
            <TextareaAutosize
              aria-label="maximum height"
              onChange={event => {
                formik.setFieldValue('ingredients', event.target.value);
              }}
              minRows={2}
              style={{
                width: '100%',
                borderRadius: 3,
                padding: '15px',
                minHeight: 50,
                margin: '10px 0 0 0',
                fontFamily: '"Roboto","Helvetica","Arial",sans-serif',
                fontSize: '16px',
                border: isIngredientsErrored ? '1px solid #d32f2f' : '',
              }}
              placeholder="More info"
              value={formik.values.ingredients || ''}
            />
            <div
              style={{
                fontSize: '0.75rem',
                marginTop: '5px',
                display: 'flex',
                justifyContent: 'space-between',
                color: isIngredientsErrored ? '#d32f2f' : '',
              }}
            >
              {formik.touched.ingredients && Boolean(formik.errors.ingredients) ? (
                <div>{formik.errors.ingredients}</div>
              ) : (
                <div />
              )}
              {formik.values.ingredients?.length} / 1000
            </div>
          </Stack>

          <Stack spacing={2} direction="row" justifyContent="flex-end">
            <Button
              disabled={isLoading}
              variant="outlined"
              onClick={() => navigate('/marketplace')}
            >
              Cancel
            </Button>
            <LoadingButton loading={isLoading} type="submit" variant="outlined">
              Save
            </LoadingButton>
          </Stack>
        </Stack>
      </form>
    </Box>
  );
};
