import React, { useEffect, useState } from 'react';

import { TableComponent } from '@src/components/Table/Table';
import { IPost, IUser } from '@src/types';
import { getPosts } from '@src/services/posts/api';
import { Columns } from '@src/components/Table/Columns';
import { getUsers } from '@src/services/users/api';

export const PostsTable: React.FC = () => {
  const [page, setPage] = useState(0);
  const [limit, setLimit] = useState(10);
  const [posts, setPosts] = useState<IPost[]>([]);
  const [users, setUsers] = useState<IUser[]>([]);
  const [countPosts, setCountPosts] = useState<number>(1);
  const [error, setError] = useState<boolean>(false);
  const [isUsersLoading, setUsersLoading] = useState<boolean>(true);
  const [isPostsLoading, setPostsLoading] = useState<boolean>(true);
  const [showModal, setShowModal] = useState<boolean>(false);

  const column1 = 'id';
  const column2 = 'userId';
  const column3 = 'text';
  const column4 = 'imgPath';
  const column5 = 'count_comments';
  const label1 = 'ID';
  const label2 = 'User ID';
  const label3 = 'Text';
  const label4 = 'Image';
  const label5 = 'Count Comments';
  const columns = Columns(
    column1,
    column2,
    column3,
    column4,
    column5,
    label1,
    label2,
    label3,
    label4,
    label5,
  );

  useEffect(() => {
    const fetchPosts = async () => {
      const {
        data: {
          success,
          body: { count, rows },
        },
      } = await getPosts(page, limit);

      setCountPosts(count);
      setPosts(rows);
      setPostsLoading(false);
      setError(!success);
    };

    const fetchUsers = async () => {
      const {
        data: {
          success,
          body: { rows },
        },
      } = await getUsers(page, 0);

      setUsers(rows);
      setUsersLoading(false);
      setError(!success);
    };

    fetchPosts();
    fetchUsers();
  }, [page, limit]);

  return (
    <TableComponent
      type="post"
      showModal={showModal}
      setShowModal={setShowModal}
      page={page}
      setPage={setPage}
      limit={limit}
      setLimit={setLimit}
      count={countPosts}
      columns={columns}
      rows={posts}
      users={users}
      setRows={setPosts}
      error={error}
      isLoading={isPostsLoading || isUsersLoading}
    />
  );
};
