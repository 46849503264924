import axios, { AxiosError } from 'axios';

import { IEmoji, IKeyword, IPost, IUser } from '@src/types';
import { getAccessToken } from '@src/utils/auth';

import { HigediResponse, axiosInstance } from '../axios';
import { showToast } from '@src/components/Toast';

const url = process.env.REACT_APP_API;

export const getPosts = async (page: number, limit: number) =>
  await axios.get<HigediResponse<{ rows: IPost[]; count: number }>>(
    `${url}/post/all?page=${page}&limit=${limit}`,
    {
      headers: {
        Authorization: `Bearer ${getAccessToken() ?? ''}`,
      },
    },
  );

export const updatePostLikes = async (page: number, limit: number, postId: number, likes: number) =>
  await axios.post<HigediResponse<{ rows: IPost[]; count: number }>>(
    `${url}/post/likes?page=${page}&limit=${limit}&id=${postId}`,
    { likes },
    {
      headers: {
        Authorization: `Bearer ${getAccessToken() ?? ''}`,
      },
    },
  );

export const createPost = async (text: string, img: string, keywordIds: number[], id: number) => {
  try {
    return await axios.post<HigediResponse<{ post: IPost }>>(
      `${url}/post/createpost`,
      { text, img, keywordIds, id },
      {
        headers: {
          Authorization: `Bearer ${getAccessToken() ?? ''}`,
        },
      },
    );
  } catch (error) {
    if (error instanceof AxiosError) {
      if (error.response?.status === 401) {
        showToast('error', 'You are not authorized');
        return;
      }
      showToast('error', error.response?.data.message ?? 'Something went wrong');
      return;
    }
    showToast('error', 'Cannot create post');
  }
};

export const createCommentToPost = async (text: string, user: IUser, postId: number) =>
  await axios.post<HigediResponse>(
    `${url}/post/createcomment`,
    { text, user, postId },
    {
      headers: {
        Authorization: `Bearer ${getAccessToken() ?? ''}`,
      },
    },
  );

export const getKeywords = async () =>
  await axios.get<HigediResponse<{ keywords: IKeyword[] }>>(`${url}/keywords`, {
    headers: {
      Authorization: `Bearer ${getAccessToken() ?? ''}`,
    },
  });

export const deletePost = async (postId: number) =>
  await axiosInstance.delete<HigediResponse>(`${url}/post/${postId}`);

export const getEmoji = async () =>
  await axiosInstance.get<HigediResponse<{ emojis: IEmoji[] }>>(`${url}/emojis`);
