export const privacyPolicyContent = [
  {
    title: undefined,
    content: [
      {
        article:
          '<b>For the purposes of this Privacy Policy</b> (the following definitions shall have the same meaning\
             regardless of whether they appear in singular or in plural):',
        ulList: [
          '"Account" means a unique account created for You to access our Service or parts of our Service.',
          '“Application” or “App” means the software program provided by the Company downloaded by You on any electronic device, named HIgedi.  ',
          'Company" (referred to as either "the Company", "We", "Us" or "Our" in this Agreement) refers to Wildcard OY',
          '"Cookies" are small files that are placed on Your computer, mobile device or any other device by a website, containing the details of Your browsing history on that website among its many uses.',
          '"Device" means any device that can access the Service such as a computer, a cell phone or a digital tablet.',
          '"Personal Data" is any information that relates to an identified or identifiable individual.',
          '"Service" refers to the Website.',
          '"Service Provider" means any natural or legal person who processes the data on behalf of the Company. \
          It refers to third-party companies or individuals employed by the Company to facilitate the Service, to \
          provide the Service on behalf of the Company, to perform services related to the Service or to assist the \
          Company in analysing how the Service is used.',
          '"Usage Data" refers to data collected automatically, either generated by the use of the Service or fro\
           the Service infrastructure itself (for example, the duration of a page visit).',
          '“You” or “User” means the individual accessing or using the Service, or the company, or other legal\
            entity on behalf of which such individual is accessing or using the Service, as applicable.',
        ],
        listGap: true,
      },
    ],
  },
  {
    title: undefined,
    content: [
      {
        article:
          'HIgedi and Wildcard OY values the privacy of individuals who use our Service (as defined in our Terms and Conditions). \
          This privacy policy (the “Privacy Policy”) explains how We collect, use, and share information from Users of the Service \
          or their Devices. By using or accessing the Service, You agree to the collection, use, disclosure, sharing, and procedures\
           this Privacy Policy describes. Beyond the Privacy Policy, Your use of the Service is also subject to our Terms and \
           Conditions.',
      },
    ],
  },
  {
    title: undefined,
    content: [
      {
        article:
          '<span style="font-weight: 600;">BY CLICKING “I ACCEPT,” OR BY DOWNLOADING, INSTALLING, OR OTHERWISE ACCESSING OR USING THE SERVICE,\
          YOU AGREE THAT YOU HAVE READ AND UNDERSTOOD, AND, AS A CONDITION TO YOUR USE OF THE SERVICE, YOU AGREE TO \
          BE BOUND BY, THE FOLLOWING TERMS AND CONDITIONS, INCLUDING HIGEDI’S PRIVACY POLICY (TOGETHER, THESE “TERMS”). \
          IF YOU ARE NOT ELIGIBLE, OR DO NOT AGREE TO THE TERMS, THEN YOU DO NOT HAVE OUR PERMISSION TO USE THE SERVICE. \
          YOUR USE OF THE SERVICE, AND OUR PROVISION OF THE SERVICE TO YOU, CONSTITUTES AN AGREEMENT BY HIGEDI AND BY \
          YOU TO BE BOUND BY THESE TERMS.</span>',
      },
    ],
  },
  {
    title: undefined,
    content: [
      {
        article:
          'When You’re using our HIgedi mobile App and online platform, there is some information We collect about\
           You, and You can share Your information on the App with other Users globally. The Policy is intended \
           to meet our duties of transparency under the “General Data Protection Regulation” or “GDPR” and other \
           applicable data protection laws.',
      },
    ],
  },
  {
    title: 'Registration Information',
    content: [
      {
        article:
          "Access to HIgedi is granted through various means of Your choice: Facebook, Google or Apple account. </br></br>\
          If you connect through Facebook, We will ask for the following access to information to be granted from your Facebook profile (which you can decline) (“Registration Information”) including name, age, gender, birthday, hometown, photographs and/or e-mail address.  </br></br>\
          If You connect through Apple or Google, We will collect information about You from Apple or Google (as applicable), including an e-mail address, full name, and a unique ID (which allows Apple or Google to identify you as a legitimate user of the account).  </br></br>\
          In addition, if You permit Us to do so, the App may access your Device's geolocalisation, address book and contact lists in order to offer You certain features of our Services, and this data forms part of your Registration Information.",
      },
    ],
  },
  {
    title: 'Log and Usage Data',
    content: [
      {
        article:
          "Usage Data is collected automatically when using the Service. Usage Data may include information such as your Device's Internet Protocol address (e.g. IP address), browser type, browser version, the pages of our Service that You visit, the time and date of Your visit, the time spent on those pages, unique Device identifiers and other diagnostic data. </br></br>\
          When You access the Service by or through a mobile Device, We may collect certain information automatically, including, but not limited to, the type of mobile Device You use, Your mobile device unique ID, the IP address of Your mobile Device, Your mobile operating system, the type of mobile Internet browser You use, unique Device identifiers and other diagnostic data. </br></br>\
          We may also collect information that Your browser sends whenever You visit our Service or when You access the Service by or through a mobile Device.",
      },
    ],
  },
  {
    title: 'Use of Your Personal Data',
    content: [
      {
        article: 'The Company may use Personal Data for the following purposes:',
        ulList: [
          'To provide and maintain our Service, including to monitor the usage of our Service.',
          'To manage Your Account: to manage Your registration as a User of the Service. The Personal Data You provide can give You access to different functionalities of the Service that are available to You as a registered User.',
          'For the performance of a contract: the development, compliance and undertaking of the purchase contract for the products, items or services You have purchased or of any other contract with Us through the Service.',
          "To contact You: To contact You by e-mail, telephone calls, SMS, or other equivalent forms of electronic communication, such as a mobile Application's push notifications regarding updates or informative communications related to the functionalities, products or contracted services, including the security updates, when necessary or reasonable for their implementation.",
          'To provide You with news, special offers and general information about other goods, services and events which We offer that are similar to those that You have already purchased or enquired about unless You have opted not to receive such information.',
          'To manage Your requests: To attend and manage Your requests to Us.',
          'For business transfers: We may use Your information to evaluate or conduct a merger, divestiture, restructuring, reorganization, dissolution, or other sale or transfer of some or all of Our assets, whether as a going concern or as part of bankruptcy, liquidation, or similar proceeding, in which Personal Data held by Us about our Service Users is among the assets transferred.',
          'For other purposes: We may use Your information for other purposes, such as data analysis, identifying usage trends, determining the effectiveness of Our promotional campaigns and to evaluate and improve our Service, products, services, marketing and your experience.',
        ],
        listGap: true,
      },
    ],
  },
  {
    title: 'We may share Your personal information in the following situations:',
    content: [
      {
        article: undefined,
        ulList: [
          'With Service Providers: We may share Your personal information with Service Providers to monitor and analyze the use of our Service, for payment processing, to contact You.',
          'With Analytics Partners: We use analytics services such as Google Analytics to collect and process certain analytics data. These services may also collect information about Your use of other websites, apps, and online resources. You can learn about Google’s practices by going to <a target="_blank" style="word-break: break-all; color: #0266E8" href="https://www.google.com/policies/privacy/partners/">https://www.google.com/policies/privacy/partners/</a>, and opt-out of them by downloading the Google Analytics opt-out browser add-on, available at <a target="_blank" style="color: #0266E8"   href="https://tools.google.com/dlpage/gaoptout">https://tools.google.com/dlpage/gaoptout</a>.',
          'For business transfers: We may share or transfer Your personal information in connection with, or during negotiations of, any merger, sale of Company assets, financing, or acquisition of all or a portion of Our business to another company.',
          'With Affiliates: We may share Your information with Our affiliates, in which case We will require those affiliates to honor this Privacy Policy. Affiliates include Our parent company and any other subsidiaries, joint venture partners or other companies that We control or that are under common control with Us.',
          'With business partners: We may share Your information with Our business partners to offer You certain products, services or promotions.',
          'With other Users: when You share personal information or otherwise interact in the public areas with other Users, such information may be viewed by all Users and may be publicly distributed outside.',
          'As Required by Law and Similar Disclosures: We may access, preserve, and disclose information about You if We believe doing so is required or appropriate to: (a) comply with law enforcement requests and legal process, such as a court order or subpoena; (b) comply with requests from auditors, examiners, or other regulators; (c) respond to Your requests; or (d) protect Your, Our, or others’ rights, property, or safety.',
          'At Your Direction: We may disclose information about You to fulfill the purpose for which You provide it.',
          'With Your consent: We may disclose Your personal information for any other purpose with Your consent.',
        ],
        listGap: true,
      },
    ],
  },
  {
    title: 'No Special Categories of Personal Data:',
    content: [
      {
        article:
          'We do not collect any “Special Categories of Personal Data” about You (this includes details about \
            Your race or ethnicity, religious or philosophical beliefs, sex life, sexual orientation, political \
            opinions, trade union membership, information about health and genetic and biometric data). Nor do We \
            collect any information about criminal convictions and offenses. However, You have the option of providing \
            such information in Your profile or in any information You publish on our App, for instance by sharing \
            Your philosophical beliefs with other Users. Please do not submit any Special Categories of Personal \
            Data if You are not happy for such information to be shared publicly on Your HIgedi Account.',
      },
    ],
  },
  {
    title: 'Information Collected while Using the Application:',
    content: [
      {
        article: undefined,
        ulList: [
          'Device Information: We receive information about the Device You use to access the Service, including, internet protocol (“IP”) address, web browser type, operating system version, and information about Your Device.',
          'Usage Information: to help Us understand how You use the Service and to help Us improve them, when You use the Service, We automatically receive information about Your interactions with the Service, including information about Your use of the App, such as time spent using the App, how You interact with elements of the App, the products You scan or save, and lists You create.',
          "Geolocation Information: upon downloading the App, You will be asked to turn on location services. If You turn these features on, We may collect your Device's geolocation data and save your Device's coordinates to offer certain features (such as, for instance, finding Users who live nearby). We may also use your Device's geolocation information to personalise the App and make it easier for You to interact with other Users close by. You can control Your location information settings in your Account settings and switch them off if You want to. Even if You have disabled location services, We may still determine your city, state, and country location based on Your IP address (but not Your exact location).",
          'Camera Access: when You use the Service, We will request access to your Device’s camera to enable You to take a photo or use other features of our Service.',
          'Information from Cookies and Similar Technologies: We and third-party partners collect information using Cookies, pixel tags, or similar technologies. Our third-party partners, such as analytics and advertising partners, may use these technologies to collect information about Your online activities over time and across different services. Cookies are small text files containing a string of alphanumeric characters. We may use both session Cookies and persistent Cookies. A session cookie disappears after You close Your browser. A persistent cookie remains after You close Your browser and may be used by Your browser on subsequent visits to the Service.',
        ],
        listGap: true,
      },
    ],
  },
  {
    title: undefined,
    content: [
      {
        article:
          'Disabling Cookies: You can typically remove or reject Cookies via Your browser settings. \
          In order to do this, follow the instructions provided by Your browser (usually located within \
            the “settings”, “help” “tools” or “edit” facility). Many browsers are set to accept Cookies until \
            You change Your settings. If You do not accept our Cookies, You may experience some inconvenience \
            in Your use of our App. For example, We may not be able to recognize Your computer or mobile Device \
            and You may need to log in every time You visit our App.',
        ulList: [
          'User feedback: We receive information You provide if You share feedback about User behaviour, posts, and Our performance.',
        ],
        listGap: true,
      },
    ],
  },
  {
    title: 'Retention of Your Personal Data',
    content: [
      {
        article:
          'The Company will retain Your Personal Data only for as long as is necessary for the purposes \
          set out in this Privacy Policy. We will retain and use Your Personal Data to the extent necessary \
          to comply with Our legal obligations (for example, if We are required to retain Your data to comply \
            with applicable laws), resolve disputes, and enforce Our legal agreements and policies.</br></br>\
          The Company will also retain Usage Data for internal analysis purposes. Usage Data is generally retained \
          for a shorter period of time, except when this data is used to strengthen the security or to improve \
          the functionality of our Service, or We are legally obligated to retain this data for longer time periods.',
      },
    ],
  },
  {
    title: 'Transfer of Your Personal Data',
    content: [
      {
        article:
          "Your information, including Personal Data, is processed at the Company's operating offices and in any other places where the parties involved in the processing are located. It means that this information may be transferred to — and maintained on — computers located outside of Your state, province, country or other governmental jurisdiction where the data protection laws may differ than those from Your jurisdiction.</br></br>\
          Your consent to this Privacy Policy followed by Your submission of such information represents Your agreement to that transfer.</br></br>\
          The Company will take all steps reasonably necessary to ensure that Your data is treated securely and in accordance with this Privacy Policy and no transfer of Your Personal Data will take place to an organization or a country unless there are adequate controls in place including the security of Your data and other personal information.",
      },
    ],
  },
  {
    title: 'Disclosure of Your Personal Data',
    content: [
      {
        article:
          'Business Transactions </br>\
          If the Company is involved in a merger, acquisition or asset sale, Your Personal Data may be transferred. We will provide notice before Your Personal Data is transferred and becomes subject to a different Privacy Policy.</br></br>\
          Law enforcement</br>\
          Under certain circumstances, the Company may be required to disclose Your Personal Data if required to do so by law or in response to valid requests by public authorities (e.g. a court or a government agency).</br></br>\
          Other legal requirements</br>\
          The Company may disclose Your Personal Data in the good faith belief that such action is necessary to:',
        ulList: [
          'Comply with a legal obligation',
          'Protect and defend the rights or property of the Company',
          'Prevent or investigate possible wrongdoing in connection with the Service',
          'Protect the personal safety of Users of the Service or the public',
          'Protect against legal liability',
        ],
        listGap: false,
      },
    ],
  },
  {
    title: 'Security of Your Personal Data',
    content: [
      {
        article:
          'The security of Your Personal Data is important to Us, but remember that no method of transmission over \
          the Internet, or method of electronic storage is 100% secure. While We strive to use commercially acceptable \
          means to protect Your Personal Data, We cannot guarantee its absolute security.',
      },
    ],
  },

  {
    title: 'Detailed Information on the Processing of Your Personal Data',
    content: [
      {
        article:
          '<b>The Service Providers</b></br>\
        We use may have access to Your Personal Data. These third-party vendors collect, store, use, process and transfer information about Your activity on Our Service in accordance with their Privacy Policies.</br></br>\
        <b>Analytics </b> </br>\
        We may use third-party service providers to monitor and analyze the use of our Service.</br></br>\
       <b>E-mail Marketing</b> </br> \
        We may use Your Personal Data to contact You with newsletters, marketing or promotional materials and other information that may be of interest to You. You may opt-out of receiving any, or all, of these communications from Us by following the unsubscribe link or instructions provided in any e-mail We send or by contacting Us at: <a style="color: #0266E8"   href="mailto:hi@higedi.com">hi@higedi.com</a>.',
      },
    ],
  },
  {
    title: 'Your Choices',
    content: [
      {
        article:
          '<b>Marketing Communications </b></br>\
          You can unsubscribe from Our promotional e-mails via the link provided in the e-mails. Even if You opt out of receiving promotional messages from Us, You will continue to receive administrative messages.</br></br>\
          <b>Do Not Track </b> </br>\
          There is no accepted standard on how to respond to Do Not Track signals, and We do not respond to such signals.</br></br>\
       <b>Deletion of Your information</b> </br> \
       You have the right to ask Us to delete personal information about You if it no longer is required for the purpose it was collected, You have withdrawn Your consent, or You have a valid objection to Us using Your personal information. You may request deletion of Your personal information by contacting Us at: <a style="color: #0266E8"   href="mailto:hi@higedi.com">hi@higedi.com</a>.',
      },
    ],
  },
  {
    title: 'Children’s Privacy',
    content: [
      {
        article:
          'We do not knowingly collect, maintain, or use personal information from children under \
          13 years of age, and no part of the Service is directed to children. If You learn that a \
          child has provided Us with personal information in violation of this Privacy Policy, then \
          You may alert Us at <a style="color: #0266E8"   href="mailto:hi@higedi.com">hi@higedi.com</a>.',
      },
    ],
  },
  {
    title: 'Removing HIgedi access',
    content: [
      {
        article:
          'If You want to remove HIgedi from your Facebook, Apple, or Google account at any time You can do this by visiting the Application settings on your Facebook, Apple, or Google profile (as applicable) and following the instructions to remove HIgedi access permissions.</br></br>\
          Even after You remove information from Your profile or delete your Account, copies of that information may still be viewable and/or accessed to the extent such information has been previously shared with others, made public by You during Your use of the App, or copied or stored by other Users or to the extent such information has been shared with search engines. We cannot control this, nor do We accept any liability for this. The App may also include links to third-party websites, plug-ins, and applications. Clicking on those links or enabling those connections may allow third parties to collect or share Your personal data. We do not control these third-party websites and are not responsible for their privacy statements. If You have given third-party applications or websites access to Your personal data, they may retain such information to the extent permitted under their terms of service or privacy policies.',
      },
    ],
  },
  {
    title: 'Links to Other Websites',
    content: [
      {
        article:
          "Our Service may contain links to other websites, products, or services that We do not own or operate. If You click on a third-party link, You will be directed to that third-party's site. For example, We may present You with links to third-party websites where You may purchase certain goods.</br></br>\
          Please be aware that this Privacy Policy does not apply to Your communications with third parties or Your activities on any third-party services or any information You disclose to third parties. We are not responsible for the privacy practices or the content of third parties or third-party sites. If You have any questions about how third parties or third-party sites use information from or about You, You should contact them directly. We encourage You to read their privacy policies before providing any information to them.",
      },
    ],
  },
  {
    title: 'Update Information',
    content: [
      {
        article:
          'You can update information, your Account and profile information by logging in to your Account on the Service.',
      },
    ],
  },
  {
    title: 'Changes to this Privacy Policy',
    content: [
      {
        article:
          'We may update Our Privacy Policy from time to time.</br></br>\
          When We change Our Privacy Policy, We may, at Our discretion, place a notice on HIgedi website, send You an e-mail, or notify You by some other means, including as required by applicable law. We may also update the “Last Updated” date at the top of Privacy Policy. Your continued access to or use of HIgedi after any changes to Privacy Policy indicates Your acceptance of such changes.</br></br>\
          You are advised to review this Privacy Policy periodically for any changes. Changes to this Privacy Policy are effective when they are posted on this page.</br></br>\
          If You have any questions about this Privacy Policy, do not hesitate to contact Us by sending Us an e-mail at: <a style="color: #0266E8"   href="mailto:hi@higedi.com">hi@higedi.com</a>.',
      },
    ],
  },
  {
    title: 'Complaints',
    content: [
      {
        article:
          'If You would like to make a complaint regarding this Policy Policy or Our practices in relation to Your personal data, please contact Us at: <a style="color: #0266E8"   href="mailto:hi@higedi.com">hi@higedi.com</a>\
          We will reply to Your complaint as soon as We can.',
      },
    ],
  },
];
