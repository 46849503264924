import { Skeleton } from '@mui/material';
import { FC, ImgHTMLAttributes, useState } from 'react';

const ImageWithLoading: FC<ImgHTMLAttributes<HTMLImageElement>> = ({
  src,
  alt,
  width = 75,
  height = 75,
  style,
  ...props
}) => {
  const [imgLoaded, setImgLoaded] = useState(false);
  const [imgError, setImgError] = useState(false);

  const handleImgLoad = () => {
    setImgLoaded(true);
  };

  const handleImgError = () => {
    setImgLoaded(true);
    setImgError(true);
  };

  return (
    <div
      style={{
        display: 'flex',
        justifyContent: 'center',
      }}
    >
      {src ? (
        <>
          {!imgLoaded && <Skeleton variant="rounded" width={width} height={height} style={style} />}
          {!imgError ? (
            <img
              src={src}
              width={width}
              height={height}
              alt={alt}
              onLoad={handleImgLoad}
              onError={handleImgError}
              style={{ display: imgLoaded ? 'block' : 'none', ...(style || {}) }}
              {...props}
            />
          ) : (
            <div
              style={{
                width,
                height,
                ...(style || {}),
              }}
              {...props}
            ></div>
          )}
        </>
      ) : null}
    </div>
  );
};

export default ImageWithLoading;
