export interface Column {
  id: string;
  label: string;
  minWidth?: number;
  align?: 'right';
  format?: (value: number) => string;
}

export const Columns = (
  column1: string,
  column2: string,
  column3: string,
  column4: string,
  column5: string,
  label1: string,
  label2: string,
  label3: string,
  label4: string,
  label5: string,
): Column[] => {
  return [
    { id: column1, label: label1, minWidth: 100 },
    { id: column2, label: label2, minWidth: 100 },
    {
      id: column3,
      label: label3,
      minWidth: 200,
      align: 'right',
      format: (value: number) => value.toLocaleString('en-US'),
    },
    {
      id: column4,
      label: label4,
      minWidth: 200,
      align: 'right',
      format: (value: number) => value.toLocaleString('en-US'),
    },
    {
      id: column5,
      label: label5,
      minWidth: 50,
      align: 'right',
      format: (value: number) => `${value}`,
    },
    {
      id: 'actions',
      label: 'Actions',
      minWidth: 100,
      align: 'right',
    },
  ];
};
