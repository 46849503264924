import React, { Dispatch, SetStateAction, useState } from 'react';
import Paper from '@mui/material/Paper';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TablePagination from '@mui/material/TablePagination';
import TableRow from '@mui/material/TableRow';
import {
  Box,
  Button,
  CircularProgress,
  IconButton,
  Popover,
  TextField,
  Typography,
} from '@mui/material';
import DeleteIcon from '@mui/icons-material/Delete';
import FavoriteIcon from '@mui/icons-material/Favorite';
import EditRoundedIcon from '@mui/icons-material/EditRounded';
import RemoveCircleIcon from '@mui/icons-material/RemoveCircle';
import CommentRoundedIcon from '@mui/icons-material/CommentRounded';

import { IPost, IUser } from '@src/types';
import { createUser, deleteUser, getUsers, unbanUser } from '@src/services/users/api';
import { deletePost, updatePostLikes } from '@src/services/posts/api';

import { Column } from './Columns';
import { Post } from '../Modals/Post';
import { Comment } from '../Modals/Comment';
import { User } from '../Modals/User';
import { RemoveModal } from '../Modals/Remove';
import { toastPromise } from '../Toast';
import ImageWithLoading from '../ImageWithLoading';

interface Data {
  type: string;
  showModal: boolean;
  setShowModal: Dispatch<SetStateAction<boolean>>;
  page: number;
  setPage: Dispatch<SetStateAction<number>>;
  limit: number;
  setLimit: Dispatch<SetStateAction<number>>;
  count: number;
  columns: Column[];
  rows: IUser[] | IPost[];
  users?: IUser[] | [];
  setRows: Dispatch<SetStateAction<any[]>>;
  error: boolean;
  isLoading: boolean;
}

export const TableComponent: React.FC<Data> = ({
  type,
  showModal,
  setShowModal,
  page,
  setPage,
  limit,
  setLimit,
  count,
  columns,
  rows,
  setRows,
  error,
  isLoading,
}: Data) => {
  const [, setAction] = useState<string>('');
  const [postId, setPostId] = useState<string>('');
  const [userId, setUserId] = useState<string>('');
  const [showCommentModal, setShowCommentModal] = useState<boolean>(false);
  const [showUserModal, setShowUserModal] = useState<boolean>(false);
  const [showRemoveUserModal, setShowRemoveUserModal] = useState<boolean>(false);
  const [userToRemove, setUserToRemove] = useState<IUser | null>(null);
  const [showUnblockUserModal, setShowUnblockUserModal] = useState<boolean>(false);
  const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | null>(null);
  const [columnLikes, setColumnLikes] = useState<number | string>(0);

  const handleOpenLikePopover = (event: React.MouseEvent<HTMLButtonElement>, likes: number) => {
    setAnchorEl(event.currentTarget);
    setColumnLikes(likes);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleUpdatePostLike = async () => {
    const likes = columnLikes;
    const response = await updatePostLikes(page, limit, +postId, +likes);
    setRows(response.data.body.rows);
    handleClose();
  };

  const handleUnblockUser = async () => {
    await toastPromise<Awaited<ReturnType<typeof unbanUser>>>(unbanUser(+userId), {
      loading: 'Unblocking user...',
      success: 'User unblocked successfully',
      error: 'Something went wrong, please try again',
    });
    setRows(prev => prev.filter((i: IUser) => i.id !== +userId));
    setShowUnblockUserModal(false);
  };

  const open = Boolean(anchorEl);
  const id = open ? 'simple-popover' : undefined;

  const handleCreate = async () => {
    switch (type) {
      case 'user': {
        const data = await toastPromise<Awaited<ReturnType<typeof createUser>>>(createUser(), {
          loading: 'Creating user...',
          success: 'User created successfully',
          error: 'Something went wrong, please try again',
        });
        if (!data?.data?.body?.user) {
          return;
        }
        setRows([...rows, data.data.body.user]);
        break;
      }
      case 'post': {
        setShowModal(!showModal);
        setAction('post');
        break;
      }
      default:
        break;
    }
  };

  const handleDeleteUser = async (id: number) => {
    await toastPromise<Awaited<ReturnType<typeof deleteUser>>>(deleteUser(id), {
      loading: 'Deleting user...',
      success: 'User deleted successfully',
      error: 'Something went wrong, please try again',
    });
    const list_of_users = rows as IUser[];
    const new_rows = list_of_users?.filter((i: IUser) => i.id !== id);
    setRows(new_rows);
  };

  const handleDeletePost = async (id: number) => {
    await toastPromise<Awaited<ReturnType<typeof deletePost>>>(deletePost(id), {
      loading: 'Deleting post...',
      success: 'Post deleted successfully',
      error: 'Something went wrong, please try again',
    });
    const list_of_users = rows as IUser[];
    const new_rows = list_of_users?.filter((i: IUser) => i.id !== id);
    setRows(new_rows);
  };

  const handleChangePage = async (event: unknown, newPage: number) => {
    setPage(newPage);
    const {
      data: { body },
    } = await getUsers(newPage, limit);

    setRows(body.rows);
  };

  const handleChangeRowsPerPage = (event: React.ChangeEvent<HTMLInputElement>) => {
    setLimit(+event.target.value);
    setPage(0);
  };

  return (
    <Paper sx={{ width: '100%', overflow: 'hidden' }}>
      {type !== 'banned_user' ? (
        <Button onClick={handleCreate} variant="outlined">
          {type === 'post' ? 'Create Post' : ''}
          {type === 'user' ? 'Create User' : ''}
        </Button>
      ) : null}
      <TableContainer sx={{ width: '100%', maxHeight: '100%' }}>
        <Table stickyHeader aria-label="sticky table">
          <TableHead>
            <TableRow>
              {columns.map((column: Column) => (
                <TableCell key={column.id} align={column.align} style={{ textAlign: 'start' }}>
                  {column.label}
                </TableCell>
              ))}
            </TableRow>
          </TableHead>
          <TableBody>
            {rows.map(row => {
              return (
                <TableRow hover role="checkbox" tabIndex={-1} key={row.id}>
                  {columns.map((column: Column) => {
                    const value = (row as any)[column.id];

                    if (typeof value === 'string' && value.startsWith('https://')) {
                      return (
                        <TableCell key={column.id}>
                          <ImageWithLoading src={value} alt={'image'} width={75} height={75} />
                        </TableCell>
                      );
                    }

                    if (column.id === 'actions') {
                      return (
                        <TableCell
                          align="center"
                          style={{
                            textAlign: 'end',
                          }}
                          key={column.id}
                        >
                          <Box sx={{ display: 'inline-flex' }}>
                            {type === 'user' && (
                              <IconButton
                                data-userid={row.id}
                                onClick={event => {
                                  setShowUserModal(!showUserModal);
                                  setUserId(event.currentTarget.dataset.userid || '');
                                }}
                                color={'success'}
                              >
                                <EditRoundedIcon />
                              </IconButton>
                            )}
                            {type === 'post' && 'text' in row ? (
                              <Box display={'flex'}>
                                <IconButton
                                  data-postid={row.id}
                                  onClick={() => {
                                    setShowCommentModal(!showCommentModal);
                                    setAction('comment');
                                    setPostId(`${row.id}`);
                                  }}
                                  color={'info'}
                                >
                                  <CommentRoundedIcon />
                                </IconButton>
                                <Box>
                                  <IconButton
                                    data-postid={row.id}
                                    color={'primary'}
                                    onClick={event => {
                                      handleOpenLikePopover(event, row.likes || 0);
                                      setPostId(`${row.id}`);
                                    }}
                                  >
                                    <FavoriteIcon />
                                  </IconButton>
                                  <Popover
                                    id={id}
                                    open={open}
                                    anchorEl={anchorEl}
                                    onClose={handleClose}
                                    sx={{ boxShadow: 1 }}
                                    anchorOrigin={{
                                      vertical: 'bottom',
                                      horizontal: 'left',
                                    }}
                                  >
                                    <Box
                                      display={'flex'}
                                      flexDirection={'column'}
                                      alignItems={'center'}
                                      padding={2}
                                      gap={1}
                                    >
                                      <Typography fontWeight={700}>Number of likes</Typography>
                                      <TextField
                                        id="contact phone number"
                                        label={'Likes'}
                                        type="number"
                                        value={columnLikes}
                                        onFocus={() => columnLikes === 0 && setColumnLikes('')}
                                        onChange={event => {
                                          event.preventDefault();
                                          +event.target.value === 0
                                            ? setColumnLikes('')
                                            : setColumnLikes(event.target.value);
                                        }}
                                        margin="normal"
                                      />
                                      <Box display={'flex'} gap={1} width={'100%'}>
                                        <Button
                                          sx={{ flexBasis: '50%' }}
                                          variant="contained"
                                          onClick={() => {
                                            handleUpdatePostLike();
                                          }}
                                          disabled={!columnLikes}
                                        >
                                          Save
                                        </Button>
                                        <Button
                                          color="error"
                                          sx={{ flexBasis: '50%' }}
                                          variant="contained"
                                          onClick={() => handleClose()}
                                        >
                                          Cancel
                                        </Button>
                                      </Box>
                                    </Box>
                                  </Popover>
                                </Box>

                                <IconButton
                                  data-postid={row.id}
                                  color={'error'}
                                  onClick={event => {
                                    if (event.currentTarget.dataset.postid) {
                                      handleDeletePost(+event.currentTarget.dataset.postid);
                                    }

                                    setAction('post');
                                  }}
                                >
                                  <DeleteIcon />
                                </IconButton>
                              </Box>
                            ) : type !== 'banned_user' && 'firstName' in row ? (
                              <IconButton
                                data-userid={row.id}
                                color={'secondary'}
                                onClick={event => {
                                  setShowRemoveUserModal(prev => !prev);
                                  setAction('user');
                                  setUserToRemove(row);
                                }}
                              >
                                <DeleteIcon />
                              </IconButton>
                            ) : null}
                            {type === 'banned_user' && (
                              <IconButton
                                data-userid={row.id}
                                onClick={event => {
                                  setShowUnblockUserModal(prev => !prev);
                                  setUserId(event.currentTarget.dataset.userid || '');
                                }}
                                color={'secondary'}
                                title="Unblock user"
                              >
                                <RemoveCircleIcon />
                              </IconButton>
                            )}
                          </Box>
                        </TableCell>
                      );
                    }

                    return (
                      <TableCell
                        key={column.id}
                        align={column.align}
                        sx={{ width: '300px', textAlign: 'start' }}
                      >
                        {column.format && typeof value === 'number' ? (
                          column.format(value)
                        ) : (
                          <Box maxWidth={'300px'} sx={{ wordWrap: 'break-word' }}>
                            {value}
                          </Box>
                        )}
                      </TableCell>
                    );
                  })}
                </TableRow>
              );
            })}
          </TableBody>
        </Table>
        {isLoading && (
          <Box my={3} width="100%" display="flex" justifyContent="center">
            <CircularProgress />
          </Box>
        )}
        {error && (
          <Typography my={3} textAlign="center">
            Something went wrong, please try again.
          </Typography>
        )}
        {rows?.length === 0 && !isLoading && (
          <Typography my={3} textAlign="center">
            No results.
          </Typography>
        )}
      </TableContainer>
      {showModal && type === 'post' && (
        <Post
          showModal={showModal}
          setShowModal={setShowModal}
          setRows={setRows}
          rows={rows}
          count={count}
        />
      )}
      {showUserModal && type === 'user' && (
        <User
          showUserModal={showUserModal}
          setShowUserModal={setShowUserModal}
          setRows={setRows}
          rows={rows as IUser[]}
          userId={userId}
        />
      )}
      <RemoveModal
        showRemoveModal={showUnblockUserModal}
        setShowRemoveModal={setShowUnblockUserModal}
        handleDelete={handleUnblockUser}
        isLoading={isLoading}
        content={'Do you want to unblock this user?'}
        title={'Unblocking User'}
        approveBtnText="Unblock"
      />
      <RemoveModal
        showRemoveModal={showRemoveUserModal}
        setShowRemoveModal={setShowRemoveUserModal}
        handleDelete={() => {
          if (userToRemove) {
            handleDeleteUser(userToRemove?.id);
            setUserToRemove(null);
          }
          setShowRemoveUserModal(false);
        }}
        isLoading={isLoading}
        content={`Do you want to delete user ${userToRemove?.email}?`}
        title={'Deleting User'}
        approveBtnText="Delete"
      />
      {showCommentModal && type === 'post' && (
        <Comment
          showCommentModal={showCommentModal}
          setShowCommentModal={setShowCommentModal}
          rows={rows as IPost[]}
          setRows={setRows}
          count={count}
          postId={postId}
        />
      )}
      <TablePagination
        rowsPerPageOptions={[10, 20, 50]}
        component="div"
        count={count}
        rowsPerPage={limit}
        page={page}
        onPageChange={handleChangePage}
        onRowsPerPageChange={handleChangeRowsPerPage}
        showLastButton
        showFirstButton
      />
    </Paper>
  );
};
