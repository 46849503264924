import React from 'react';
import { Navigate } from 'react-router-dom';

import { getAccessToken } from '@src/utils/auth';

interface Props {
  component: React.ReactElement | null;
}

export const PrivateRoute = ({ component: RouteComponent }: Props): React.ReactElement | null => {
  const storedAccessToken = getAccessToken();

  if (storedAccessToken) {
    return RouteComponent;
  }

  return <Navigate to="/signin" />;
};
