export const helpContent = [
  {
    title:
      'Account registration. I’m asked about my gestational diabetes experience – what should I choose?',
    content: [
      {
        article:
          'When you create your account, you’ll be first asked to tell us about your gestational diabetes experience:',
        numberList: [
          'Choose “Newcomer” if you’re currently pregnant with gestational diabetes and this is  your first pregnancy with this diagnosis;',
          'Choose “Expert” if you’re currently not pregnant, but have had a pregnancy with gestational diabetes in the past OR if you’re currently pregnant with or without gestational diabetes and have had a pregnancy with gestational diabetes in the past;',
          'Choose “Mentor” if you’ve had two or more pregnancies with gestational diabetes in\
            the past;',
          'Choose “Healthcare professional” if you’re a nutritionist or a member of the\
            medical, pharmaceutical industry or nursing professions.',
        ],
        listGap: true,
      },
    ],
  },
  {
    title: undefined,
    content: [
      {
        article:
          'There’s no possibility to choose multiple experience levels (for example both a "Newcomer" and\
          a "Healthcare professional") so choose the one that describes you more as a user.',
      },
    ],
  },
  {
    title:
      'What is the difference between the experience levels of a Newcomer, an Expert, and a Mentor?',
    content: [
      {
        article: undefined,
        numberList: [
          'A “Newcomer” is a user who is currently pregnant with gestational diabetes and it is\
            her first pregnancy with this diagnosis;',
          'An “Expert” is a user who isn’t currently pregnant, but has had one pregnancy with\
            gestational diabetes in the past OR is currently pregnant with or without gestational\
            diabetes, and has had a pregnancy with gestational diabetes in the past;',
          'A “Mentor” is a user who has had two or more pregnancies with gestational diabetes\
            in the past;',
        ],
        listGap: true,
      },
    ],
  },
  {
    title: 'Can I choose 2 experience levels?',
    content: [
      {
        article:
          'There’s no possibility to choose multiple experience levels (for example both a "Newcomer" and\
        a "Healthcare professional") so choose the one that describes you more as a user.',
      },
    ],
  },
  {
    title: 'How to change my gestational diabetes experience status?',
    content: [
      {
        article:
          'You can edit your gestational experience level on your profile by following these steps:',
        ulList: [
          'Open HIgedi',
          'Select the profile image icon at the top left corner',
          'Select Edit Profile',
          'Find the Experience level and edit it by clicking on the dropdown menu',
          'Scroll down and press the Save button',
        ],
        listGap: true,
      },
    ],
  },
  {
    title: 'How to edit my profile?',
    content: [
      {
        article: 'You can edit your profile by following these steps:',
        ulList: [
          'Select Edit Profile if you wish to change your Image, Name, Experience level, Due\
        Date, Diagnosed On Date or Bio, then scroll down and press the Save button',
          'Press the Edit button if you wish to change the Me In 3 Emojis, then click on the emojis\
        that best describe you (you are able to choose up to three emojis); when you’re done with\
        your selection press Save at the top right corner',
          'Press the Edit button if you wish to change My Keywords, then click on the keywords\
        that best describe you (selected keywords appear at the top and you’re able to remove\
        them from your selection by clicking on the little red X button at the end of the keyword\
        itself); when you’re done with your selection just go back to your main profile page by\
        clicking the Back button at the top left corner',
          'Press the Edit button if you wish to change My Location, then search for a location by\
        typing it in; when you’re done with your selection press Save at the top right corner',
        ],
        listGap: true,
      },
    ],
  },
  {
    title: 'What are My Connections?',
    content: [
      {
        article: 'My Connections are the users you are following and who are following you.',
      },
    ],
  },
  {
    title: 'What is the difference between a "Global Feed" vs "My Feed"?',
    content: [
      {
        article:
          '"Global Feed" gathers posts from all HIgedi users while "My Feed" only shows posts from yourself\
        and the users you follow as well as the posts you have interacted with. To toggle between the feeds\
        simply swipe right or left.',
      },
    ],
  },
  {
    title: 'How to report a post?',
    content: [
      {
        article:
          'If you’re concerned about a specific post, please report it by tapping the three dots in the top righthand corner and select “Report this post”. We’ll investigate it.',
      },
    ],
  },
  {
    title: 'How to report a user?',
    content: [
      {
        article:
          'Firstly, we’re sorry to hear that there’s a user you wish to report. Should you wish to do so, please\
          report the user by:',
        ulList: [
          'Tapping the three dots in the top right-hand corner of their post. Select “Report this\
        user”. We’ll investigate it.\
        OR',
          'Opening the user’s profile. Tap the three dots in the top right-hand corner\
        and select “Report this user”. We’ll investigate it.',
        ],
        listGap: true,
      },
    ],
  },
  {
    title: 'How do I block a user or remove them from My Connections?',
    content: [
      {
        article:
          'We understand that sometimes you just don’t want to see content or receive messages from some users. </br> </br>Should you wish to, you can block a user by following these steps:',
        ulList: [
          'Open HIgedi',
          'Tap on the user’s name or the profile image you want to block',
          'Tap on the three dots at the top right corner and select “Block this user”',
        ],
        listGap: true,
      },
    ],
  },
  {
    title: 'Can I delete or edit my post?',
    content: [
      {
        article: 'You can delete or edit your post by following these steps:',
        ulList: [
          'Open HIgedi',
          'Swipe left to see My Feed',
          'Tap on the three dots at the top right corner and select “Delete” or “Edit',
        ],
        listGap: true,
      },
    ],
  },
  {
    title: 'How to deactivate my account?',
    content: [
      {
        article: 'You can deactivate your account by following these steps:',
        ulList: [
          'Open HIgedi',
          'Select the profile image icon at the top left corner',
          'Select the Settings icon at the top right corner',
          'Click on “Delete my Account” and follow the instructions',
        ],
        listGap: true,
      },
    ],
  },
  {
    title: 'Can I send a private message to any HIgedi user?',
    content: [
      {
        article:
          'You’ll first need to send a “say HI” request to a user you want to start a conversation with which has\
        to be accepted. Only then will you be able to start a private chat.',
      },
    ],
  },
  {
    title: 'Why is HIgedi asking for my location?',
    content: [
      {
        article:
          'HIgedi is asking for your location so that you and the other users can find each other nearby. This\
          will allow users to search for support close by, make a connection, share local recommendations and\
          why not meet in real life. </br></br> Here’s how you can choose your preferred location access:',
        ulList: [
          'On iOS: head to Settings → HIgedi → Location',
          'On Android: head to Settings → HIgedi → Location permissions',
        ],
        listGap: true,
      },
    ],
  },
  {
    title: 'Is it safe to share my location? How exactly does it appear to other users?',
    content: [
      {
        article:
          'We’ll never share your exact location. Once you allow HIgedi to share your location, we’ll only show\
          others an approximate area you’re located in.',
      },
    ],
  },
  {
    title: 'Who’s saying HI to me?',
    content: [
      {
        article:
          'You can see the users who have sent you a "HI message" in your private Chat tab by tapping on the\
          Requests tab. They won’t be able to start a private chat with you before you have accepted the\
          request.',
      },
    ],
  },
  {
    title: 'How to say HI to other users?',
    content: [
      {
        article: 'Here’s how you can say HI to other users:',
        ulList: [
          "To say HI to a user found on the “Global Feed”, open a user’s profile by clicking on the\
          user's name or the profile image, then tap on the “Say HI” button. You’ll receive a\
          notification once the user has accepted your request. You will be able to start a conversation\
          in the Chat tab afterwards.",
          '<b>OR</b>',
          'Open a map search tab and search for a desired area. To say HI, press on a selected\
          user’s profile image on the map itself and then send a request by tapping the “Say HI”\
          button. You’ll receive a notification once the user has accepted your request. You will be\
          able to start a conversation in the private Chat tab afterwards.',
        ],
        listGap: true,
      },
    ],
  },
  {
    title: 'Can I search other users without sharing my location?',
    content: [
      {
        article: 'HIgedi would need you to allow your location to display users around your area.',
      },
    ],
  },
  {
    title: 'How do map search filters work?',
    content: [
      {
        article:
          'To narrow down the map search with the desired filters simply open a map search tab and click on\
          the filters button on the right top corner:',
        ulList: [
          'To search for users with a specific experience level, tick the desired experience level;',
          'To search for users with a specific interests and/or keywords, click on Add keywords\
          and select the desired keywords (selected keywords appear at the top and you’re able to\
          remove them from your selection by clicking on the little red X button at the end of the\
          keyword); when you’re done with your selection just go back to the filters page by clicking\
          back button at the top left corner;',
          'To change the desired search distance, slide the dot to your preferred distance range;',
          'To save the selected filters click on the Apply button at the bottom of the filters page.',
        ],
        listGap: true,
      },
    ],
  },
  {
    title: 'How to unfollow a user?',
    content: [
      {
        article:
          'Simply open the user’s profile you want to unfollow and press the Unfollow button.',
      },
    ],
  },
  {
    title: 'How do I see and delete my notifications?',
    content: [
      {
        article: 'You can see and delete your notifications by following these steps:',
        ulList: [
          'Open HIgedi',
          'Select the notification icon at the top right corner',
          'Delete notifications by pressing “Delete All” at the top right corner and follow the instructions',
        ],
        listGap: true,
      },
    ],
  },
  {
    title: 'How to choose or remove a category on Marketplace?',
    content: [
      {
        article:
          'To select a specific category simply click on the desired category underneath the search bar. You are\
          able to choose more than one. To see the categories available scroll right-left.</br></br>\
          To remove a category simply click on the selected category you wish to remove.',
      },
    ],
  },
  {
    title: 'I haven’t received a product that I found on the Marketplace?',
    content: [
      {
        article:
          'All products or items on our Marketplace are sold by vendors and not directly by HIgedi. To get\
          feedback regarding your order or any other claim, please get in touch with the vendor directly.',
      },
    ],
  },
  {
    title: 'How can I get in touch with the HIgedi team?',
    content: [
      {
        article:
          'Please feel free to send us an email at <a href="mailto:hi@higedi.com">hi@higedi.com</a> or head to "Settings" (the Settings icon at the\
            top right-hand corner or your profile) and then select “Contact HIgedi”.',
      },
    ],
  },
  {
    title: 'I’ve sent an email but haven’t heard back yet',
    content: [
      {
        article:
          'Firstly, thank you for getting in touch with our HIgedi team. We aim to get back to you within 48\
          hours of receiving your query, however this timeframe can be longer over the weekend, holidays or\
          if we’re experiencing a bigger amount of email demand </br></br>Thank you for your patience.',
      },
    ],
  },
];
