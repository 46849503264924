import * as yup from 'yup';

export const validateManager = yup.object({
  username: yup.string().min(2).required(),
  password: yup.string().min(8).required(),
  email: yup.string().email('Invalid email format').required('Required'),
});

export const validateProduct = yup.object().shape({
  name: yup.string().trim().required('Product name is required.'),
  link: yup.string().required('Product link is required.'),
  price: yup.number().positive().required('Price is required.'),
  description: yup.string().max(1000).trim().required('Description is required.'),
  ingredients: yup.string().max(1000).trim().optional(),
  categories: yup.array().min(1).required('At least one category is required.'),
});

export const validatePost = yup.object({
  keywords: yup.array().min(1).max(5).required(),
  user: yup.object().required(),
  text: yup.string().min(10).max(255).trim().required('Required'),
});

export const validateUser = yup.object({
  firstName: yup.string().min(1).max(100).required(),
  lastName: yup.string().min(1).max(100).required(),
  image: yup.object().required(),
});

export const validateAffiliateNote = yup.object({
  title: yup.string().min(0).max(100),
  text: yup.string().min(0).max(255).trim(),
  description: yup.string().min(0).max(1000).trim(),
});
