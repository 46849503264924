export interface IManager {
  id: number;
  role?: string;
  username: string;
  password: string;
  email: string;
}

export enum UserRoles {
  superAdmin = 'super-admin',
  admin = 'admin',
  user = 'user',
}

export interface Category {
  id: number;
  category: string;
}

export type MarketplaceFilters = { name: string; categories: Category[] };

export interface Product {
  id: number;
  name: string;
  images?: Image[];
  icon?: Image;
  link: string;
  price: number;
  categories: Category[];
  category?: string;
  description: string | null;
  ingredients: string | null;
}

export interface Image {
  id?: number;
  productId?: number;
  base64?: string;
  imgPath?: string;
  imgKey?: string;
  createdAt?: Date;
  updatedAt?: Date;
}
export interface IReports {
  post?: IPost;
  user: IUser;
  reports: IReport[];
}
export interface IPost {
  id: number;
  userId: number;
  text: string;
  imgPath: string | null;
  is_reported: boolean;
  reports?: IReport[];
  likes?: number;
  count_comments?: number;
}

export interface IUser {
  id: number;
  firstName: string | null;
  lastName: string | null;
  avatarPath: string | null;
  avatarKey?: string;
  experience: IUserExperience;
  email: string;
  isBanned: boolean;
}
export interface IReport {
  report_id: number;
  reporter?: IUser;
  status: IReportStatus;
  reason: IReportReason;
  description: string;
  createdAt: string;
}

export enum IUserExperience {
  new_comer = 'Newcomer',
  mentor = 'Mentor',
  expert = 'Expert',
  healthcare_professional = 'Healthcare Professional',
}

enum IReportStatus {
  'pending',
  'canceled',
  'approved',
}

enum IReportReason {
  'Spam',
  'Business',
  'Bullying',
  'Unsafe advice',
  'Other',
}

export interface Actions {
  select: (id: number) => void;
}

export interface IKeyword {
  id: number;
  value: string;
  categoryId: Category['id'];
}

export interface ModalImage {
  src?: string;
  base64?: string;
  type_of_img?: string;
  avatarPath?: string;
  avatarKey?: string;
}

export interface IEmoji {
  id: number;
  codes: string;
  char: string;
  name: string;
  category: string;
  group: string;
  subgroup: string;
}

export interface IText {
  id: number;
  key: 'affiliate';
  title: string;
  text: string;
  description: string;
  createdAt: string;
  updatedAt: string;
}
