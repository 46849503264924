import React from 'react';
import { Stack } from '@mui/material';
import { Outlet } from 'react-router-dom';

import { Breadcrumbs } from '@src/components/Breadcrumbs';

const breadcrumbs = {
  '/marketplace': 'Marketplace',
  '/marketplace/create': 'Create',
  '/marketplace/edit-:slug': 'Edit',
};

const Marketplace: React.FC = () => {
  return (
    <Stack spacing={2}>
      <Breadcrumbs breadcrumbs={breadcrumbs} />

      <Outlet />
    </Stack>
  );
};

export default Marketplace;
export * from './MarketplaceTable';
export * from './MarketplaceEditor';
