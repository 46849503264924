export const termOfUseContent = [
  {
    title: undefined,
    content: [
      {
        article:
          '<b>For the purposes of these Terms and Conditions</b> (the following definitions shall have the same meaning regardless of whether they appear in singular or in plural):',
        ulList: [
          '“Application” or “App” means the software program provided by the Company downloaded by You on any electronic device, named HIgedi.',
          '“Account” means a unique account created for You to access our Service or parts of our Service.',
          '“Company” (referred to as either "the Company", "We", "Us", "Our" or “HIgedi entities” in this Agreement) refers to Wildcard OY.',
          '“Content” refers to content such as text, images, or other information that can be posted, uploaded, linked to or otherwise made available by You, regardless of the form of that content.',
          '“Device” means any device that can access the Service such as a computer, a cell phone or a digital tablet.',
          '“Service” refers to the Website.',
          '“Third-party Service” means any services or content (including data, information, products or services) provided by a third-party that may be displayed, included or made available by the Service.',
          '“Website” refers to HIgedi website, accessible from <a target="_blank" style="color: #0266E8" href="https://higedi.com">www.hidegi.com</a>',
          '“You” or “User” means the individual accessing or using the Service, or the company, or other legal entity on behalf of which such individual is accessing or using the Service, as applicable.',
        ],
        listGap: true,
      },
    ],
  },
  {
    title: undefined,
    content: [
      {
        article:
          'This is a contract between You and HIgedi (Wildcard OY with registered address at Kuklase 2-49, Tallinn, 13424 Estonia) and We want You to be aware of \
          Yours and Our rights before You use the HIgedi Application ("HIgedi"). By downloading or using the \
          App, these Terms will automatically apply to You – You should make sure therefore that You read them \
          carefully before using the App. You’re not allowed to copy, or modify the App, any part of the App, or \
          Our trademarks in any way. You’re not allowed to attempt to extract the source code of the App, and \
          You also shouldn’t try to translate the App into other languages, or make derivative versions. The \
          App itself, and all the trade marks, copyright, database rights and other intellectual property \
          rights related to it, still belong to Wildcard OY.</br></br>\
            If You do not agree to be bound by these Terms, or other referenced agreements or documentation, You must cease to access or use HIgedi Application.</br></br>\
            If You have any questions about these Terms or our App, please contact Us at: <a style="color: #0266E8"  href="mailto:hi@higedi.com">hi@higedi.com</a>.',
      },
    ],
  },
  {
    title: 'Access to HIgedi',
    content: [
      {
        article:
          'These Terms and Conditions apply to Your access to and use of HIgedi. Subject to Your compliance with these Terms, You may use HIgedi in accordance with any documentation or guidelines We make available to You.',
      },
    ],
  },
  {
    title: 'Changes to these Terms and Conditions and HIgedi',
    content: [
      {
        article:
          'We are committed to ensuring that the App is as useful and efficient as possible. For that reason, \
          except where prohibited by applicable law, We reserve the right to make changes to the App or to \
          charge for its services, at any time and for any reason. We will never charge You for the App or \
          its services without making it clear to You exactly what You’re paying for. When We change these Terms,\
           We may, at Our discretion, place a notice on HIgedi website, send You an e-mail, or notify You by some \
           other means, including as required by applicable law. We may also update the “Last Updated” date \
           at the top of these Terms and Conditions. Your continued access to or use of HIgedi after any \
           changes to these Terms indicates Your acceptance of such changes. </br></br> \
           We may, at Our discretion, suspend Your access to or use of HIgedi or any component thereof:',
        ulList: [
          'for scheduled maintenance;',
          'if You violate any provision of these Terms; or',
          'to address any emergency security concerns.',
        ],
        listGap: true,
      },
    ],
  },
  {
    title: 'Access to HIgedi',
    content: [
      {
        article:
          'Before You can use our App, You will need to register for an Account. In order to create an Account You must:',
        numberList: [
          'Sign in through your Facebook, Google or Apple account;',
          'Be at least 13 years old;',
          'Be legally permitted to use the App in Your country.',
        ],
      },
    ],
  },
  {
    title: undefined,
    content: [
      {
        article:
          "If You create an Account, You authorize Us to access, display and use certain information from your Facebook, Google, or Apple account (e.g. profile pictures, relationship status, location and information about Facebook friends, as applicable). For more information about what information We use and how We use it, please check out Our Privacy Policy.</br></br>\
           You cannot use another User’s Account without her permission. You cannot create duplicate HIgedi Accounts in addition to Your original Account.</br></br>\
           We hope You love the HIgedi community, but if You want to leave the App, You can delete Your Account at any time by going to the 'Settings' screen of your Account when You are logged in, and clicking on the 'Delete my Account' link and following the steps shown, or for Facebook, Google or Apple, You can go to the relevant settings page within those services and disallow access by the App. We will save Your profile information in case You realize You miss Us and You decide to restore Your Account; check Our Privacy Policy for more information. If You would like Your Content or profile information to be deleted, please contact Us at: <a style='color: #0266E8'  href='mailto:hi@higedi.com'>hi@higedi.com</a>.</br></br>\
           We reserve the right at its sole discretion to terminate or suspend any Account, or make use of any operational, technological, legal or other means available to enforce the Terms at any time without liability and without the need to give You prior notice.",
      },
    ],
  },
  {
    title: 'Your Responsibilities and Compliance with Community Guidelines',
    content: [
      {
        article:
          'We encourage our Users to freely communicate on HIgedi, but We have to impose restrictions on \
          certain content. Please see our Community Guidelines available at <a target="_blank" style="color: #0266E8" href="https://higedi.com/communityguidelines">www.hidegi.com/communityguidelines</a> \
          (“Community Guidelines”) which contains important information about Our guidelines and rules for \
          HIgedi, and which is hereby incorporated into and forms a part of these Terms, and governs \
          Your conduct on HIgedi. You agree to comply with Our Community Guidelines at all times when\
           using HIgedi. We have a moderation team that reviews Your Content to ensure that it complies \
           with these Terms. Our moderation tools and team of moderators may remove any of Your Content \
           that they consider breaches our Community Guidelines, restrict or prohibit Your access to the \
           App, and/or delete Your Account, if in their reasonable opinion, You breach any of the restrictions \
           in these Terms or the Community Guidelines.</br></br>\
           In addition to complying with our Community Guidelines, You will:',
        ulList: [
          'act in a respectful manner towards other Users;',
          'not use language which could be deemed offensive or is likely to harass, upset, embarrass, mistreat, alarm, or annoy any other person, or use HIgedi for any illegal purpose;',
          'not encourage any illegal activity including, without limitation, terrorism, inciting racial hatred, or the submission of which in itself constitutes committing a criminal offense;',
          'not post Content that is obscene, vulgar, pornographic, or otherwise may offend human dignity;',
          'not solicit passwords for any purpose, or personal information for commercial or illegal purposes from other Users or disseminate another person’s personal information without their permission;',
          'not solicit money or other valuable items from another User;',
          'not publish, market, advertise or in any way distribute the Content;',
          'not copy, modify, transmit, disseminate or create derivative works from any Content or any intellectual property, content or proprietary information accessed through HIgedi, without Our prior written consent;',
          'not use HIgedi for the purpose of building a similar or competitive product or service;',
          'not use HIgedi in relation to fraud or any other similar practice;',
          'not attempt to gain unauthorized access to HIgedi, or bypass any measures We may use to prevent or restrict access to the App;',
          'not run any form of auto-responder on HIgedi, or any processes that involves the transmission of "junk" mail or "spam.',
        ],
        listGap: true,
      },
    ],
  },
  {
    title: undefined,
    content: [
      {
        article:
          "We don't tolerate bad behavior on HIgedi. You can report any abuse or complain by \
          contacting Us, outlining the abuse and/or complaint. E-mail Us at: \
          <a style='color: #0266E8'  href='mailto:hi@higedi.com'>hi@higedi.com</a>.",
      },
    ],
  },
  {
    title: 'Your Content (content that You upload and provide)',
    content: [
      {
        article:
          'As Your Content is created by You, You are responsible and liable for Your Content and will indemnify, defend, release, and hold Us harmless from any claims made in connection with Your Content.</br></br>\
          You may not display any personal contact or banking information on Your individual profile page whether in relation to You or any other person. If You still choose to reveal any personal information about yourself to other Users, it is at Your own risk.</br></br>\
          HIgedi is a public community, so Your Content will be visible to other Users of the App all around the world. Please make sure You are comfortable sharing Your Content before You post it. As such, You agree that Your Content may be viewed by other Users. By uploading Your Content on HIgedi, You represent and warrant to Us that You have all necessary rights and licenses to do so, and automatically grant us a non-exclusive, royalty free, perpetual, worldwide license to use Your Content in any way (including, without limitation, editing, copying, modifying, adapting, translating, reformatting, creating derivative works from, incorporating into other works, advertising, distributing and otherwise making available to the general public Your Content, whether in whole or in part and in any format or medium currently known or developed in the future).</br></br>\
          We have the right to remove, edit, limit or block access to any of Your Content at any time, and We have no obligation to display or review Your Content.',
      },
    ],
  },
  {
    title: 'Our Content',
    content: [
      {
        article:
          'The rest of the Content (except Your Content) on App belongs to Us. Any other text, graphics, User \
          interfaces, trademarks, logos, sounds, artwork, content and other intellectual property appearing on \
          HIgedi are owned, controlled or licensed by Us and are protected by copyright, trademark and other \
          intellectual property law rights. All right, title and interest in and to Our Content remains with Us \
          at all times.</br></br> We grant You a non-exclusive, limited, personal, non-transferable, revocable, \
          license to access and use Our Content, without the right to sublicense, under the following conditions:',
        listGap: true,
        ulList: [
          'You are not allowed to use, sell, modify, or distribute Our Content except as permitted by the functionality of the App;',
          'You are not allowed to use Our name in meta-tags, keywords and/or hidden text;',
          'You are not allowed to create derivative works from Our Content or commercially exploit Our Content in any way;',
          'You are allowed to use Our Content for lawful purposes only.',
        ],
      },
    ],
  },
  {
    title: undefined,
    content: [
      {
        article: 'We reserve all other rights.',
      },
    ],
  },
  {
    title: 'Privacy',
    content: [
      {
        article:
          'For information about how HIgedi collects, uses, and shares Your personal data, please check out Our Privacy Policy.</br></br>\
        The HIgedi App stores and processes personal data that You have provided to Us, in order to provide our Service. It’s Your responsibility to keep Your phone and access to the App secure. We therefore recommend that You do not jailbreak or root Your phone, which is the process of removing software restrictions and limitations imposed by the official operating system of Your Device. It could make Your phone vulnerable to malware/viruses/ malicious programs, compromise Your phone’s security features and it could mean that HIgedi App won’t work properly or at all.</br></br>\
        You should be aware that there are certain things that HIgedi will not take responsibility for. Most of the functions of the App will require the App to have an active Internet connection. The connection can be Wi-Fi, or provided by Your mobile network provider, but HIgedi cannot take responsibility for the App not working at full functionality if You don’t have access to Wi-Fi, and You don’t have any of Your data allowance left.</br></br>\
        If You’re using the App outside of an area with Wi-Fi, You should remember that Your terms of the agreement with Your mobile network provider will still apply. As a result, You may be charged by Your mobile provider for the cost of data for the duration of the connection while accessing the App, or other third party charges. In using the App, You’re accepting responsibility for any such charges, including roaming data charges if You use the App outside of Your home territory (i.e. region or country) without turning off data roaming. If You are not the bill payer for the Device on which You’re using the App, please be aware that We assume that You have received permission from the bill payer for using the App.</br></br>\
        With respect to HIgedi’s responsibility for Your use of the App, when You’re using the App, it’s important to bear in mind that although We endeavor to ensure that it is updated and correct at all times, We do rely on third parties to provide information to Us so that We can make it available to You. HIgedi accepts no liability for any loss, direct or indirect, You experience as a result of relying wholly on this functionality of the App.</br></br>\
        At some point, We may wish to update the App. The App is currently available on Android & iOS – the requirements for both systems (and for any additional systems We decide to extend the availability of the App to) may change, and You’ll need to download the updates if You want to keep using the App. HIgedi does not promise that it will always update the App so that it is relevant to You and/or works with the Android & iOS version that You have installed on your Device. However, You promise to always accept updates to the Application when offered to You, We may also wish to stop providing the App, and may terminate use of it at any time without giving notice of termination to You. Unless We tell You otherwise, upon any termination, (a) the rights and licenses granted to You in these Terms will end; (b) You must stop using the App, and (if needed) delete it from Your Device.',
      },
    ],
  },
  {
    title: 'Third-party stores',
    content: [
      {
        article:
          'The App may provide links or access to third-party content, websites, or services that are not owned or controlled by HIgedi. Likewise, We may allow You to access HIgedi from third party systems e.g., Apple (iTunes, etc.), Google, etc. (each, a “Third-Party Platform”) and may require that You be a registered member of such Third-Party Platforms and provide certain account credentials and other information in order to access the App. By using the App, You agree to comply with any applicable terms, conditions or requirements promulgated by any provider of a Third-Party Platform (e.g., Facebook’s Terms of Use, iTunes Store Terms of Use, etc.).</br></br>\
        HIgedi does not endorse any third-party content, websites, services, or systems, or guarantee their quality, accuracy, reliability, completeness, currency, timeliness, non-infringement, merchantability,</br></br>\
        or fitness for any purpose. Third party content, websites, services, or systems are not under HIgedi’s control, and if You choose to access any such content, websites, services or to access HIgedi from such systems, You do so entirely at Your own risk. You acknowledge that You may be required to accept terms of use applicable to third-party content, websites, services, or systems and agree to accept and comply with any such terms of use. Your interactions with individuals found on or through HIgedi, including payment and delivery of goods and services, and any other terms, conditions, warranties, or representations associated with such dealings, are solely between You and such individuals. You agree that, to the fullest extent permitted by applicable law, HIgedi is not responsible or liable for any loss or damage of any sort incurred as the result of any such dealings.',
      },
    ],
  },
  {
    title: 'Push Notifications',
    content: [
      {
        article:
          'When You install our App on Your mobile Device, You agree to receive push notifications, which are messages an App sends You on Your mobile Device when You are not in the App. You can turn off notifications by visiting Your mobile Device’s ‘settings’ page.',
      },
    ],
  },
  {
    title: 'E-mail',
    content: [
      {
        article:
          'We may send You e-mails concerning our products and services, as well as those of third parties. You may opt out of promotional e-mails by following the unsubscribe instructions in the promotional e-mail itself.',
      },
    ],
  },
  {
    title: 'Disclaimers; No Warranties by HIgedi',
    content: [
      {
        article:
          '<span style="font-weight: 600;">YOUR USE OF THE APP IS AT YOUR OWN RISK. THE SERVICE AND ALL MATERIALS AND CONTENT AVAILABLE THROUGH THE SERVICE ARE PROVIDED “AS IS” AND ON AN “AS AVAILABLE” BASIS. HIGEDI DISCLAIMS ALL WARRANTIES OF ANY KIND, WHETHER EXPRESS OR IMPLIED, RELATING TO THE SERVICE AND ALL MATERIALS AND CONTENT AVAILABLE THROUGH THE SERVICE, INCLUDING: (A) ANY IMPLIED WARRANTY OF MERCHANTABILITY, FITNESS FOR A PARTICULAR PURPOSE, TITLE, QUIET ENJOYMENT, OR NON-INFRINGEMENT; AND (B) ANY WARRANTY ARISING OUT OF COURSE OF DEALING, USAGE, OR TRADE. HIGEDI DOES NOT WARRANT THAT THE SERVICE OR ANY PORTION OF THE SERVICE, OR ANY MATERIALS OR CONTENT OFFERED THROUGH THE SERVICE, WILL BE UNINTERRUPTED, SECURE, OR FREE OF ERRORS, VIRUSES, OR OTHER HARMFUL COMPONENTS, AND HIGEDI DOES NOT WARRANT THAT ANY OF THOSE ISSUES WILL BE CORRECTED.</br></br>\
          NO ADVICE OR INFORMATION, WHETHER ORAL OR WRITTEN, OBTAINED BY YOU FROM THE SERVICE OR HIGEDI ENTITIES OR ANY MATERIALS OR CONTENT AVAILABLE THROUGH THE SERVICE WILL CREATE ANY WARRANTY REGARDING ANY OF THE HIGEDI ENTITIES OR THE SERVICE THAT IS NOT EXPRESSLY STATED IN THESE TERMS. WE ARE NOT RESPONSIBLE FOR ANY DAMAGE THAT MAY RESULT FROM THE SERVICE AND YOUR DEALING WITH ANY OTHER SERVICE USER. YOU UNDERSTAND AND AGREE THAT YOU USE ANY PORTION OF THE SERVICE AT YOUR OWN DISCRETION AND RISK, AND THAT WE ARE NOT RESPONSIBLE FOR ANY DAMAGE TO YOUR PROPERTY (INCLUDING YOUR COMPUTER SYSTEM OR MOBILE DEVICE USED IN CONNECTION WITH THE SERVICE) OR ANY LOSS OF DATA, INCLUDING USER CONTENT.</br></br>\
          HIGEDI DOES NOT PROVIDE ANY MEDICAL OR PROFESSIONAL MEDICAL, DIETITIAN OR NUTRITIONIST SERVICES OR ADVICE. ALL INFORMATION AND SERVICES PROVIDED BY HIGEDI OR ITS USERS ARE NOT INTENDED TO BE A SUBSTITUTE FOR ANY PROFESSIONAL SERVICE OR ADVICE. YOU SHOULD NOT DISREGARD THE ADVICE FROM A HEALTHCARE PROFESSIONAL OR DELAY IN SEEKING PROFESSIONAL ADVICE BECAUSE OF YOUR USE OF THE SERVICE. ALL INFORMATION AND SERVICES PROVIDED BY HIGEDI ARE INTENDED FOR GENERAL INFORMATIONAL PURPOSES ONLY. HIGEDI DOES NOT WARRANT THE ACCURACY, COMPLETENESS, RELIABILITY, OR USEFULNESS OF ANY INFORMATION OR CONTENT PROVIDED ON OR THROUGH SERVICE, INCLUDING INFORMATION OR CONTENT OBTAINED FROM ANY THIRD PARTY, USERS OF HIGEDI, OR PUBLIC</br>\
          DATABASE. YOU ACKNOWLEDGE AND AGREE THAT NONE OF THE INFORMATION OR CONTENT PROVIDED BY OR THROUGH HIGEDI OR THE SERVICE HAS THE ABILITY TO DIAGNOSE, PRESCRIBE, OR PERFORM ANY TASK THAT CONSTITUTES THE PRACTICE OF MEDICINE OR PROFESSIONAL DIETITIAN OR NUTRITIONIST SERVICE OR ADVICE. IF YOU RELY ON ANY DATA OR INFORMATION OBTAINED THROUGH HIGEDI OR THE SERVICE, YOU DO SO AT YOUR OWN RISK. YOU ARE SOLELY RESPONSIBLE FOR ANY DAMAGE OR LOSS THAT RESULTS FROM YOUR USE OF SUCH DATA OR INFORMATION. IF YOU SUSPECT THAT YOU MAY HAVE A MEDICAL EMERGENCY, SEEK MEDICAL ATTENTION STRAIGHT AWAY.</span> </br></br>\
          We make reasonable efforts to update the information on the App, however We do not represent, warrant or guarantee that the Content on the App is accurate, complete or up-to-date. We also do not warrant or represent that the information or materials available through the App will meet Your particular requirements or needs or that access to, or use of, the same will be uninterrupted or completely secure. The information contained in the App, including any User Content, may contain inaccuracies or errors. We are not required to update the App or to correct any inaccuracies which may become apparent but will be entitled to do so is We wish without notice to You.</br></br>\
          <span style="font-weight: 600;">THE LIMITATIONS, EXCLUSIONS, AND DISCLAIMERS IN THIS SECTION APPLY TO THE FULLEST EXTENT PERMITTED BY LAW.</br>\
          We do not disclaim any warranty or other right that We are prohibited from disclaiming under applicable law.</span>',
      },
    ],
  },
  {
    title: 'Limitation of liability',
    content: [
      {
        article:
          "Notwithstanding any damages that You might incur, the entire liability of the Company and any of its suppliers under any provision of this Terms and Your exclusive remedy for all of the foregoing shall be limited to the amount actually paid Us in the last six (6) months, or, if greater, one hundred euros (100 EUR). Nothing in these Terms will in any way exclude or limit a party's liability to the other party for: (a) death or personal injury caused by that party's negligence; (b) fraud or fraudulent misrepresentation; and/or (c) any other matter for which it would be illegal to exclude or attempt to exclude its liabilities.</br></br>\
          To the maximum extent permitted by applicable law, in no event shall the Company or its suppliers be liable for any special, incidental, indirect, or consequential damages whatsoever (including, but not limited to, damages for loss of profits, loss of goodwill, loss of data, or other information, for business interruption, for personal injury, loss of privacy arising out of or in any way related to the use of or inability to use the Service, third-party software and/or third-party hardware used with the Service, or otherwise in connection with any provision of these Terms), even if the Company or any supplier has been advised of the possibility of such damages and even if the remedy fails of its essential purpose.</br></br>\
          Some states do not allow the exclusion of implied warranties or limitation of liability for incidental or consequential damages, which means that some of the above limitations may not apply. In these states, each party's liability will be limited to the greatest extent permitted by law.",
      },
    ],
  },
  {
    title: 'Governing Law',
    content: [
      {
        article:
          'The laws of the Country, excluding its conflicts of law rules, shall govern this Terms and Your use of the Service. Your use of the Application may also be subject to other local, state, national, or international laws.',
      },
    ],
  },
  {
    title: 'Disputes Resolution',
    content: [
      {
        article:
          'If You have any concern or dispute about the Service, You agree to first try to resolve the dispute informally by contacting the Company.',
      },
    ],
  },
  {
    title: 'For European Union (EU) Users',
    content: [
      {
        article:
          'If You are a European Union consumer, You will benefit from any mandatory provisions of the law of the country in which You are resident in.',
      },
    ],
  },
  {
    title: 'United States Legal Compliance',
    content: [
      {
        article:
          'You represent and warrant that (a) You are not located in a country that is subject to the United States government embargo, or that has been designated by the United States government as a "terrorist supporting" country, and (b) You are not listed on any United States government list of prohibited or restricted parties.',
      },
    ],
  },
  {
    title: 'Severability and Waiver',
    content: [
      {
        article:
          "<b>Severability</b></br>\
         If any provision of these Terms is held to be unenforceable or invalid, such provision will be changed and interpreted to accomplish the objectives of such provision to the greatest extent possible under applicable law and the remaining provisions will continue in full force and effect.</br></br>\
         <b>Waiver</b></br>\
         Except as provided herein, the failure to exercise a right or to require performance of an obligation under these Terms shall not effect a party's ability to exercise such right or require such performance at any time thereafter nor shall the waiver of a breach constitute a waiver of any subsequent breach.",
      },
    ],
  },
  {
    title: 'Contact Us',
    content: [
      {
        article:
          "If You have any questions or suggestions about these Terms and Conditions, do not hesitate to contact Us by sending Us an e-mail:  <a style='color: #0266E8'  href='mailto:hi@higedi.com'>hi@higedi.com</a>.",
      },
    ],
  },
];
