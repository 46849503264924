export const LOGGED_IN_KEY = 'logged-in';
const ACCESS_TOKEN_KEY = 'access-token';

export const setAccessToken = (accessToken: string): void => {
  localStorage.setItem(ACCESS_TOKEN_KEY, accessToken);
};

export const removeAccessToken = (): void => {
  localStorage.removeItem(ACCESS_TOKEN_KEY);
};

export const getAccessToken = (): string | null => localStorage.getItem(ACCESS_TOKEN_KEY);

export const setLoggedIn = (value: boolean) => localStorage.setItem(LOGGED_IN_KEY, String(value));

export const login = (accessToken: string) => {
  setAccessToken(accessToken);
  setLoggedIn(true);
};
export const logout = () => {
  removeAccessToken();
  setLoggedIn(false);
  location.reload();
};
