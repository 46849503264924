import React, { useEffect, useState } from 'react';
import { Box, TextField, Typography, Modal, Button } from '@mui/material';

import { editCategory } from '@src/services/marketplace/api';
import { Category } from '@src/types';

const style = {
  position: 'absolute' as const,
  display: 'grid',
  gridGap: '20px',
  textAlign: 'center',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 400,
  bgcolor: 'background.paper',
  border: '2px solid #000',
  boxShadow: 24,
  p: 4,
};

interface Props {
  showEditModal: boolean;
  setShowEditModal: React.Dispatch<React.SetStateAction<boolean>>;
  id: number | null;
  setCategories: React.Dispatch<React.SetStateAction<Category[]>>;
  categories: Category[];
}

export const EditCategoryModal: React.FC<Props> = ({
  showEditModal,
  setShowEditModal,
  setCategories,
  id,
  categories,
}) => {
  const [newCategory, setNewCategory] = useState<string>('');
  const [error, setError] = useState(false);

  useEffect(() => {
    const category = categories.find(category => category.id === id);
    setNewCategory(category ? category.category : '');
  }, [id, showEditModal]);

  const handleEdit = async () => {
    if (newCategory.length < 3) {
      setError(true);
      return;
    }

    if (id) {
      const response = await editCategory(id, newCategory);
      setCategories(response.data.body.categories);
    }
    setShowEditModal(!showEditModal);
    setNewCategory('');
  };

  return (
    <Modal
      open={showEditModal}
      onClose={() => setShowEditModal(!showEditModal)}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <Box sx={style}>
        <Typography id="modal-modal-title" variant="h6" component="h2">
          Type name of Category
        </Typography>
        <TextField
          id="outlined-basic"
          label="Category"
          variant="outlined"
          value={newCategory}
          onChange={event => setNewCategory(event.target.value)}
          error={error}
          helperText={error ? 'Please, type more than three characters' : ''}
        />
        <Box style={{ display: 'inline-flex', justifyContent: 'space-between' }}>
          <Button
            autoFocus
            color="error"
            onClick={() => {
              setShowEditModal(!showEditModal);
              setNewCategory('');
              setError(false);
            }}
          >
            CANCEL
          </Button>
          <Button autoFocus color="success" onClick={handleEdit}>
            EDIT
          </Button>
        </Box>
      </Box>
    </Modal>
  );
};
