import React, { useState } from 'react';
import { IconButton, Typography, TableCell, TableRow, Box } from '@mui/material';
import RemoveRedEyeSharpIcon from '@mui/icons-material/RemoveRedEyeSharp';
import DoNotDisturbIcon from '@mui/icons-material/DoNotDisturb';
import CheckIcon from '@mui/icons-material/Check';

import { IReport, IReports, IUser } from '@src/types';
import { UserInfo } from '@src/components/UserInfo';
import { ViewReports } from '@src/components/Reports/ViewReports';
import { deleteUserReport, updateUserReport } from '@src/services/reports/api';
import { RemoveModal } from '@src/components/Modals/Remove';
import { toastPromise } from '@src/components/Toast';
import ImageWithLoading from '@src/components/ImageWithLoading';

interface Props {
  key: number | undefined;
  user: IUser;
  reports: IReport[];
  users: IReports[];
  setUsers: React.Dispatch<React.SetStateAction<IReports[]>>;
}

export const Row: React.FC<Props> = ({ user, users, setUsers, reports }) => {
  const [showUser, setShowUser] = useState<boolean>(false);
  const [showReports, setShowReports] = useState<boolean>(false);
  const [newReports, setNewReports] = useState<IReport[]>(reports || []);
  const [showRemoveModal, setShowRemoveModal] = useState<boolean>(false);
  const [action, setAction] = useState<string>('cancelled');
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const reasons = reports.map(report => report.reason).join(',');

  const handleUpdateReport = async () => {
    setIsLoading(true);
    try {
      if (user?.id) {
        await toastPromise<Awaited<ReturnType<typeof updateUserReport>>>(
          updateUserReport(user?.id, action, user?.email),
          {
            loading: 'Updating report...',
            success: 'Report updated successfully',
            error: 'Something went wrong, please try again',
          },
        );
      }

      const new_users = users.filter(i => i.user?.id !== user?.id);

      setUsers(new_users);
    } catch (error) {
      console.error(error);
    } finally {
      setShowRemoveModal(!showRemoveModal);
      setIsLoading(false);
    }
  };

  return (
    <>
      {newReports.length > 0 && (
        <>
          <TableRow>
            <TableCell component="th" scope="row" align="center">
              <Typography variant="h6" component="h6">
                {newReports?.length}
              </Typography>
            </TableCell>
            <TableCell align="center">
              <Box sx={{ display: 'flex', justifyContent: 'center' }}>
                {user?.avatarPath ? (
                  <ImageWithLoading
                    alt="user avatar"
                    src={user?.avatarPath}
                    width={75}
                    height={75}
                  />
                ) : null}
              </Box>
            </TableCell>
            <TableCell
              align="center"
              style={{ cursor: 'pointer' }}
              onClick={() => setShowUser(!showUser)}
            >
              {user?.email || `${user?.firstName} ${user?.lastName}` || ''}
            </TableCell>
            <TableCell align="center">
              {reasons.length > 30 ? `${reasons.substring(0, 30)}...` : reasons}
            </TableCell>
            <UserInfo showUser={showUser} setShowUser={setShowUser} user={user} />
            <TableCell align="center">
              <IconButton
                style={{ cursor: 'pointer' }}
                onClick={() => setShowReports(!showReports)}
              >
                <RemoveRedEyeSharpIcon />
              </IconButton>
            </TableCell>
            <TableCell align="center">
              <Box sx={{ display: 'inline-flex' }}>
                <IconButton
                  onClick={() => {
                    setShowRemoveModal(!showRemoveModal);
                    setAction('cancelled');
                  }}
                  color={'success'}
                >
                  <CheckIcon />
                </IconButton>
                <IconButton
                  onClick={() => {
                    setShowRemoveModal(!showRemoveModal);
                    setAction('approved');
                  }}
                  color={'error'}
                >
                  <DoNotDisturbIcon />
                </IconButton>
              </Box>
            </TableCell>
          </TableRow>
          <RemoveModal
            showRemoveModal={showRemoveModal}
            setShowRemoveModal={setShowRemoveModal}
            handleDelete={handleUpdateReport}
            isLoading={isLoading}
            content={
              action === 'approved'
                ? 'Do you want to block the user?'
                : 'Do you want to ignore user reports?'
            }
            title={'Blocking User'}
            approveBtnText={action === 'approved' ? 'Block' : 'Ignore'}
          />
          <ViewReports
            showReports={showReports}
            setShowReports={setShowReports}
            reports={newReports}
            setReports={setNewReports}
            deleteReport={deleteUserReport}
          />
        </>
      )}
    </>
  );
};
