import React, { useState } from 'react';
import { useFormik } from 'formik';
import {
  Card,
  CardHeader,
  IconButton,
  CardMedia,
  CardContent,
  Box,
  Dialog,
  useMediaQuery,
  TextField,
  Button,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
} from '@mui/material';
import { useTheme } from '@mui/material/styles';
import { HighlightOffOutlined } from '@mui/icons-material';

import { toBase64 } from '@src/utils/toBase64';
import { validateUser } from '@src/utils/validators';
import { IKeyword, IUser, IUserExperience, ModalImage } from '@src/types';
import { deleteImage } from '@src/services/marketplace/api';
import { updateUser } from '@src/services/users/api';
import { hideToast, showToast } from '../Toast';
import { AxiosError } from 'axios';

interface Props {
  showUserModal: boolean;
  setShowUserModal: React.Dispatch<React.SetStateAction<boolean>>;
  setRows: React.Dispatch<React.SetStateAction<any[]>>;
  rows: IUser[];
  userId: string;
}

export const User: React.FC<Props> = ({
  showUserModal,
  setShowUserModal,
  setRows,
  rows,
  userId,
}) => {
  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down('md'));
  const user = rows?.find((i: IUser) => i.id === +userId) as IUser;
  const [experience, setExperience] = useState<string>(user.experience || 'Newcomer');

  const formik = useFormik({
    initialValues: {
      text: '',
      keywords: [] as IKeyword[],
      users: rows || [],
      firstName: user.firstName || '',
      lastName: user.lastName || '',
      email: user.email || '',
      user: user || ({} as IUser),
      image:
        ({
          avatarPath: user.avatarPath,
          base64: '',
          src: '',
          url: '',
          avatarKey: user.avatarKey,
        } as ModalImage) || ({} as ModalImage),
      experience: user.experience || experience,
    },
    validationSchema: validateUser,
    onSubmit: (): void => {
      ('');
    },
  });

  const handleUpdateUser = async () => {
    if (formik.values.image.avatarPath) {
      const loadingToastId = showToast('loading', 'Updating user...');
      try {
        const updated_user_without_image = await updateUser(
          formik.values.firstName,
          formik.values.lastName,
          formik.values.email,
          +userId,
          experience,
        );

        hideToast(loadingToastId);
        showToast('success', 'User updated successfully');
        const new_rows = rows.map(row => {
          if (row.id === +userId) {
            return updated_user_without_image;
          }

          return row;
        });
        setRows(new_rows);
        setShowUserModal(!showUserModal);
      } catch (error) {
        hideToast(loadingToastId);
        if (error instanceof AxiosError) {
          let errorMessage = 'Error updating user';
          const errorType = error.response?.data?.body?.error?.field?.name;
          if (errorType === 'SequelizeUniqueConstraintError') {
            errorMessage = 'Email already taken by another user';
          }
          showToast('error', errorMessage);
        }
      } finally {
        hideToast(loadingToastId);
      }
    }

    Reflect.deleteProperty(formik.values.image, 'src');
    const updated_user_with_image = await updateUser(
      formik.values.firstName,
      formik.values.lastName,
      formik.values.email,
      +userId,
      experience,
      formik.values.image?.base64,
    );

    const new_users = rows.map(row => {
      if (row.id === +userId) {
        return updated_user_with_image;
      }

      return row;
    });
    setRows(new_users);
    setShowUserModal(!showUserModal);
  };

  const onChangeImage = async (event: React.ChangeEvent<HTMLInputElement>) => {
    if (event.target.files !== null) {
      const { base64 } = await toBase64(event.target.files[0]);

      formik.setFieldValue('image', {
        src: URL.createObjectURL(event.target.files[0]),
        base64,
        type_of_img: event.target.files[0].type.replace('image/', ''),
      });
    }
  };

  const handleDeleteImage = async () => {
    if (formik.values.image.avatarKey && typeof formik.values.image.avatarKey === 'string') {
      await deleteImage(formik.values.image.avatarKey);
    }

    await formik.setFieldValue('image', {
      avatarPath: '',
      base64: '',
      src: '',
      avatarKey: '',
    });
  };

  return (
    <Box>
      <Dialog
        fullScreen={fullScreen}
        open={showUserModal}
        onClose={() => setShowUserModal(!showUserModal)}
        aria-labelledby="responsive-dialog-title"
      >
        <Card sx={{ width: 500, minHeight: 800 }}>
          <CardHeader
            sx={{ textAlign: 'center' }}
            title={userId ? 'Editing User' : 'Creating User'}
          />
          <Box
            sx={{
              display: 'grid',
              justifyContent: 'center',
              height: 200,
              alignContent: 'space-around',
              gap: '15px',
              paddingBottom: '10px',
              marginTop: '50px',
              marginBottom: '30px',
            }}
          >
            <TextField
              label="First Name"
              name="firstName"
              value={formik.values.firstName}
              onChange={formik.handleChange}
              helperText={formik.touched.firstName ? formik.errors.firstName : ''}
              error={formik.touched.firstName && Boolean(formik.errors.firstName)}
            />
            <TextField
              label="Last Name"
              name="lastName"
              value={formik.values.lastName}
              onChange={formik.handleChange}
              helperText={formik.touched.lastName ? formik.errors.lastName : ''}
              error={formik.touched.lastName && Boolean(formik.errors.lastName)}
            />
            <TextField
              label="Email"
              name="email"
              value={formik.values.email}
              onChange={formik.handleChange}
              helperText={formik.touched.email ? formik.errors.email : ''}
              error={formik.touched.email && Boolean(formik.errors.email)}
            />
            <FormControl fullWidth>
              <InputLabel id="select-experience">Experience</InputLabel>
              <Select
                labelId="select-experience"
                id="select-experience"
                value={experience}
                label="experience"
                onChange={event => {
                  setExperience(event.target.value);
                }}
              >
                <MenuItem value={IUserExperience.expert}>Expert</MenuItem>
                <MenuItem value={IUserExperience.healthcare_professional}>
                  Healthcare Professional
                </MenuItem>
                <MenuItem value={IUserExperience.mentor}>Mentor</MenuItem>
                <MenuItem value={IUserExperience.new_comer}>Newcomer</MenuItem>
              </Select>
            </FormControl>
          </Box>
          <CardContent sx={{ display: 'grid', justifyContent: 'center' }}>
            {!formik.values.image.avatarKey && (
              <Box
                sx={{
                  display: 'flex',
                  justifyContent: 'center',
                  minHeight: 100,
                }}
              >
                <Button
                  variant="outlined"
                  sx={{ width: 100, alignSelf: 'center' }}
                  component="label"
                >
                  Upload
                  <input
                    hidden
                    accept="image/*"
                    multiple
                    name="image"
                    type="file"
                    onChange={onChangeImage}
                  />
                </Button>
              </Box>
            )}
            {(formik.values.image.src || formik.values.image.avatarPath) && (
              <Box
                sx={{
                  display: 'flex',
                  justifyContent: 'center',
                  margin: 2,
                }}
              >
                <CardMedia
                  component="img"
                  height="250"
                  image={formik.values.image?.src || formik.values.image.avatarPath || ''}
                  alt="Paella dish"
                />
                <IconButton
                  aria-label="delete"
                  style={{
                    backgroundColor: 'transparent',
                    alignItems: 'flex-start',
                    height: '50px',
                  }}
                  onClick={handleDeleteImage}
                >
                  <HighlightOffOutlined fontSize="inherit" cursor="pointer" color="error" />
                </IconButton>
              </Box>
            )}
          </CardContent>
          <Box
            sx={{
              display: 'flex',
              justifyContent: 'space-around',
              marginBottom: 5,
            }}
          >
            <Button
              variant="outlined"
              sx={{ width: 100, alignSelf: 'center' }}
              component="label"
              color="error"
              onClick={() => setShowUserModal(!showUserModal)}
            >
              Cancel
            </Button>
            <Button
              variant="outlined"
              sx={{ width: 100, alignSelf: 'center' }}
              type="submit"
              onClick={handleUpdateUser}
            >
              Submit
            </Button>
          </Box>
        </Card>
      </Dialog>
    </Box>
  );
};
