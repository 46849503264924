import { Button, TextareaAutosize } from '@mui/material';
import { toastPromise } from '@src/components/Toast';
import { getAffiliateText, updateAffiliateText } from '@src/services/marketplace/api';
import { validateAffiliateNote } from '@src/utils/validators';
import { useFormik } from 'formik';
import { useEffect } from 'react';

const Texts = () => {
  useEffect(() => {
    const fetchData = async () => {
      const { data } = await getAffiliateText();

      formik.setFieldValue('title', data.title);
      formik.setFieldValue('text', data.text);
      formik.setFieldValue('description', data.description);
    };

    fetchData();
  }, []);

  const formik = useFormik({
    initialValues: {
      title: 'Affiliate program note',
      text: 'We are a participant in the Amazon Services LLC Associates Program, an affiliate advertising program designed to provide a means for us to earn fees by linking to http://Amazon.com and affiliated sites.',
      description:
        'CERTAIN CONTENT THAT APPEARS IN THIS APPLICATION COMES FROM AFFILIATE SITES. THIS CONTENT IS PROVIDED ‘AS IS’ AND IS SUBJECT TO CHANGE OR REMOVAL AT ANY TIME.',
    },
    validationSchema: validateAffiliateNote,

    async onSubmit(values) {
      console.log('values', values);
      const body = {
        title: values.title || undefined,
        text: values.text || undefined,
        description: values.description || undefined,
      };

      const response = await toastPromise<Awaited<ReturnType<typeof updateAffiliateText>>>(
        updateAffiliateText(body),
        {
          loading: 'Updating text...',
          success: 'Text updated successfully',
          error: 'Something went wrong, please try again',
        },
      );

      if (response?.status === 200) {
        formik.setFieldValue('title', response.data.body.title);
        formik.setFieldValue('text', response.data.body.text);
        formik.setFieldValue('description', response.data.body.description);
      }
    },
  });

  return (
    <div>
      <form onSubmit={formik.handleSubmit} onReset={formik.handleReset}>
        <h3>Affiliate program note</h3>

        <TextareaAutosize
          onChange={event => {
            formik.setFieldValue('title', event.target.value);
          }}
          minRows={1}
          style={{
            width: '100%',
            maxWidth: '600px',
            borderRadius: 3,
            padding: '15px',
            minHeight: 10,
            margin: '10px 0 0 0',
            fontFamily: '"Roboto","Helvetica","Arial",sans-serif',
            fontSize: '16px',
          }}
          placeholder="Title"
          value={formik.values.title || ''}
        />

        <TextareaAutosize
          aria-label="maximum height"
          onChange={event => {
            formik.setFieldValue('text', event.target.value);
          }}
          minRows={2}
          style={{
            width: '100%',
            maxWidth: '600px',
            borderRadius: 3,
            padding: '15px',
            minHeight: 50,
            margin: '10px 0 0 0',
            fontFamily: '"Roboto","Helvetica","Arial",sans-serif',
            fontSize: '16px',
          }}
          placeholder="Text"
          value={formik.values.text || ''}
        />

        <TextareaAutosize
          aria-label="maximum height"
          onChange={event => {
            formik.setFieldValue('description', event.target.value);
          }}
          minRows={2}
          style={{
            width: '100%',
            maxWidth: '600px',
            borderRadius: 3,
            padding: '15px',
            minHeight: 50,
            margin: '10px 0 0 0',
            fontFamily: '"Roboto","Helvetica","Arial",sans-serif',
            fontSize: '16px',
          }}
          placeholder="Description"
          value={formik.values.description || ''}
        />

        <br />

        <div
          style={{
            display: 'flex',
            justifyContent: 'space-between',
            width: '100%',
            maxWidth: '600px',
          }}
        >
          <Button color="primary" variant="contained" type="submit">
            Save
          </Button>
        </div>
      </form>
    </div>
  );
};

export default Texts;
