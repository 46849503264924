import axios, { AxiosError } from 'axios';

import { IUser } from '@src/types';
import { getAccessToken } from '@src/utils/auth';

import { HigediResponse, axiosInstance } from '../axios';
import { showToast } from '@src/components/Toast';

const url = process.env.REACT_APP_API;

export const getUsers = async (page: number | null, limit: number | null) =>
  await axios.get<HigediResponse<{ rows: IUser[]; count: number }>>(
    `${url}/user/fake?page=${page}&limit=${limit}&show_banned=0`,
    {
      headers: {
        Authorization: `Bearer ${getAccessToken() ?? ''}`,
      },
    },
  );

export const getBannedUsers = async (
  page: number | null,
  limit: number | null,
  username?: string,
) =>
  await axios.get<HigediResponse<{ users: IUser[]; count: number }>>(
    `${url}/adminpanel/banned-users?page=${page}&limit=${limit}&username=${username ?? ''}`,
    {
      headers: {
        Authorization: `Bearer ${getAccessToken() ?? ''}`,
      },
    },
  );

export const unbanUser = async (userId: number) =>
  await axiosInstance.patch<HigediResponse>(`${url}/adminpanel/banned-users/${userId}`, {
    headers: {
      Authorization: `Bearer ${getAccessToken() ?? ''}`,
    },
  });

export const createUser = async () => {
  try {
    return await axiosInstance.post<HigediResponse<{ user: IUser }>>(`${url}/user/fake`);
  } catch (error) {
    if (error instanceof AxiosError) {
      if (error.response?.status === 401) {
        showToast('error', 'You are not authorized');
        return;
      }
      showToast('error', error.response?.data.message ?? 'Something went wrong');
      return;
    }
    showToast('error', `Error: ${error}`);
  }
};

export const updateUser = async (
  firstName: string,
  lastName: string,
  email: string,
  userId: number,
  experience: string,
  image?: string,
) => {
  const {
    data: { body },
  } = await axiosInstance.patch<HigediResponse<{ user: IUser }>>(`${url}/user/update`, {
    id: userId,
    firstName,
    lastName,
    email,
    image,
    experience,
  });

  return body;
};

export const deleteUser = async (userId: number) =>
  await axiosInstance.delete<HigediResponse>(`${url}/user/${userId}`);
