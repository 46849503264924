import React, { useEffect, useState } from 'react';
import {
  Box,
  CircularProgress,
  Grid,
  Paper,
  Table as MuiTable,
  TableBody,
  TableContainer,
  Typography,
} from '@mui/material';

import { getPostReports } from '@src/services/reports/api';
import { IReports } from '@src/types';
import { Table } from '@src/components/TableHead';

import { Row } from './Row';

export const PostReports: React.FC = () => {
  const [posts, setPosts] = useState<IReports[] | []>([]);
  const [error, setError] = useState<boolean>(false);
  const [isLoading, setIsLoading] = useState<boolean>(true);

  useEffect(() => {
    const fetchData = async () => {
      const {
        data: {
          success,
          body: { reports },
        },
      } = await getPostReports();

      setPosts(reports);
      setIsLoading(false);
      setError(!success);
    };

    fetchData();
  }, []);

  return (
    <Box sx={{ flexGrow: 1 }}>
      <Grid item container spacing={2}>
        <Grid item xs={12}>
          <Paper variant="outlined">
            <>
              <TableContainer sx={{ width: '100%' }}>
                <MuiTable sx={{ minWidth: 700 }} size="small" aria-label="Post Reports table">
                  <Table
                    column_1="Count Reports"
                    column_2="Image"
                    column_3="Content"
                    column_4="Author"
                    column_5="View Reports"
                    column_6="Actions"
                  />
                  <TableBody>
                    {posts?.map((post, idx) => (
                      <Row
                        key={idx}
                        post={post?.post}
                        user={post.user}
                        reports={post.reports}
                        posts={posts}
                        setPosts={setPosts}
                      />
                    ))}
                  </TableBody>
                </MuiTable>
              </TableContainer>

              {isLoading && (
                <Box my={3} width="100%" display="flex" justifyContent="center">
                  <CircularProgress />
                </Box>
              )}
              {error && (
                <Typography my={3} textAlign="center">
                  Something went wrong, please try again.
                </Typography>
              )}
              {posts?.length === 0 && (
                <Typography my={3} textAlign="center">
                  No results.
                </Typography>
              )}
            </>
          </Paper>
        </Grid>
      </Grid>
    </Box>
  );
};
