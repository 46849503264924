import React, { useEffect, useState } from 'react';
import {
  Button,
  Box,
  Dialog,
  ListItemText,
  ListItem,
  List,
  Divider,
  AppBar,
  Toolbar,
  IconButton,
  Typography,
  Slide,
} from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import AddTaskIcon from '@mui/icons-material/AddTask';
import RemoveCircleOutlineIcon from '@mui/icons-material/RemoveCircleOutline';
import EditOutlinedIcon from '@mui/icons-material/EditOutlined';
import { TransitionProps } from '@mui/material/transitions';

import { useGetCategoriesQuery } from '@src/services/marketplace/hooks';
import { Category } from '@src/types';
import { deleteCategory } from '@src/services/marketplace/api';

import { AddCategoryModal } from './AddCategoryModal';
import { RemoveModal } from '../Modals/Remove';
import { EditCategoryModal } from './EditCategoryModal';

interface Props {
  showCategories: boolean;
  setShowCategories: React.Dispatch<React.SetStateAction<boolean>>;
}

const Transition = React.forwardRef(function Transition(
  props: TransitionProps & {
    children: React.ReactElement;
  },
  ref: React.Ref<unknown>,
) {
  return <Slide direction="up" ref={ref} {...props} />;
});

export const CategoriesLayout: React.FC<Props> = ({ showCategories, setShowCategories }) => {
  const [showAddModal, setShowAddModal] = useState(false);
  const [showRemoveModal, setShowRemoveModal] = useState(false);
  const [showEditModal, setShowEditModal] = useState(false);
  const getAllCategories = useGetCategoriesQuery();
  const [categories, setCategories] = useState<Category[]>(
    getAllCategories.data?.data.body.categories || [],
  );
  const [id, setId] = useState<number | null>(null);

  useEffect(() => {
    if (getAllCategories?.data?.data?.body?.categories) {
      setCategories(getAllCategories?.data?.data?.body?.categories);
    }
  }, [getAllCategories.data?.data.body.categories]);

  const handleDelete = async () => {
    if (id) {
      const response = await deleteCategory(id);
      setCategories(response.data.body.categories);
    }

    setShowRemoveModal(!showRemoveModal);
  };

  return (
    <Box>
      <Dialog
        fullScreen
        open={showCategories}
        onClose={() => setShowCategories(!showCategories)}
        TransitionComponent={Transition}
      >
        <AppBar sx={{ position: 'relative' }}>
          <Toolbar style={{ display: 'inline-flex' }}>
            <IconButton
              edge="start"
              color="inherit"
              onClick={() => setShowCategories(!showCategories)}
              aria-label="close"
            >
              <CloseIcon />
            </IconButton>
            <Typography sx={{ ml: 2, flex: 1 }} variant="h6" component="div">
              Categories
            </Typography>
            <Box>
              <Button
                autoFocus
                style={{ color: '#08fd14' }}
                onClick={() => setShowAddModal(!showAddModal)}
              >
                <AddTaskIcon />
              </Button>
            </Box>
          </Toolbar>
        </AppBar>
        <AddCategoryModal
          showAddModal={showAddModal}
          setShowAddModal={setShowAddModal}
          setCategories={setCategories}
          categories={categories}
        />
        <EditCategoryModal
          showEditModal={showEditModal}
          setShowEditModal={setShowEditModal}
          setCategories={setCategories}
          categories={categories}
          id={id}
        />
        <RemoveModal
          showRemoveModal={showRemoveModal}
          setShowRemoveModal={setShowRemoveModal}
          handleDelete={handleDelete}
          content={'Are you sure you wanna remove this category ?'}
          title={'Removing Category'}
        />
        <List>
          {categories.map(cat => (
            <Box key={cat.id}>
              <Box
                style={{
                  display: 'inline-flex',
                  justifyContent: 'space-between',
                  width: '100%',
                }}
              >
                <ListItem>
                  <ListItemText primary={cat.category} id={cat.category} data-item={cat} />
                </ListItem>
                <Button
                  autoFocus
                  color="info"
                  tabIndex={cat.id}
                  onClick={event => {
                    setId(event.currentTarget.tabIndex);
                    setShowEditModal(!showEditModal);
                  }}
                >
                  <EditOutlinedIcon />
                </Button>
                <Button
                  autoFocus
                  color="error"
                  tabIndex={cat.id}
                  onClick={event => {
                    setId(event.currentTarget.tabIndex);
                    setShowRemoveModal(!showRemoveModal);
                  }}
                >
                  <RemoveCircleOutlineIcon />
                </Button>
              </Box>
              <Divider />
            </Box>
          ))}
        </List>
      </Dialog>
    </Box>
  );
};
