import React, { useEffect, useState } from 'react';
import { useFormik } from 'formik';
import {
  Card,
  CardHeader,
  CardContent,
  Box,
  Dialog,
  useMediaQuery,
  Autocomplete,
  TextField,
  Button,
  Typography,
} from '@mui/material';
import { TextareaAutosize } from '@mui/base';
import { useTheme } from '@mui/material/styles';

import { validatePost } from '@src/utils/validators';
import { IEmoji, IPost, IUser } from '@src/types';
import { createCommentToPost, getEmoji } from '@src/services/posts/api';
import { getUsers } from '@src/services/users/api';

interface PropsType {
  showCommentModal: boolean;
  setShowCommentModal: React.Dispatch<React.SetStateAction<boolean>>;
  setRows: React.Dispatch<React.SetStateAction<any[]>>;
  rows: IPost[];
  postId: string;
  count: number;
}

export const Comment: React.FC<PropsType> = ({
  showCommentModal,
  setShowCommentModal,
  rows,
  setRows,
  postId,
  count,
}) => {
  const theme = useTheme();
  const [emojii, setEmojii] = useState<IEmoji[]>([]);
  const fullScreen = useMediaQuery(theme.breakpoints.down('md'));
  const formik = useFormik({
    initialValues: {
      text: '',
      users: [] as IUser[],
      user: {} as IUser,
    },
    validationSchema: validatePost,
    onSubmit: (): void => {
      console.log('onSubmit');
    },
  });

  const textLength = formik.getFieldProps('text').value.length;

  useEffect(() => {
    const fetchUsers = async () => {
      const page = 0;
      const {
        data: { body },
      } = await getUsers(page, 0);

      formik.setFieldValue('users', body.rows);
    };

    const fetchEmojii = async () => {
      const {
        data: {
          body: { emojis },
        },
      } = await getEmoji();

      if (emojis) {
        setEmojii(emojis);
      }
    };

    fetchEmojii();
    fetchUsers();
  }, []);

  const handleCreateComment = async () => {
    await createCommentToPost(formik.values.text, formik.values.user, +postId);

    const posts = rows.map(row => {
      if (row.id === +postId && typeof row.count_comments === 'number') {
        row.count_comments = ++row.count_comments;
        return row;
      }

      return row;
    });
    setRows(posts);

    setShowCommentModal(!showCommentModal);
  };

  return (
    <Box>
      <Dialog
        fullScreen={fullScreen}
        open={showCommentModal}
        onClose={() => setShowCommentModal(!showCommentModal)}
        aria-labelledby="responsive-dialog-title"
      >
        <Card sx={{ width: 500, minHeight: 800 }}>
          <CardHeader sx={{ textAlign: 'center' }} title="Creating Comment" />
          <Box
            sx={{
              display: 'grid',
              justifyContent: 'center',
              // height: 200,
              paddingTop: '2em',
              paddingBottom: '2em',
              alignContent: 'space-around',
            }}
          >
            <Autocomplete
              disablePortal
              id="free-solo-demo"
              freeSolo
              onChange={(_, value) => {
                const user = formik.values.users.find(
                  i => `${i.firstName} ${i.lastName}` === value,
                );
                formik.setFieldValue('user', user);
              }}
              options={formik.values.users.map(option => `${option.firstName} ${option.lastName}`)}
              sx={{ width: 300 }}
              renderInput={params => {
                return <TextField {...params} label="Select User" />;
              }}
            />
          </Box>
          <CardContent
            sx={{
              display: 'flex',
              justifyContent: 'center',
              flexDirection: 'column',
              position: 'relative',
              width: '100%',
              gap: '1rem',
            }}
          >
            <Typography sx={{ textAlign: 'center' }}>Type text for post</Typography>
            <TextareaAutosize
              aria-label="maximum height"
              onChange={event => {
                formik.setFieldValue('text', event.target.value);
              }}
              minRows={3}
              maxLength={510}
              placeholder="Maximum 510 characters"
              style={{
                width: '100%',
                borderRadius: 3,
                minHeight: 50,
                resize: 'vertical',
                padding: 10,
              }}
              value={formik.values.text}
            />
            <Box
              sx={{
                position: 'absolute',
                left: '85%',
                bottom: '8%',
                width: '100%',
                fontWeight: '900',
              }}
            >
              {textLength}/510
            </Box>
          </CardContent>
          <CardContent sx={{ display: 'grid', justifyContent: 'center' }}>
            <Typography sx={{ textAlign: 'center' }}>Select Emojii</Typography>
            <Autocomplete
              id="free-solo-demo"
              freeSolo
              options={emojii.map(option => option.char)}
              renderInput={params => <TextField {...params} label="Emojii" />}
              onKeyPress={event => {
                const emoji = emojii.find(
                  i => i.char === (event.target as HTMLInputElement).defaultValue,
                );

                if (emoji) {
                  formik.setFieldValue(
                    'text',
                    `${formik.values.text}${String.fromCodePoint(+`0x${emoji.codes}`)}`,
                  );
                }
              }}
              onChange={event => {
                const emoji = emojii.find(i => i.char === event.currentTarget.innerHTML);

                if (emoji) {
                  // setText(text + String.fromCodePoint(+`0x${emoji.codes}`));
                  formik.setFieldValue(
                    'text',
                    `${formik.values.text}${String.fromCodePoint(+`0x${emoji.codes}`)}`,
                  );
                }
              }}
            />
          </CardContent>
          <Box
            sx={{
              display: 'flex',
              justifyContent: 'space-around',
              marginBottom: 5,
            }}
          >
            <Button
              variant="outlined"
              sx={{ width: 100, alignSelf: 'center' }}
              component="label"
              color="error"
              onClick={() => setShowCommentModal(!showCommentModal)}
            >
              Cancel
            </Button>
            <Button
              variant="outlined"
              sx={{ width: 100, alignSelf: 'center' }}
              type="submit"
              onClick={handleCreateComment}
            >
              Submit
            </Button>
          </Box>
        </Card>
      </Dialog>
    </Box>
  );
};
