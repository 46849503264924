import React, { useReducer } from 'react';

import { IManager, MarketplaceFilters } from '@src/types';

type State = {
  authUser: IManager | null;
  marketplaceFilters: MarketplaceFilters;
};

type Action =
  | {
      type: 'SET_USER';
      payload: IManager | null;
    }
  | {
      type: 'SET_MARKETPLACE_FILTERS';
      payload: MarketplaceFilters;
    };

type Dispatch = (action: Action) => void;

const initialState: State = {
  authUser: null,
  marketplaceFilters: {
    name: '',
    categories: [],
  },
};

type StateContextProviderProps = { children: React.ReactNode };

const StateContext = React.createContext<{ state: State; dispatch: Dispatch } | undefined>(
  undefined,
);

const stateReducer = (state: State, action: Action) => {
  switch (action.type) {
    case 'SET_USER': {
      return {
        ...state,
        authUser: action.payload,
      };
    }
    case 'SET_MARKETPLACE_FILTERS': {
      return {
        ...state,
        marketplaceFilters: action.payload,
      };
    }
    default: {
      throw new Error(`Unhandled action type`);
    }
  }
};

const StateContextProvider = ({ children }: StateContextProviderProps) => {
  const [state, dispatch] = useReducer(stateReducer, initialState);
  const value = { state, dispatch };
  return <StateContext.Provider value={value}>{children}</StateContext.Provider>;
};

const useStateContext = () => {
  const context = React.useContext(StateContext);

  if (context) {
    return context;
  }

  throw new Error(`useStateContext must be used within a StateContextProvider`);
};

export { StateContextProvider, useStateContext };
