import React from 'react';
// import { useLocation } from 'react-router-dom';

import { useGetCurrentManagerQuery } from '@src/services';
import { FullscreenSpinner } from '@src/components/FullscreenSpinner';

import { useVerifyTokens } from './use-verify-tokens';

type AuthMiddlewareProps = {
  children: React.ReactElement;
};

const AuthMiddleware: React.FC<AuthMiddlewareProps> = ({ children }) => {
  // const { pathname } = useLocation();

  // const enabled =
  //   !pathname.match('/signin') ||
  //   !pathname.match('/privacy-policy') ||
  //   !pathname.match('/term-of-use');
  // console.log(enabled);

  useVerifyTokens();

  const { isLoading } = useGetCurrentManagerQuery(false);

  if (isLoading) {
    return <FullscreenSpinner />;
  }

  return children;
};

export default AuthMiddleware;
