import React from 'react';
import { Box, Toolbar } from '@mui/material';

import { Drawer } from './Drawer';
import { AppBar } from './AppBar';

interface Props {
  children: React.ReactElement | null;
}

export const DashboardLayout: React.FC<Props> = ({ children }) => (
  <Box sx={{ display: 'flex' }}>
    <AppBar />
    <Drawer />
    <Box component="main" width="100%">
      <Toolbar />
      <Box m={3}>{children}</Box>
    </Box>
  </Box>
);
