import React from 'react';
import { Link, useLocation } from 'react-router-dom';
import { NavigateNext } from '@mui/icons-material';
import { Breadcrumbs as MuiBreadcrumbs, Link as MuiLink, Typography } from '@mui/material';

interface Props {
  breadcrumbs: Record<string, string>;
}

export const Breadcrumbs: React.FC<Props> = ({ breadcrumbs }) => {
  const { pathname } = useLocation();
  const splitPathname = pathname.split('/').filter(Boolean);

  return (
    <MuiBreadcrumbs separator={<NavigateNext fontSize="small" />}>
      {splitPathname.map((value, index) => {
        const isLast = index === splitPathname.length - 1;
        const to = `/${splitPathname.slice(0, index + 1).join('/')}`.replace(/\d+$/, ':slug');

        if (isLast) {
          return (
            <Typography color="text.primary" key={to}>
              {breadcrumbs[to]}
            </Typography>
          );
        }

        return (
          <MuiLink component={Link} underline="hover" color="inherit" to={to} key={to}>
            {breadcrumbs[to]}
          </MuiLink>
        );
      })}
    </MuiBreadcrumbs>
  );
};
