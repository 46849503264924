import { axiosInstance, HigediResponse } from '@src/services';
import {
  EditProductRequest,
  GetOneProductResponse,
  GetProductsParams,
  GetProductsResponse,
  PostCopyProductsResponse,
  PostProductRequest,
  PostProductResponse,
} from '@src/services/marketplace/types';
import { getAccessToken } from '@src/utils/auth';
import { Category, IText } from '@src/types';

const MARKETPLACE_ENDPOINT = '/products';
const url = process.env.REACT_APP_API;

export const getProducts = async (params: GetProductsParams) =>
  await axiosInstance.get<GetProductsResponse>(`${MARKETPLACE_ENDPOINT}`, {
    params,
  });

export const getOneProduct = async (id: number) => {
  if (!isNaN(id)) {
    return await axiosInstance.get<GetOneProductResponse>(`${MARKETPLACE_ENDPOINT}/${id}`);
  }
};

export const createProduct = async (product: PostProductRequest) => {
  Reflect.deleteProperty(product, 'id');
  return await axiosInstance.post<PostProductResponse>(`${MARKETPLACE_ENDPOINT}`, product);
};

export const copyProducts = async (ids: number[]) =>
  await axiosInstance.post<PostCopyProductsResponse>(`${MARKETPLACE_ENDPOINT}/copy`, { ids });

export const deleteProducts = async (ids: number[]) =>
  await axiosInstance.delete<HigediResponse>(`${MARKETPLACE_ENDPOINT}`, {
    data: { ids },
  });

export const editProduct = async (product: EditProductRequest) =>
  await axiosInstance.put<HigediResponse>(`${MARKETPLACE_ENDPOINT}/${product.id}`, product);

export const getCategories = async () =>
  await axiosInstance.get<HigediResponse<{ categories: Category[] }>>(`${url}/getcategories`, {
    headers: {
      Authorization: `Bearer ${getAccessToken() ?? ''}`,
    },
  });

export const addCategory = async (category: string) => {
  const response = await axiosInstance.post<HigediResponse<{ category: Category }>>(
    `${url}/category`,
    { category },
    {
      headers: {
        Authorization: `Bearer ${getAccessToken() ?? ''}`,
      },
    },
  );
  return response;
};

export const deleteCategory = async (id: number) => {
  const response = await axiosInstance.delete<HigediResponse<{ categories: Category[] }>>(
    `${url}/category/${id}`,
    {
      headers: {
        Authorization: `Bearer ${getAccessToken() ?? ''}`,
      },
    },
  );
  return response;
};

export const editCategory = async (id: number, category: string) => {
  const response = await axiosInstance.patch<HigediResponse<{ categories: Category[] }>>(
    `${url}/category/${id}`,
    { category },
    {
      headers: {
        Authorization: `Bearer ${getAccessToken() ?? ''}`,
      },
    },
  );
  return response;
};

export const deleteImage = async (key: string) =>
  await axiosInstance.delete<HigediResponse>(`${url}/image`, { data: { key } });

export const getAffiliateText = async () =>
  await axiosInstance.get<HigediResponse & IText>(`${url}/affiliate-text`, {
    headers: {
      Authorization: `Bearer ${getAccessToken() ?? ''}`,
    },
  });

export const updateAffiliateText = async (data: Partial<IText>) =>
  await axiosInstance.patch<HigediResponse<IText>>(`${url}/adminpanel/affiliate-text`, data, {
    headers: {
      Authorization: `Bearer ${getAccessToken() ?? ''}`,
    },
  });
