import React, { useCallback } from 'react';
import { AppBar as MuiAppBar, Button, Menu, MenuItem, Toolbar } from '@mui/material';
import { AccountCircle, ExpandLess, ExpandMore } from '@mui/icons-material';
import { bindMenu, bindTrigger } from 'material-ui-popup-state';
import { usePopupState } from 'material-ui-popup-state/hooks';
import { useNavigate } from 'react-router-dom';

import { useSignOutMutation } from '@src/services';
import { useStateContext } from '@src/context';

export const AppBar = () => {
  const stateContext = useStateContext();
  const navigate = useNavigate();
  const { mutateAsync: signOut } = useSignOutMutation(navigate);
  const user = stateContext.state.authUser;

  const handleLogOut = useCallback(async () => {
    await signOut();
  }, []);

  const popupState = usePopupState({
    variant: 'popover',
    popupId: 'account-actions',
  });

  return (
    <MuiAppBar
      position="fixed"
      sx={{
        display: 'flex',
        alignItems: 'flex-end',
        justifyContent: 'center',
        px: 3,
      }}
    >
      <Toolbar>
        <Button
          {...bindTrigger(popupState)}
          sx={{
            color: theme => theme.palette.primary.contrastText,
            textTransform: 'none',
          }}
          startIcon={<AccountCircle />}
          endIcon={popupState.isOpen ? <ExpandLess /> : <ExpandMore />}
        >
          {user?.username || 'admin'}
        </Button>
        <Menu
          {...bindMenu(popupState)}
          transformOrigin={{ horizontal: 'right', vertical: 'top' }}
          anchorOrigin={{ horizontal: 'right', vertical: 'bottom' }}
          PaperProps={{ sx: { minWidth: 180 } }}
        >
          <MenuItem onClick={handleLogOut}>Logout</MenuItem>
        </Menu>
      </Toolbar>
    </MuiAppBar>
  );
};
