import React, { useEffect } from 'react';
import { Button, Stack, Typography } from '@mui/material';
import { bindTrigger, usePopupState } from 'material-ui-popup-state/hooks';
import { useSearchParams } from 'react-router-dom';
import { useFormik } from 'formik';

import { ManagerDialog } from '@src/pages/Managers/ManagerDialog';
import { SearchBar } from '@src/components/SearchBar';
import { useGetCurrentManagerQuery } from '@src/services/managers/hooks';

import { Table } from './Table';

const Managers: React.FC = () => {
  const addDialogState = usePopupState({
    variant: 'dialog',
    popupId: 'add-manager-dialog',
  });
  const { data } = useGetCurrentManagerQuery(true);
  const [searchParams, setSearchParams] = useSearchParams();

  const search = useFormik<{ search: string }>({
    initialValues: { search: '' },
    onSubmit: values => {
      setSearchParams(values.search ? { username: values.search } : undefined);
    },
  });

  useEffect(() => {
    if (searchParams.get('username')) {
      search.setFieldValue('search', searchParams.get('username'));
    }
  }, [searchParams]);

  return (
    <Stack spacing={2}>
      <Typography component="h1">Managers</Typography>

      <Stack direction="row">
        {data?.role === 'super-admin' && (
          <form onSubmit={search.handleSubmit}>
            <Stack direction="row" spacing={2}>
              <SearchBar
                placeholder="Search username…"
                inputProps={{
                  'aria-label': 'search',
                  name: 'search',
                  value: search.values.search,
                  onChange: search.handleChange,
                }}
              />
              <Button type="submit">Search</Button>
            </Stack>
          </form>
        )}

        {data?.role === 'super-admin' && (
          <>
            <Button sx={{ marginLeft: 'auto' }} {...bindTrigger(addDialogState)}>
              Add manager
            </Button>
            <ManagerDialog
              dialogTitle="Add manager"
              variant="add"
              dialogState={addDialogState}
              managerData={{
                id: 0,
                username: '',
                password: '',
                email: '',
              }}
            />
          </>
        )}
      </Stack>
      <Table />
    </Stack>
  );
};

export default Managers;
