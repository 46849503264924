import React from 'react';
import { Box, Modal, Skeleton, Typography } from '@mui/material';

import { IUser } from '@src/types';

export interface ModalProps {
  showUser: boolean;
  setShowUser: React.Dispatch<React.SetStateAction<boolean>>;
  user: IUser;
}

const style = {
  position: 'absolute' as const,
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 450,
  bgcolor: 'background.paper',
  boxShadow: 24,
  p: 4,
  display: 'grid',
  borderRadius: '3%',
  maxHeight: '300px',
};

export const UserInfo: React.FC<ModalProps> = ({ showUser, setShowUser, user }) => {
  return (
    <Modal
      open={showUser}
      onClose={() => setShowUser(!showUser)}
      aria-labelledby="parent-modal-title"
      aria-describedby="parent-modal-description"
    >
      <Box sx={style}>
        <Typography id="modal-modal-title" variant="h6" textAlign="center" component="h2">
          Info about user
        </Typography>
        <Box
          sx={{
            display: 'inline-flex',
            justifyContent: 'space-between',
            alignItems: 'center',
          }}
        >
          <Typography id="modal-modal-description" sx={{ mt: 2 }}>
            {user?.avatarPath ? (
              <img
                src={user.avatarPath}
                width={75}
                height={75}
                alt="image"
                style={{ borderRadius: '50%' }}
              />
            ) : (
              <Skeleton variant="rounded" width={75} height={75} />
            )}
          </Typography>
          <Box sx={{ display: 'grid' }}>
            <Typography id="modal-modal-description" variant="subtitle1" sx={{ mt: 2 }}>
              {`Full Name: ${user?.firstName} ${user?.lastName}`}
            </Typography>
            <Typography id="modal-modal-description" sx={{ mt: 2 }}>
              {`Experience: ${user?.experience}`}
            </Typography>
            <Typography id="modal-modal-description" sx={{ mt: 2 }}>
              {`Email: ${user?.email}`}
            </Typography>
          </Box>
        </Box>
      </Box>
    </Modal>
  );
};
