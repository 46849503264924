import { useLocation } from 'react-router-dom';
import { useEffect } from 'react';
import decode from 'jwt-decode';

import { getAccessToken } from '@src/utils/auth';
import { useGetCurrentManagerQuery } from '@src/services';

export const useVerifyTokens = () => {
  const { refetch } = useGetCurrentManagerQuery(false);
  const { pathname } = useLocation();

  useEffect(() => {
    const checkAuth = () => {
      const accessToken = getAccessToken() ?? '';

      if (accessToken) {
        const decodedAccessToken = decode<{ exp: number }>(accessToken);

        const isAccessTokenExpired = decodedAccessToken.exp * 1000 < new Date().getTime();

        if (isAccessTokenExpired) {
          void refetch();
        }
      }
    };

    checkAuth();
  }, [pathname]);
};
