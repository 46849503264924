import React from 'react';
import { bindDialog, PopupState } from 'material-ui-popup-state/hooks';
import { Box, Dialog as MuiDialog, DialogTitle, IconButton } from '@mui/material';
import { Close } from '@mui/icons-material';

interface Props {
  popupState: PopupState;
  title: React.ReactNode;
  children: React.ReactNode | null;
}

export const Dialog: React.FC<Props> = ({ popupState, title, children }) => (
  <MuiDialog {...bindDialog(popupState)}>
    <Box p={3}>
      <DialogTitle id="customized-dialog-title" textAlign="center">
        {title}
        <IconButton
          aria-label="close"
          onClick={popupState.close}
          sx={{
            position: 'absolute',
            right: 8,
            top: 8,
            color: theme => theme.palette.grey[500],
          }}
        >
          <Close />
        </IconButton>
      </DialogTitle>

      {children}
    </Box>
  </MuiDialog>
);
