import React, { useEffect, useState } from 'react';
import {
  Box,
  CircularProgress,
  Grid,
  Paper,
  Table as MuiTable,
  TableBody,
  TableContainer,
  Typography,
} from '@mui/material';

import { getUserReports } from '@src/services/reports/api';
import { IReports } from '@src/types';
import { Table } from '@src/components/TableHead';

import { Row } from './Row';

export const UserReports: React.FC = () => {
  const [users, setUsers] = useState<IReports[]>([]);
  const [error, setError] = useState<boolean>(false);
  const [isLoading, setIsLoading] = useState<boolean>(true);

  useEffect(() => {
    const fetchData = async () => {
      const {
        data: {
          success,
          body: { reports },
        },
      } = await getUserReports();

      setUsers(reports);
      setIsLoading(false);
      setError(!success);
    };

    fetchData();
  }, []);

  return (
    <Box sx={{ flexGrow: 1 }}>
      <Grid item container spacing={2}>
        <Grid item xs={12}>
          <Paper variant="outlined">
            <>
              <TableContainer sx={{ width: '100%' }}>
                <MuiTable sx={{ minWidth: 700 }} size="small" aria-label="Post Reports table">
                  <Table
                    column_1="Count Reports"
                    column_2="Image"
                    column_3="User Email"
                    column_4="Reports reason"
                    column_5="Reports details"
                    column_6="Actions"
                  />
                  <TableBody>
                    {users?.map((user, idx) => (
                      <Row
                        key={idx}
                        user={user.user}
                        reports={user.reports}
                        users={users}
                        setUsers={setUsers}
                      />
                    ))}
                  </TableBody>
                </MuiTable>
              </TableContainer>

              {isLoading && (
                <Box my={3} width="100%" display="flex" justifyContent="center">
                  <CircularProgress />
                </Box>
              )}
              {error && (
                <Typography my={3} textAlign="center">
                  Something went wrong, please try again.
                </Typography>
              )}
              {users?.length === 0 && (
                <Typography my={3} textAlign="center">
                  No results.
                </Typography>
              )}
            </>
          </Paper>
        </Grid>
      </Grid>
    </Box>
  );
};
