import React, { useEffect } from 'react';
import {
  alpha,
  Box,
  CircularProgress,
  Paper,
  styled,
  Table as MuiTable,
  TableBody,
  TableCell,
  tableCellClasses,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from '@mui/material';
import { useSearchParams } from 'react-router-dom';

import { Row } from '@src/pages/Managers/Table/Row';
import { useGetManagersQuery } from '@src/services/managers';
import { useGetCurrentManagerQuery } from '@src/services/managers/hooks';

const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: alpha(theme.palette.common.black, 0.05),
    fontWeight: 'bold',
  },
}));

export const Table: React.FC = () => {
  const [searchParams] = useSearchParams();
  const { data, refetch, isLoading, error } = useGetManagersQuery(
    searchParams.get('username') ?? '',
  );
  const currentManager = useGetCurrentManagerQuery(true);

  useEffect(() => {
    void refetch();
  }, [searchParams.get('username')]);

  return (
    <Paper variant="outlined">
      <>
        <TableContainer sx={{ width: '100%' }}>
          <MuiTable sx={{ minWidth: 700 }} size="small" aria-label="Managers table">
            <TableHead>
              <TableRow>
                <StyledTableCell
                  align="center"
                  style={{ minWidth: '40%', maxWidth: '40%', width: '40%' }}
                >
                  Username
                </StyledTableCell>
                <StyledTableCell
                  align="center"
                  style={{ minWidth: '40%', maxWidth: '40%', width: '40%' }}
                >
                  Email
                </StyledTableCell>
                {currentManager?.data?.id === 1 && (
                  <>
                    <StyledTableCell
                      style={{ minWidth: '10%', maxWidth: '10%', width: '10%' }}
                      align="center"
                    >
                      Edit
                    </StyledTableCell>
                    <StyledTableCell
                      style={{ minWidth: '10%', maxWidth: '10%', width: '10%' }}
                      align="center"
                    >
                      Delete
                    </StyledTableCell>
                  </>
                )}
              </TableRow>
            </TableHead>

            <TableBody>
              {!isLoading &&
                data?.data.body.managers.map(manager => {
                  manager.password = '********';
                  return (
                    <Row key={manager.id} {...manager} currentManagerID={currentManager.data?.id} />
                  );
                })}
            </TableBody>
          </MuiTable>
        </TableContainer>
        {isLoading && (
          <Box my={3} width="100%" display="flex" justifyContent="center">
            <CircularProgress />
          </Box>
        )}
        {error && (
          <Typography my={3} textAlign="center">
            Something went wrong, please try again.
          </Typography>
        )}
        {data?.data?.body?.managers?.length === 0 && (
          <Typography my={3} textAlign="center">
            No other managers found.
          </Typography>
        )}
      </>
    </Paper>
  );
};
