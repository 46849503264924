import React, { ChangeEvent, useState } from 'react';
import Box from '@mui/material/Box';
import Modal from '@mui/material/Modal';
import Fade from '@mui/material/Fade';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import { TextField } from '@mui/material';

import { restorePassword } from '@src/services/auth/api';

const style = {
  position: 'absolute' as const,
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 400,
  bgcolor: 'background.paper',
  border: '2px solid #000',
  boxShadow: 24,
  p: 4,
};

interface Props {
  showForgotPasswordModal: boolean;
  setShowForgotPasswordModal: React.Dispatch<React.SetStateAction<boolean>>;
}

export const ForgotPassword: React.FC<Props> = ({
  showForgotPasswordModal,
  setShowForgotPasswordModal,
}) => {
  const [email, setEmail] = useState<string>('');
  const [error, setError] = useState<boolean>(false);
  const [helperText, setHelperText] = useState<string>('');

  const handleChange = (event: ChangeEvent<HTMLInputElement>): void => {
    setEmail(event.target.value);
  };

  const handleRestorePassword = async () => {
    const { success, msg } = await restorePassword(email);

    if (!success) {
      setError(true);
      setHelperText(msg as string);
      return;
    }

    setError(false);
    setHelperText('');
    setEmail('');
    setShowForgotPasswordModal(false);
  };

  return (
    <div>
      <Modal
        aria-labelledby="transition-modal-title"
        aria-describedby="transition-modal-description"
        open={showForgotPasswordModal}
        closeAfterTransition
      >
        <Fade in={showForgotPasswordModal}>
          <Box sx={style}>
            <Typography id="transition-modal-title" variant="h6" component="h2">
              Please, type your email
            </Typography>
            <Typography id="transition-modal-description" sx={{ mt: 2 }}>
              If email will be correct, we send there, new password
            </Typography>
            <TextField
              id="email"
              label="email"
              name="email"
              value={email}
              style={{ marginTop: '10px' }}
              onChange={handleChange}
              error={error}
              helperText={helperText}
              required
              fullWidth
            />
            <Box
              sx={{
                display: 'flex',
                justifyContent: 'space-around',
                marginTop: '10px',
              }}
            >
              <Button
                autoFocus
                color="warning"
                onClick={() => {
                  setError(false);
                  setHelperText('');
                  setEmail('');
                  setShowForgotPasswordModal(false);
                }}
              >
                Cancel
              </Button>
              <Button autoFocus color="success" onClick={handleRestorePassword}>
                Apply
              </Button>
            </Box>
          </Box>
        </Fade>
      </Modal>
    </div>
  );
};
