import React, { useEffect } from 'react';
import {
  Box,
  Dialog,
  Table as MuiTable,
  AppBar,
  Toolbar,
  IconButton,
  Typography,
  Slide,
  TableBody,
  TableContainer,
  Grid,
  Paper,
} from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import { TransitionProps } from '@mui/material/transitions';

import { IReport } from '@src/types';
import { Table } from '@src/components/TableHead';
import { AxiosResponse, HigediResponse } from '@src/services';

import { Report } from './Report';

interface Props {
  showReports: boolean;
  setShowReports: React.Dispatch<React.SetStateAction<boolean>>;
  setReports: React.Dispatch<React.SetStateAction<IReport[]>>;
  reports: IReport[];
  deleteReport: (id: number) => Promise<AxiosResponse<HigediResponse<void>>>;
}

const Transition = React.forwardRef(function Transition(
  props: TransitionProps & {
    children: React.ReactElement;
  },
  ref: React.Ref<unknown>,
) {
  return <Slide direction="up" ref={ref} {...props} />;
});

export const ViewReports: React.FC<Props> = ({
  showReports,
  setShowReports,
  setReports,
  reports,
  deleteReport,
}) => {
  useEffect(() => {
    if (reports.length === 0) {
      setShowReports(!setReports);
    }
  }, [reports.length]);

  return (
    <Box>
      <Dialog
        fullScreen
        open={showReports}
        onClose={() => setShowReports(!showReports)}
        TransitionComponent={Transition}
      >
        <AppBar sx={{ position: 'relative' }}>
          <Toolbar style={{ display: 'inline-flex' }}>
            <IconButton
              edge="start"
              color="inherit"
              onClick={() => setShowReports(!showReports)}
              aria-label="close"
            >
              <CloseIcon />
            </IconButton>
            <Typography sx={{ ml: 2, flex: 1 }} variant="h6" component="div">
              Reports
            </Typography>
          </Toolbar>
        </AppBar>
        <Box sx={{ flexGrow: 1 }}>
          <Grid item container spacing={2}>
            <Grid item xs={12}>
              <Paper variant="outlined">
                <>
                  <TableContainer sx={{ width: '100%' }}>
                    <MuiTable sx={{ minWidth: 700 }} size="small" aria-label="Reports table">
                      <Table
                        column_1="Created"
                        column_2="Reason"
                        column_3="Description"
                        column_4="Status"
                        column_5="Reporter"
                        column_6="Actions"
                      />
                      <TableBody>
                        {reports?.map(report => (
                          <Report
                            key={report.report_id}
                            report={report}
                            reports={reports}
                            setReports={setReports}
                            deleteReport={deleteReport}
                          />
                        ))}
                      </TableBody>
                    </MuiTable>
                  </TableContainer>
                </>
              </Paper>
            </Grid>
          </Grid>
        </Box>
      </Dialog>
    </Box>
  );
};
